import React,{useContext,useEffect,useState} from "react";
import {Link,useLocation} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome,faRobot,faBuildingColumns,faWaveSquare,faBell,faGear} from '@fortawesome/free-solid-svg-icons'

import {GlobalContext} from "../../contexts/global";

import MenuMobile from "../../components/menumobile";	

import logo2 from "../../assets/img/logo.png";



import './style.css';

const Component = () => {

	const {settings,globals} = useContext(GlobalContext);
	// const {authenticated,user,setUser,statusGlobal,loading,login,logout} = useContext(AuthContext);

	const location = useLocation();




	let homeAlert = '';
	let botsAlert = '';
	let exchangesAlert = '';
	let ordersAlert = '';
	let notificationsAlert = '';
	let settingsAlert = '';

	
	const menuArray = [
		
		{"to":"/dashboard/home", "title":"Home","icon":faHome,"alert":homeAlert},
		{"to":"/dashboard/orders", "title":"Ordens","icon":faWaveSquare,"alert":ordersAlert},
		{"to":"/dashboard/bots", "title":"Bots","icon":faRobot,"alert":botsAlert},
		{"to":"/dashboard/exchanges", "title":"Corretoras","icon":faBuildingColumns,"alert":exchangesAlert},
		// {"to":"/dashboard/notifications", "title":"Notificações","icon":faBell,"alert":notificationsAlert},
		// {"to":"/dashboard/settings", "title":"Configurações","icon":faGear,"alert":settingsAlert},

		// {"to":"/dashboard/home", "title":"Home","icon":faHome,"alert":homeAlert ,"class":""},
		// {"to":"/dashboard/home", "title":"Balance","icon":faDollarSign,"alert":homeAlert ,"class":""},
		// {"to":"/dashboard/receive", "title":"Receive","icon":faDownload,"alert":receiveAlert,"class":""},
	];

	const [menu,menuUpdate] = useState(menuArray);

/*
	useEffect(()=>{
		let menu = menuArray;

		menu[1].icon = faHome;
		menu[1].alert = globals.orders;
		menu[1].title = globals.orders;
		
		let menu2 = JSON.stringify(menu);
		menu2 = JSON.parse(menu2);

		console.log(menu2);

		menuUpdate(menu2);

	},[globals.orders]);*/
	
	

	return (
		<>

			

        	<div className=" layout_dashboard_menumobile">

            	<MenuMobile>
            		{Object.keys(menu).map((key,index) => {
			 			
				 		return (
				 			
							<li key={index}  className={  ( location.pathname.indexOf(menuArray[key].to) !== -1 )?'active':'' }>
								<Link to={menuArray[key].to} className={menuArray[key].class}>									
									<FontAwesomeIcon icon={(menuArray[key].icon)} />
									{/*<span>{menuArray[key].title}</span>*/}
								</Link>
								<span className={"badge "+menuArray[key].to.replace('/','').replace('/','')}></span>
							</li>
							
						);
					})}


            	</MenuMobile>

		    </div>
     
		
	        


		</>
	);
};


export default Component;