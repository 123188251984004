let indicators = {

	"value": {
		"title":"[Valor condicional]",
		"config": []
	},	

	// "date" : "Data de fechamento",
	// "time" : "Timestamp de fechamento",

	"sma": {
		"title": "Média móvel simples",
		"config": {
			"source":"close",
			"length":9
		}
	},

	"ema": {
		"title": "Média móvel exponencial",
		"config": {
			"source":"close",
			"length":9
		}
	},

	"price_open": {
		"title": "Preço de abertura",
		"config": []
	},
	"price_close": {
		"title": "Preço de fechamento",
		"config": []
	},
	"price_high": {
		"title": "Preço máximo",
		"config": []
	},
	"price_low": {
		"title": "Preço minimo",
		"config": []
	},

	// "price_volume" : "Volume de negociação",

	// "averagevolume" : "Média de volume",

	// "price_direction" : "Direção do preço",		

	// "tendencyma" : "Tendencia - média móvel",	

	"aroonhigh": {
		"title": "Aroon high",
		"config": {
			"length":14
		}
	},
	"aroonlow": {
		"title": "Aroon low",
		"config": {
			"length":14
		}
	},

	"rsi": {
		"title": "RSI",
		"config": {
			"source":"close",
			"length":14
		}
	},

	"stochk": {
		"title": "Stoch K",
		"config": {
			"kperiod":14,
			"kslow":1,
			"dperiod":3,
		}
	},
	"stochd": {
		"title": "Stoch D",
		"config": {
			"kperiod":14,
			"kslow":1,
			"dperiod":3,
		}
	},

	"macdline": {
		"title": "MACD line",
		"config": {
			"source":"close",
			"FastLength":12,
			"SlowLength":26,
			"SignalLength":9,
		}
	},
	"macdsignal": {
		"title": "MACD signal",
		"config": {
			"source":"close",
			"FastLength":12,
			"SlowLength":26,
			"SignalLength":9,
		}
	},
	"macdhistogram": {
		"title": "MACD Histogram",
		"config": {
			"source":"close",
			"FastLength":12,
			"SlowLength":26,
			"SignalLength":9,
		}
	},

	"rvi" : {
		"title": "RVI - Relative Volatility Indicator",
		"config": {
			"length":10
		}
	},

	"bollingerbands_upper" : {
		"title": "BollingerBands Upper",
		"config": {
			"source":"close",
			"length":20,
			"deviation":2,
		}
	},
	"bollingerbands_lower" : {
		"title": "BollingerBands Lower",
		"config": {
			"source":"close",
			"length":20,
			"deviation":2,
		}
	},
	"bollingerbands_middle" : {
		"title": "BollingerBands Middle",
		"config": {
			"source":"close",
			"length":20,
			"deviation":2,
		}
	},
	"bollingerbands_pb" : {
		"title": "BollingerBands Percent Bandwidth",
		"config": {
			"source":"close",
			"length":20,
			"deviation":2,
		}
	},
	
	// "macddivergence" : "MACD divergence",
	// "rviline" : "RVI line",
	// "rvisignal" : "RVI signal", //relative volatility indicator
}

export {indicators}