import React, {useState,useContext} from "react";

import {AuthContext} from "../../contexts/auth";

import {getId} from "../../libs/deviceid.js";
import {md5} from "../../libs/md5.js";

import pack from "../../../package.json";

import {requestLogon} from "../../services/apiAuth.jsx";

import logo from "../../assets/img/logo.png";
import "./style.css";

const Login = () => {

	const {authCheckDevice} = useContext(AuthContext);

	const [fields,fieldsUpdate] = useState({
		accept:"'application/json",
		username:"",
		password:"",
		device:getId('chainbot'),
	});
	const [response,responseUpdate] = useState('&nbsp;');

	const submitHandle = (e) =>{
		e.preventDefault();

		fields.password = md5(fields.password);


		requestLogon(fields).then((resp)=>{
			let {status} = resp.data;
			
			if(status === 'success'){				
				authCheckDevice((resp)=>{
					let {status} = resp;
					if(status === 'success'){
						responseUpdate('<span class="-success-text padding">Acesso liberado</span>');
					}else{						
						responseUpdate('<span class="-error-text padding">Acesso negado</span>');
					}
				});
			}else{
				responseUpdate('<span class="-error-text padding">Acesso negado</span>');
			}			
		}).catch((resp)=>{
			responseUpdate('<span class="-error-text padding">Acesso negado</span>');	
		});

		
	}

	return (
		<>
			
			<div className="--login h100 ">

				<div className="wrapper">
					<div className="header ">

					</div>
					<div className="main  ">				
						
						<div className="line h100">
							<div className="column middle center h100">
							
								<img src={logo} className="logo" alt="logo" />
								<h1>{pack.title}</h1>

							</div>
						</div>
					
					
					</div>
					<div className="footer">

						<div className="line container">
							<div className="column middle center ">
								
								<div className="margin-bottom-40" dangerouslySetInnerHTML={{__html: response}} ></div>

								<form onSubmit={submitHandle} className="w100 margin-bottom-40">
							
									<div className="input-float flatmodel">
									      <input type="text" autoComplete="username" required key2={fields.username} defaultValue={fields.username} onChange={(e)=>{ fields.username=e.target.value; fieldsUpdate({...fields}) }} />
									      <label >Email</label>
									</div>

									<div className="input-float flatmodel">
									      <input type="password" autoComplete="current-password" required key2={fields.password} defaultValue={fields.password} onChange={(e)=>{ fields.password=e.target.value; fieldsUpdate({...fields}) }} />
									      <label >Senha</label>
									</div>

									<div className="line h100 mobile-nobreak margin-top-20">
										<div className="column col6 middle center h100">										
											<button type="button" className="button -link w100">Registrar</button>
										</div>
										<div className="column middle center h100">										
											<button className="button -primary w100">Entrar</button>
										</div>
									</div>
									

								</form>

							</div>
						</div>

						

					

					
					</div>
				</div>
			
			</div>
		</>
	);


};


export default Login;
