import React, {useState, createContext,useEffect,useContext} from "react";
import {useNavigate} from "react-router-dom";

import {getId} from "../libs/deviceid.js";

import {checkDevice} from "../services/apiAuth";

import {GlobalContext} from "./global";

export const AuthContext = createContext();
export const AuthProvider = ({children}) => {

	const {settings,listBots,globals} = useContext(GlobalContext);

	const [auth, authUpdate] = useState(null);
	const navigate = useNavigate();

	

	const authCheckDevice = (callback) => {
		let deviceid = getId('chainbot')
		checkDevice(deviceid).then((resp)=>{
			let {status,body} = resp.data;			
			authUpdate(body);

			if(callback)callback(resp);
			if(status === 'success'){	

				window.username = body[0].username;
						
				/*globals.getAll(true).catch((err)=>{
					console.log('---',err);
					// setTimeout(()=>{
						// navigate('/login');
					// },1000);		
				});*/

				globals.getAll(true,(status)=>{

					if(status === 'success'){
						// navigate('/dashboard/home');
						return;
					}

					// navigate('/login');
				});
		
				navigate('/dashboard/home');
				// setTimeout(()=>{
				// },1000);		
			}else{
				// setTimeout(()=>{
					navigate('/login');
				// },1000);		
			}
		}).catch((err)=>{
			// console.log('-',err);
			// setTimeout(()=>{
				navigate('/login');
			// },1000);		
		});

	
			
	}


	useEffect(()=>{	
		authCheckDevice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<AuthContext.Provider value={{ authenticated: !!auth,auth, authUpdate,authCheckDevice}}>						
			{children}			
		</AuthContext.Provider>
	)
}