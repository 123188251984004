

const checkJson = (text) => {

  if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

      return true;

    }else{

      return false;
      //the json is not ok

    }

}
export {checkJson}


const typeOf = (obj) => {

  if(Object.prototype.toString.call(obj) === '[object Array]') return 'array';
  if(Object.prototype.toString.call(obj) === '[object Object]') return 'object';

  return (typeof obj);
}
export {typeOf}

const wait = (time) => {
// function wait(time) {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    });
}
export {wait}

const objToArray = (obj) => {
    	function* values(obj) {
		    for (let prop of Object.keys(obj)) // own properties, you might use
		                                       // for (let prop in obj)
		        yield obj[prop];
		}
		
		return Array.from(values(obj));
	}

export {objToArray}


const sortKey = (array,key) => {
	array = array.sort(function(a,b){
      return parseInt(a[key]) - parseInt(b[key]);
    });
    return array;
}
export {sortKey}



const sortKey2 = (array,key,key2) => {
  array = array.sort(function(a,b){
      return parseInt(a[key][key2]) - parseInt(b[key][key2]);
    });
    return array;
}
export {sortKey2}


const secToDate = (seconds,mode) => {

    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    if( mode === 'extenso' ){

        var dDisplay = d > 0 ? d + (d == 1 ? " dia, " : " dias, ") : "";
        var hDisplay = h >= 0 ? h + (h == 1 ? " hora, " : " horas, ") : "";
        var mDisplay = m >= 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : "";
        var sDisplay = s >= 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;

    }else{


        var dDisplay = d > 0 ? d + (d == 1 ? "d, " : "d, ") : "";
        var hDisplay = h >= 0 ? h + (h == 1 ? "h, " : "h, ") : "";
        var mDisplay = m >= 0 ? m + (m == 1 ? "m, " : "m, ") : "";
        var sDisplay = s >= 0 ? s + (s == 1 ? "s" : "s") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }
}

export {secToDate}


const stringToColor = (str) => {

   function hashCode(str) {
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    function pickColor(str) {
      // Note the last value here is now 50% instead of 80%
      return `hsl(${hashCode(str) % 360}, 100%, 50%)`;
    }

    return pickColor(str);
}

export {stringToColor}

const Convert2Digits = (n) => {

  return n > 9 ? "" + n: "0" + n;

}

export {Convert2Digits}


const stampToFormat = (date) => {

  // var date = new Date();
  var dateStr =
  ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
  ("00" + date.getDate()).slice(-2) + "/" +
  date.getFullYear() + " " +
  ("00" + date.getHours()).slice(-2) + ":" +
  ("00" + date.getMinutes()).slice(-2) + ":" +
  ("00" + date.getSeconds()).slice(-2);

  return dateStr;

}

export {stampToFormat}

