import React,{useState,useEffect,useContext} from "react";

import {GlobalContext} from "../../../contexts/global";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWindowRestore,faTimesCircle,faDoorOpen,faCheck,faBars,faGlobe,faClock,faRobot,faSortAmountUpAlt,faDollarSign,faCheckCircle,faChartLine,faWaveSquare} from '@fortawesome/free-solid-svg-icons'


import {disconnectDevice} from "../../../services/apiAuth.jsx";


import ChartPerformance from "../../../components/charts/performance";
import ChartOrders from "../../../components/charts/orders";

// import {exchangeList} from "../../../services/apiExchange.jsx";
import {typeOf} from "../../../libs/misc.js";

import {md5} from "../../../libs/md5";

import Selectexchanges from "../../../layout/selectexchanges";

import QuestionInline from "../../../components/questioninline";

import avatarImage from "../../../assets/img/logo.png";
// import avatarImage from "../../../assets/img/avatar.png";
// import avatarImageMiddle from "../../../assets/img/avatar-middle.png";
import {getId} from "../../../libs/deviceid.js";

import "./style.css";
const Component = () => {

	const {settings,globals} = useContext(GlobalContext);

	const [menuAvatar,menuAvatarUpdate] = useState(false);
	const [exchangeID,exchangeIDUpdate] = useState(null);

	const [exchangeData,exchangeDataUpdate] = useState(globals.exchanges);

	useEffect(()=>{		
		let cont = 0;
		for(let key in globals.exchanges){			
			if(globals.exchanges[key].bots_active === undefined) cont++;		
		}

		// impede repintura do layout caso exista algum erro nos dados
		if( cont === Object.keys(globals.exchanges).length ) return;

		exchangeDataUpdate(globals.exchanges);
	},[globals.exchanges]);


	useEffect(()=>{		

		// console.log( globals.listBots );
		// console.log( Object.keys(globals.listBots) );

		exchangeIDUpdate(global.exchangeID);
	},[global.exchangeID]);


	const logoutHandle = () =>{
		let deviceid = getId('chainbot');

		 disconnectDevice(deviceid).then((resp)=>{
			let {status} = resp.data;
			if(status === 'success'){
				window.location.reload(true);
			}
		});
	}


	const openWindowHandle = () =>{		
		window.open("/app/window.html", "extension_popup", "width=320,height=520,status=no,scrollbars=no,resizable=no,location=center");
	}

	return (
		<>
			
			<div className={"page home h100 "+ (menuAvatar?'menuAvatar-active':'') }>

				<div className="wrapper">
					<div className="header padding-h-20">
						
						<div className="line h100 mobile-nobreak tablet-nobreak avatar-wapper">
							<div className="column col0 center  ">
								{globals.user.email && (									
									<div className="avatar-image" style={{"backgroundImage":"url("+avatarImage+")"}} ></div>
									// <div className="avatar-image" style={{"backgroundImage":"url('http://www.gravatar.com/avatar/"+md5(globals.user.email)+"?s=100')"}}></div>																	
									
								)}								
							</div>							
							<div className="column  middle left ">
								<span className="avatar-name">{globals.user.name}</span>
								<span className="avatar-email">{globals.user.email}</span>
								{/*<span className="avatar-username">{globals.user.username}</span>*/}

								

								<ul className="avatar-tools">
									{ ( Object.keys(globals.user).length > 0 && globals.user.fields.telegramuserchecked === true) && (
										<li className="telegram" title={globals.user.fields.telegramuserid}><a></a></li>
									)}
								</ul>

							</div>							
							<div className="column col0 middle left ">
								<button type="button" className={"link  "+(menuAvatar?'-primary':'-secondary')} onClick={()=>{ menuAvatarUpdate(!menuAvatar); }}>
									<FontAwesomeIcon icon={faBars} />
								</button>
							</div>							
						</div>

						{menuAvatar && (
							<div className="menuAvatar">
								<ul>
									<li className="onlyifextension">
										<button type="button" className={"link -secondary padding w100 left  "} onClick={openWindowHandle}  >	
											<FontAwesomeIcon icon={faWindowRestore}  />&nbsp;&nbsp;Abrir em outra janela			
										</button>
									</li>
									<li>
										<QuestionInline className="w100">
											<div question="" className="w100">
												<button type="button" className={"link -secondary padding w100 left  "}  >	
													<FontAwesomeIcon icon={faTimesCircle} />&nbsp;&nbsp;Sair				
												</button>
											</div>
											<div alternatives="" className="w100">
												<div response="yes" className="w100">
													<button type="button" className={"link -primary padding w100 left  "} style={{"whiteSpace":"nowrap"}} onClick={(e)=>{ logoutHandle() }} >						
														<FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;Sair												
													</button>											
												</div>			
											</div>
										</QuestionInline>
										
									</li>
									
								</ul>
							</div>
						)}
						
						
					</div>
					<div className="main margin-top-10 padding-top-10 padding-h-20 sticky overflow-y-scroll ">										
						
						<Selectexchanges exchange={(id)=>{exchangeIDUpdate(id);  globals.getAll(false); }}/>

						<div className="line  mobile-nobreak tablet-nobreak margin-top-20 margin-bottom-10 ">
							<div className="column  center ">

								<span className={"btn  w100 padding contrast "+ (globals.performance>0?'-success':(globals.performance===0?'':'-error')) } title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle ">
											<FontAwesomeIcon icon={faChartLine} />
										</div>
										<div className="column  right middle padding-right">									
											<strong className="display:block">Rendimento total</strong>
											<small>$ {globals.performance.toFixed(2)}</small>											
											

										</div>								
									</div>
								</span>

							</div>							
						</div>

					{/*	<div className="line  mobile-nobreak tablet-nobreak margin-bottom-20">
							<div className="column  center ">

								<span className={"btn -secondary w100 padding " } title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle padding-left-20 padding-right">
																			
											<strong className="display:block">Rendimento</strong>
											
											<ChartPerformance/>
											

										</div>								
									</div>
								</span>

							</div>							
						</div>

						<div className="line  mobile-nobreak tablet-nobreak margin-bottom-20">
							<div className="column  center ">

								<span className={"btn -secondary w100 padding " } title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle padding-left-20 padding-right">
																			
											
											<strong className="display:block">
												Ordens executadas
												<small>
												&nbsp;({globals.order.totalLoss+globals.order.totalVictories})
												</small>
											</strong>
											
											<ChartOrders/>
											

										</div>								
									</div>
								</span>

							</div>							
						</div>*/}

						
						
						<div className="line  mobile-nobreak tablet-nobreak ">
							<div className="column  center ">

								<span className="btn w100 padding-h-0 nocontrast" title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle icon">
											<FontAwesomeIcon icon={faRobot} />
										</div>
										<div className="column  right middle padding-right">									
											<strong className="display:block">Robos em execução</strong>
											<small>{globals.botsActive}/{Object.keys(globals.listBots).length}</small>											
											

										</div>								
									</div>
								</span>

							</div>							
						</div>

						<div className="line  mobile-nobreak tablet-nobreak ">
							<div className="column  center ">

								<span className="btn w100 padding-h-0 nocontrast" title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle icon">
											<FontAwesomeIcon icon={faClock} />
										</div>
										<div className="column  right middle padding-right">									
											<strong className="display:block">Ordens abertas</strong>
											<small>{globals.orders}</small>											
											

										</div>								
									</div>
								</span>

							</div>							
						</div>


						<div className="line  mobile-nobreak tablet-nobreak ">
							<div className="column  center ">

								<span className="btn  w100 padding-h-0 nocontrast" title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										<div className="column col2 center middle icon">
											<FontAwesomeIcon icon={faWaveSquare} />
											{/*<FontAwesomeIcon icon={faCheckCircle} />*/}
										</div>
										<div className="column  right middle padding-right">									
											<strong className="display:block">Ordens em execução</strong>
											<small>{globals.positions}</small>											
											

										</div>								
									</div>
								</span>

							</div>							
						</div>

						
						{/*{ ( exchangeID === '[full]') && (*/}
							<div className="line  mobile-nobreak tablet-nobreak margin-top-10 margin-bottom-10 ">							
								<div className="column  center ">
									
										<ul className="balancelist">

										{ typeOf(exchangeData) === 'object' && (
											<>

											{Object.keys(exchangeData).map((key,index)=>{

												let balance_id = exchangeData[key]._id;
												let balancelist = exchangeData[key].fields.balancelist ?? {};
												let name = exchangeData[key].name;
												let status = exchangeData[key].fields.status;
												let enabled = exchangeData[key].enabled;
												let orders_open = exchangeData[key].orders_open ?? 0;
												let postions_open = exchangeData[key].postions_open ?? 0;
												let bots_active = exchangeData[key].bots_active ?? 0;
												let bots_list = exchangeData[key].bots_list ?? {};
												let performance = exchangeData[key].performance ?? 0;

												{/*console.log('==',exchangeData[key]);*/}

												if( exchangeID !== '[full]' && (exchangeID !== balance_id)) return (<React.Fragment key={index}></React.Fragment>);


												return (
													<li key={index} className={enabled === 'false'?'disabled':''} >
														<div className="line  mobile-nobreak tablet-nobreak">
															<div className="column col2 center top icon padding-top-10">
																{/*<FontAwesomeIcon icon={faDollarSign} className="-secondary link" />*/}
																
																{(status === 'success' && enabled === 'true') && (
																	<FontAwesomeIcon icon={faGlobe} className="-success link" />
																)}

																{(status === 'success' && enabled === 'false') && (
																	<FontAwesomeIcon icon={faGlobe} className="-secondary disabled link" />
																)}

																{(status !== 'success') && (
																	<FontAwesomeIcon icon={faGlobe} className="-error link" />
																)}

															</div>
															<div className="column  right middle padding-right">									
																
																<label>{name}</label>
																<ul>
																	{Object.keys(balancelist).map((key2,index2)=>{


																		return (
																			<li key={index2}>
																				<span>{Number(balancelist[key2]).toFixed(3)}</span> <cite>{key2}</cite>
																			</li>
																		)
																	})}
																	{ ( exchangeID === '[full]') && (
																		<>
																		<li className={"division "+(orders_open>0?'active':'')}><cite>Ordens abertas</cite> <span>{orders_open}</span></li>
																		<li className={ (postions_open>0?'active':'') }><cite>Posições abertas</cite> <span>{postions_open} </span></li>
																		<li ><cite>Bots registrados</cite> <span>{Object.keys(bots_list).length} </span></li>
																		<li className={ (bots_active>0?'active':'') }><cite>Bots ativos</cite> <span>{bots_active}</span></li>
																		<li className={ (performance>0?'positive':(performance<0?'negative':'')) }><cite>Rendimento</cite> <span>$ {performance.toFixed(2)} </span></li>
																		</>
																	)}
																</ul>
																
															</div>								
														</div>

													</li>
													
												)
											})}
											</>
											)}
										</ul>
								</div>
							</div>
						{/*)}*/}
		

						{/*{ ( exchangeID !== '[full]') && (
							<div className="line  mobile-nobreak tablet-nobreak margin-bottom-20">
								<div className="column  center ">

									<span className="button -secondary w100 padding nocontrast" title="Saldo total">
										<div className="line  mobile-nobreak tablet-nobreak">
											<div className="column col2 center middle icon">
												<FontAwesomeIcon icon={faDollarSign} />
											</div>
											<div className="column  right middle padding-right">									
												<strong className="display:block">Saldo</strong>
												<small>$ {globals.balance.toFixed(2)}</small>												
											</div>								
										</div>
									</span>

								</div>							
							</div>
						)}
					*/}
						

						

					</div>
					<div className="footer">

						<div className="headerArea">
								{/*<h1>Home</h1>*/}
							{/*<p>Automatize suas operações</p>*/}
						</div>
					
					</div>
				</div>
			
			</div>
		</>
	);
};


export default Component;
