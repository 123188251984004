import React, {useEffect,useRef} from "react";

import "./style.css";

const MenuMobile = (props) => {

  const inputRef = useRef(null);
   

  useEffect(() => {

    let liList = inputRef.current.querySelectorAll('li');
    
    for (var i = 0; i < liList.length; i++) {
        liList[i].onclick = function(){
            for (var a = 0; a < liList.length; a++) {
                liList[a].classList.remove('active');
            }
            this.classList.add('active');
        }
    }

  }, []);




	return (
		<>
		  <div className="MenuMobile" ref={inputRef}>
  		  <ul>
            {props.children} 
        </ul>
      </div>

		
		</>
	);
};


export default MenuMobile;
