import React, {useState,  useEffect,useContext, useRef} from "react";

// import {botGet,botPut,botPost,botDelete,botClone} from "../../../../services/apiBots";
// import {exchangeList} from "../../../../services/apiExchange";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome,faStore,faSave,faPlay,faStop,faThumbsUp,faThumbsDown,faSearch,faToggleOn,faChevronUp,faChevronDown,faBars,faBuildingColumns,faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

// import QuestionInline from "../../../../components/questioninline";
// import {slugfy} from "../../../../libs/slugfy.jsx";

// import {stampToFormat} from "../../../../libs/misc";

/*import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Rules from './rules';
import BackTest from './backtest';
import ModelsBot from './modelsbot';*/

// import ConfigInitial from './configinitial';
// import StrategyInitial from './strategyinitial';
// import BacktestInitial from './backtestinitial';

import {GlobalContext} from "../../../../contexts/global";
// import {indicators} from "../../../../helpers/trades/indicators";

import LocalStrategy from "./LocalStrategy/index.jsx";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';


import "./style.css";

const BotStore = ({storeMode,storeCloseHandle,choseAction,newBotHandle,fieldsUpdate}) => {

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null);


	  

	return (
		<form ref={inputRef} className="h100 --option-theme" >
			<div className="wrapper">
				<div className="header">
					<div className=" options_header">
						<div className="line mobile-nobreak tablet-nobreak padding">
							<div className="column col5 left middle">						
								<span>Estratégias</span>
							</div>
							<div className="column col0 right ">																	
								<button type="button" className={"link"} onClick={()=>{ storeCloseHandle() }} >						
									<FontAwesomeIcon icon={faTimes} />
									
								</button>
							</div>						
						</div>
					</div>
				</div>
				<div className="main">
					<div className="padding h100 sticky scrolly ">
					
						<Tabs>
							<TabList className="react-tabs__tab-list padding-bottom-20">								
								<Tab><FontAwesomeIcon icon={faHome} /> &nbsp;Local</Tab>
								<Tab><FontAwesomeIcon icon={faStore} /> &nbsp;Público</Tab>
							</TabList>

							<TabPanel>
								
								<LocalStrategy storeMode={storeMode} storeCloseHandle={storeCloseHandle} choseAction={choseAction} newBotHandle={newBotHandle} fieldsUpdate={fieldsUpdate} />
								

							</TabPanel>
							<TabPanel>
								<div className="center" >
									not implemented
								</div>
							</TabPanel>
						</Tabs>

						
					</div>
				</div>
				<div className="footer"></div>
			</div>

		</form>
	);
};


export default BotStore;