import React,{useEffect,useLayoutEffect,useState,useContext} from "react";
// import {useLocation} from "react-router-dom";

import {GlobalContext} from "../../../contexts/global";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan,faHandPaper,faStopCircle,faCheck,faTrash,faSyncAlt,faVial,faBox,faDollarSign,faSignal,faClock,faCalendar,faUpLong,faDownLong} from '@fortawesome/free-solid-svg-icons'

import {objToArray,sortKey,sortKey2,secToDate,wait,Convert2Digits,stampToFormat,typeOf} from "../../../libs/misc";

import {orderList, orderDelete} from "../../../services/apiOrders.jsx";

import QuestionInline from "../../../components/questioninline";
import ProgressBar from "../../../components/ProgressBar";

import LayoutCard from "../../../components/card/index.jsx";
import Tabs from "../../../components/tabs/index.jsx";

import Options from "../../../components/options/index.jsx";


import {order} from "../../../libs/order.js";

import Dialog from '@mui/material/Dialog';
import OrderDetail from "./orderdetail/index.jsx";

import Selectexchanges from "../../../layout/selectexchanges";



import "./style.css";

const Component = () => {

	const {settings,globals} = useContext(GlobalContext);
	// const location = useLocation();

	const [countOrders, countOrdersUpdate] = useState(0);
	const [list, listUpdate] = useState([]);


	const [totalBalance, totalBalanceUpdate] = useState(0);
	const [totalVictories, totalVictoriesUpdate] = useState(0);
	const [totalLoss, totalLossUpdate] = useState(0);
	
	const [performanceList, performanceListUpdate] = useState(0);
	
	const [moneyVictories, moneyVictoriesUpdate] = useState(0);
	const [moneyLoss, moneyLossUpdate] = useState(0);


	const [exchangeListID, exchangeListIDUpdate] = useState(null);


	const requestList = () =>{
		globals.getOrders();
	}

	const refreshList = () =>{
		
		let list = globals.listOrders;


		if( list.length < 1 ){
			listUpdate([]);
			return;
		}

	

		list = objToArray(list);		
		list = sortKey2(list,'fields','updatetime');		
		list = list.reverse();
		

		let totalBalancePre = 0;
		let totalVictoriesPre = 0;
		let totalLossPre = 0;
		let performanceListPreDate = [];
		let performanceListPrePrice = [];
		let performanceListPrePriceCoin = [];
		let moneyVictoriesValue = 0;
		let moneyLossValue = 0;			
		let countOrdersPre = 0;

		let listSegmented = {};

		for(let key in list){

			// list[key].fields = JSON.parse(list[key].fields);

			if( global.exchangeID !== '[full]' && (global.exchangeID !== list[key].exchange)) continue;

			countOrdersPre++;
		
			
			let a = list[key].fields.target_loss - list[key].fields.target_gain;
			let b = list[key].fields.coin_last_price - list[key].fields.target_gain;
			let c = (b*100/a) * -1;

			c = Math.abs(c);

			if(a < 0){
				c = (100-c);
			}
			c = c.toFixed(2);
			list[key].fields.target_percent = c;

			list[key].fields.orderstatus = '0';			
			list[key].fields.currentTime = (new Date()).getTime();
						
			let currentTotal = 0;


			if(list[key].fields.balance_last){
				currentTotal = (parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start));
			}else{
				currentTotal = ((list[key].fields.coin_last_price - list[key].fields.order_main_price) * list[key].fields.order_main_amount);
			}

			totalBalancePre += currentTotal;
			performanceListPrePrice[performanceListPrePrice.length] = currentTotal.toFixed(2);				
			performanceListPrePriceCoin[performanceListPrePriceCoin.length] = parseFloat(list[key].fields.coin_last_price).toFixed(2);
			performanceListPreDate[performanceListPreDate.length] = (new Date(parseInt( list[key].fields.updatetime )*1000)).toLocaleString();
		

			if( currentTotal >= 0){
				totalVictoriesPre++;
				moneyVictoriesValue += currentTotal;
			}else{
				totalLossPre++;
				moneyLossValue += currentTotal;
			}


			if( parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start) === 0){
				list[key].fields.target_status = 'equal';
			}else{
				if( parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start) > 0){
					list[key].fields.target_status = 'gain';
				}else{
					list[key].fields.target_status = 'loss';					
				
				}
			}

		
			if(typeof listSegmented[list[key].fields.exchange] === 'undefined') listSegmented[list[key].fields.exchange] = [];

			let listSegmentedCount = listSegmented[list[key].fields.exchange].length;			
			listSegmented[list[key].fields.exchange][listSegmentedCount] = list[key];

		}

		
		

		performanceListPreDate.reverse();
		performanceListPrePrice.reverse();

		let performanceListPre = {
			"status":true,
			"pricecoin":performanceListPrePriceCoin,
			"date":performanceListPreDate,
			"price":performanceListPrePrice
		};

		countOrdersUpdate(countOrdersPre);
		performanceListUpdate(performanceListPre);
		totalVictoriesUpdate(totalVictoriesPre);
		totalLossUpdate(totalLossPre);
		totalBalanceUpdate(totalBalancePre);		
		moneyVictoriesUpdate(moneyVictoriesValue);
		moneyLossUpdate(moneyLossValue);
		listUpdate(listSegmented);

	}

	useLayoutEffect(()=>{		
		refreshList();
	},[window.username,globals.listOrders]);



	let [currentItem, currentItemUpdate] = useState(null);
	let [openOrder, openOrderUpdate] = useState(false);
	const openOrderHandle = (key,key2) => {
	   currentItemUpdate([key,key2]);
	   openOrderUpdate(true);
	};
	const closeOrderHandle = () => {
		openOrderUpdate(false);
	};


	const selExchangeHandle = (id) => {
		exchangeListIDUpdate(id);
	};



	const [erasing, erasingUpdate] = useState(false);
	const [erasingIndex, erasingIndexUpdate] = useState(0);

	const deleteAllHandle = async (e) =>{

		erasingUpdate(true);

		let index = 1;
		for(let key in list[exchangeListID]){

			erasingIndexUpdate(index);

			await orderDelete(window.username,list[exchangeListID][key]._id).then((response)=>{
				let status = response.data.status;
				let content = response.data.body;
						
			});

			index++;

			await wait(500);
		}

		erasingUpdate(false);
		refreshList();

	}



	return (
		<>

			<Dialog onClose={closeOrderHandle} open={openOrder}  >		
				<OrderDetail refreshList={refreshList} closeOrderHandle={closeOrderHandle} list={list} currentItem={currentItem} ></OrderDetail>
			</Dialog>

			<div className="page orders h100 ">

				<div className="wrapper">
					<div className="header ">
						
				

						<div className="line barControl mobile-nobreak tablet-nobreak ">
							<div className="column col6 center padding-h-10 padding-top-10 padding-bottom-10">
								<span className="btn w100 " title="Saldo total">
									<label className="display:block">Saldo total</label>
									<small>$ { parseFloat(totalBalance).toFixed(2)}</small>
								</span>
							</div>
							<div className="column  center padding-right-10">
								
								<span className="btn w100 " >
									<label  className="display:block">Ordens</label>
									<small>{countOrders}</small>
								</span>
								
							</div>
						</div>

						<div className="line barControl mobile-nobreak tablet-nobreak">							
							<div className="column col6 center padding-h-10 padding-top-10 padding-bottom-20">
								
								<span className="btn w100 " >
									<label  className="display:block">Vitórias</label>
									<small>$ {parseFloat(moneyVictories).toFixed(2)}  ({totalVictories})</small>
								</span>
								
							</div>					
							<div className="column  center padding-right-10">
								
								<span className="btn w100 " >
									<label  className="display:block ">Derrotas</label>
									<small>$ {parseFloat(moneyLoss).toFixed(2)}  ({totalLoss})</small>
								</span>
								
							</div>
						</div>


						


					</div>
						


						

						

						<div className="line mobile-nobreak tablet-nobreak padding barButtons">
							<div className="column left">
								<button type="button" className="link -secondary " >
									<FontAwesomeIcon icon={faSyncAlt} onClick={()=>{listUpdate([]);requestList()}} />
								</button>
							</div>
							<div className="column right">
								{Object.keys(list).length > 0 && (
									<QuestionInline>
										<div question="">
											<button type="button" className={"link -secondary  "}  >	
												{(erasing === true)? (												
													<>
														<small>{erasingIndex}/{ list[exchangeListID].length }</small>&nbsp;&nbsp;<label className="loading"></label>Limpar
													</>
												):(												
													<>
													<FontAwesomeIcon icon={faTrash} />
													&nbsp;&nbsp;Limpar
													</>
												)}					
											</button>
										</div>
										<div alternatives="">
											<div response="yes">
												<button type="button" className={"link  -error  "} style={{"whiteSpace":"nowrap"}} onClick={(e)=>{ deleteAllHandle() }} >						
													<FontAwesomeIcon icon={faCheck} />		
													&nbsp;&nbsp;Limpar												
												</button>											
											</div>			
										</div>
									</QuestionInline>
								)}
							</div>
						</div>

						<div className="line barMenu mobile-nobreak tablet-nobreak padding-h-10 tabwrapper">														
							<div className="column  center ">

								<Tabs content={list}>				

						
									{Object.keys(list).map((key,index) => {
										let exchangeIterate = null;
									
										for(let keypre in globals.exchanges){											
											if( globals.exchanges[keypre]._id === key){
												exchangeIterate = globals.exchanges[keypre];	
											}
										}
									

										if( exchangeIterate === null ){
											exchangeIterate = {
												"id":key,
												title:"unknown",
												fields:{
													orders_data: list[key],
												}
											};																					
										}else{
											exchangeIterate.fields.orders_data = list[key];
										}

										
										return (
											<li key={index}>
												<a onClick={(e)=>{ selExchangeHandle(exchangeIterate._id); }}>
													
													{exchangeIterate.name} 

													{ ( typeof exchangeIterate?.fields.orders_data !== 'undefined' ) && (
														<>
															&nbsp;({Object.keys(exchangeIterate.fields.orders_data).length})
														</>
													)}

												</a>
											</li>																							
										)
									})}
																		
								</Tabs>

							</div>
						</div>


					<div className="main sticky overflow-y-scroll">	

						{ ( typeOf(list) === 'array' ) && (
							<div className="loading center padding link "></div>
						)}

						

						{ ( typeOf(list) === 'object' && Object.keys(list).length < 1 ) && (							
							<div className="center" >
								<p>Sem registro</p>
							</div>
						)}


						<div className="listCards padding-top-10 padding-h-10">
							<ul>
								{Object.keys(list).map((key,index) => {

									{/*console.log(key,index);*/}
									{/*let balance_id = globals.exchanges[key].id;
									let balancelist = globals.exchanges[key].balancelist;
									let title = globals.exchanges[key].title;*/}

									let exchangeIterate = null;

									for(let keypre in globals.exchanges){

										if( globals.exchanges[keypre]._id === key){
												exchangeIterate = globals.exchanges[keypre];												
										}
									
									}

									if( exchangeIterate === null ){

											exchangeIterate = {
												"id":key,
												title:"unknown",
												fields:{
													orders_data: list[key],
												}
											};
											
											{/*return (<React.Fragment key={index}></React.Fragment>);*/}
										
										}
										
									{/*if(exchangeIterate === null) return (<React.Fragment key={index}></React.Fragment>);*/}
									
									{/*console.log(exchangeListID,exchangeIterate._id);*/}

									if( exchangeListID !== exchangeIterate._id ) return (<React.Fragment key={index}></React.Fragment>);


									return (
										<React.Fragment key={index}>
											<li>
												{/*<label className="exchange_title">{exchangeIterate.title}</label>*/}
												<ul>
													{Object.keys(list[key]).map((key2,index2) => {

														

														{/*if( global.exchangeID !== '[full]' && (global.exchangeID !== list[key].exchange)) return (<React.Fragment key={index}></React.Fragment>);*/}
														
														return(
															<li key={index2} onClick={()=>{ openOrderHandle(key,index2); }} className={ (order(list[key],index2).statusbase)+" "+(order(list[key],index2).status)+" "+(list[key][index2].fields.action?'action-'+list[key][index2].fields.action:'') }>
												        		<LayoutCard 
										        					key={index2} 
										        					// enabledValue={list[index2].enabled}
										        					
										        					splitMain={
													        			<>								        				
													        				<div className="line mobile-nobreak" title={"Ganho/Perda - "+"Saldo inicial: $ "+list[key][index2].fields.balance_start+" - Saldo final: $ "+list[key][index2].fields.balance_last}>
													        					<div className="column col2">
													        						<small className=" index">
													        							<span className="number">{ Convert2Digits(Object.keys(list[key]).length-parseInt(index2)) } </span>
													        							
													        								<span className={"order_status " + ((list[key][index2].fields.action === 'cancel')?'loading loading-position':'') }>

													        								{ (list[key][index2].fields.action === 'cancel') && (
														        								<FontAwesomeIcon icon={faHandPaper} />														        								
													        								)}

													        								{ (list[key][index2].fields.action === 'canceled') && (
													        									<FontAwesomeIcon icon={faBan} />
													        								)}
													        								</span> 

													        						</small>
													        					</div>
													        					<div className="column">
													        						<div className={"target_status "+list[key][index2].fields.order_main_side+" "+list[key][index2].fields.target_status}>
														        						{ list[key][index2].fields.balance_last ? (
															        						<>																
																								<span className={"miniicon " }><FontAwesomeIcon icon={faUpLong} /></span> $ {order(list[key],index2).roi_pnl }																																	
															        						</>																
																						):(
															        						<>																
																								<span className={"miniicon " }><FontAwesomeIcon icon={faUpLong} /></span> $ { order(list[key],index2).roi_pnl }																	
															        						</>																
																						)}
																						<small className="target_status_percent">{ order(list[key],index2).roi_porcent } %</small>
													        						</div>

													        						<small className="createtime">{ stampToFormat(new Date(parseInt(list[key][index2].fields.updatetime))) }</small>

													        					</div>
													        					<div className="column col3 right">
													        						{/*<small className="botname" title={ "ID: "+list[key][index2]._id+" - order_sl_id: "+list[key][index2].fields.order_sl_id }>{ list[key][index2].name }</small>*/}
													        						<small className="order_main_simbol">{list[key][index2].fields.order_main_symbol}</small>	
													        						<small className="coin_last_price"> { parseFloat(list[key][index2].fields.coin_last_price).toFixed(3)} {/*list[key][index2].fields.order_interval*/}</small>	
													        						<small className="order_main_pln"><cite>pln:</cite> {Number(list[key][index2].fields.order_main_pln).toFixed(2)}</small>	
													        					</div>
													        				</div>
													        			</>
													        		}

													        		splitInfo={
													        			<>											
													        			</>
													        		}


										        				/>

										        				<ProgressBar percentage={order(list[key],index2).target_percent} style={{"height":"2px"}} />

				

												     		</li>
														)
													})}
											</ul>
											</li>
										</React.Fragment>
									)


							        
							     })
							    } 
								
								

								 	
							</ul>
						</div>

					</div>
					<div className="footer">

						<div className="headerArea">
							<h1>Ordens <span className="badge">{Object.keys(globals.listOrders).length}</span></h1>
							{/* <h1>Ordens <span className="badge">{countOrders}</span></h1> */}
							{/*<p>Listagem de bots criados</p>*/}
						</div>

					</div>
				</div>
			
			</div>
		</>
	);
};


export default Component;
