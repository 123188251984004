import { env } from '../.env.js';
import axios from "axios";


export const api = axios.create({
	baseURL: env.AchoordURL
});



export const backtestList = async (username) => {	
	return await api.get("/backtest/list/?username="+username);
}

export const backtestGet = async (username,botid) => {	
	return await api.get("/backtest/get/?username="+username+"&botid="+botid);	
}

export const backtestPut = async (username,botid,data) => {	
	return await api.put("/backtest/put/?username="+username+"&botid="+botid,data);	
}

export const backtestPost = async (username,data) => {
	return await api.post("/backtest/post/?username="+username,data);
}

export const backtestDelete = async (username,botid) => {	
	return await api.delete("/backtest/delete/?username="+username+"&botid="+botid);
}



