import React,{useEffect,useState,useRef} from "react";

const Component = (props) => {

	const [fromDate, fromDateUpdate] = useState();	
	const [toDate, toDateUpdate] = useState();	
	

	let countDays = 0;

	if(props.currentDay !== 0 )		
		countDays = props.currentDay;

	let daysTest = [];
	for (var i = 1; i < 91; i++) {
		daysTest[i] = i;
	}

	let setList = (value) => {

		props.currentDayUpdate(value);

		countDays = value;

		// props.dayProcess = 0;
		props.dayProcessUpdate(0);

		let listDaysPre = [];
		for (var i = 0; i < countDays; i++) {
			if(props.targetdate)
				var today = new Date(props.targetdate);
			else
				var today = new Date();

			var tomorrow = new Date(today);

			// console.log( tomorrow.getDate() );
			tomorrow.setDate(tomorrow.getDate()-i);
			// tomorrow.setDate(tomorrow.getDate()-i);
			let d = tomorrow.toLocaleDateString();

			let dArray = d.split('/');

			// d = dArray[1]+'/'+dArray[0]+'/'+dArray[2];
			// let d = (tomorrow.getUTCMonth()+1)+'/'+tomorrow.getDate()+'/'+tomorrow.getUTCFullYear();

			listDaysPre[i] = {
				"status":'',
				"amount_roi":0,
				"balance_final":0,
				"loss_count":0,
				"gain_count":0,
				"amount_initial":0,
				"balance_initial":0,				
				"date":d,
				"loading":"false"
			};
		}
		
		listDaysPre.reverse();

		props.listDaysUpdate(listDaysPre);		
	}


	useEffect(()=>{
		if(props.currentDay === 'day'){		
			handleDateList();
		}
	},[fromDate , toDate,props.currentDay]);


	let handleDateList = () =>{


		

		if(fromDate && toDate){

			let listDaysPre = [];
			let indexInitial = 0;

			/*let today = new Date(toDate);
			today.setDate(today.getDate()+1);
			let d = today.toLocaleDateString();
			// let d = (today.getUTCMonth()+1)+'/'+today.getDate()+'/'+today.getUTCFullYear();

			let dArray = d.split('/');
			let dcomp = dArray[2]+'-'+(dArray[0])+'-'+(parseInt(dArray[1]));
			let dcompReverse = dArray[0]+'/'+(dArray[1])+'/'+dArray[2];

			
			console.log(toDate , dcomp,dcompReverse);

			if(toDate === dcomp){
				listDaysPre[indexInitial] = {"date":dcompReverse,"loading":"false"};
				indexInitial++;
			}
*/
				let dateRange = function(startDate, endDate, steps = 1) {


					let startDate_adj = new Date(startDate);
					let endDate_adj = new Date(endDate);

					

					startDate_adj.setDate(startDate_adj.getDate()+1);
					startDate = startDate_adj.toLocaleDateString();

					endDate_adj.setDate(endDate_adj.getDate()+1);
					endDate = endDate_adj.toLocaleDateString();


				  const dateArray = [];
				  let currentDate = new Date(startDate);
				  
				  let endD = new Date(endDate);

				 

				  // endD = endD.setDate(endD.getDate()+1);

				  while (currentDate <= endD) {
				    dateArray.push(new Date(currentDate));
				 	
				 	// console.log(dateArray);
				    // Use UTC date to prevent problems with time zones and DST
				    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
				  }
				    // dateArray.push(new Date(endDate));

				  return dateArray;
				}

				/*var getDaysArray = function(start, end) {
				    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
				        arr.push(new Date(dt));
				    }
				    return arr;
				};*/
				let daylist = dateRange(fromDate, toDate);

				console.log(daylist);
				// let daylist = getDaysArray(new Date(fromDate),new Date(toDate));
				/*daylist.map((v)=>{
					// console.log();

					let d = v.toLocaleDateString()

					listDaysPre[listDaysPre.length] = {"date":d,"loading":"false"};

					return d;
				});*/

				for (var i = indexInitial; i < daylist.length; i++) {
					let d = daylist[i].toLocaleDateString();
					// listDaysPre[i] = {"date":d,"loading":"false"};;
					listDaysPre[i] = {
						"status":'',
						"amount_roi":0,
						"balance_final":0,
						"loss_count":0,
						"gain_count":0,
						"amount_initial":0,
						"balance_initial":0,				
						"date":d,
						"loading":"false"
					};
				}
				

				props.listDaysUpdate(listDaysPre);	
			// },1000);
		}
	}


	return (
		<>		
		<div className="headerControl">
						
			<div className="line  mobile-nobreak  padding-h-10 padding-bottom-10">
				<div className="column col6 ">

					{/*<p>Escoha os dias para executar o backtest.</p>*/}
					
					<label>Dias de teste</label><br/>
					<select className="select  w100"  onChange={(e) => { setList(e.target.value); }} value={props.currentDay} >
						<option value="0" >Escolha os dias</option>
						<option value="day" >[ Faixa ]</option>
						{ daysTest.map((key,index) => {
							
				        	return (
								<option key={key} value={key}>{key}</option>
							)
				        })}
					</select>

				</div>
				<div className="column padding-left">
					{  props.currentDay !== 0 && props.currentDay !== '0' && (
						<>	
							<div className="w100 ">
								<label>Simular saldo $</label><br/>
								<input type="number" name="balancesimulate"  className="  w100"  onChange={(e)=>{ props.balanceSimulateUpdate(e.target.value) }} defaultValue={props.balanceSimulate}  />
							</div>
						</>
					)}

				</div>
			</div>

			{props.currentDay === 'day' && (
				<>
					<div className="line mobile-nobreak padding-h-10 padding-bottom-10">
						<div className="column col6 ">

							<div className="w100">
								<label>From</label><br/>
								<input type="date" name="fromdate"  className=" margin-0 w100" onChange={(e)=>{ fromDateUpdate(e.target.value); }} defaultValue={fromDate} max={fromDate} />
							</div>
							

						</div>
						<div className="column padding-left">
							<div className="w100 ">
								<label>To</label><br/>
								<input type="date" name="todate"  className=" margin-0 w100"  onChange={(e)=>{ toDateUpdate(e.target.value) }} defaultValue={toDate} max={toDate} />
							</div>
						</div>
					</div>
				</>							
			)}
		</div>
		</>
	)
}

export default Component