
import React,{useEffect,useState} from "react";


import {exchangeList} from "../../services/apiExchange.jsx";

import './style.css';


const Component = (props) => {

	const [exchange, exchangeUpdate] = useState(false);

	const [listExchanges, listExchangesUpdate] = useState( global.exchangeList ?? {});

	const getExchangesList = () =>{
				
		if(typeof global.listExchanges === 'undefined') return;


		listExchangesUpdate(global.listExchanges);

		/*exchangeList(window.username).then((response)=>{
			
			let status = response.data.status;
			let list = response.data.content;

			global.exchangeList = list;
			
			listExchangesUpdate(list);

					
		}).catch((response)=>{
			
		});*/

		
	}

	useEffect(()=>{
		global.exchangeID = '[full]';
	},[window.username]);


	useEffect(()=>{	
		getExchangesList();
	},[global.listExchanges])


	useEffect(()=>{		
		if(exchange === false) return;
		global.exchangeID = exchange;
		props.exchange(exchange);
	},[exchange])

	
	let result = (
		<>		
			<div className="selectexchanges">
				<div className="line  mobile-nobreak tablet-nobreak  w100">
					<div className="column middle  ">
						<select className="select w100" onChange={(e)=>{ exchangeUpdate(e.target.value) }} value={exchange} >									
							<option value="[full]">Todas</option>

							
								{Object.keys(listExchanges).map((key,index)=>{	

									{/*if(listExchanges[key].status!=='success') return (<React.Fragment key={index}></React.Fragment>);*/}

									return (
										<React.Fragment key={index}>
											<option  value={listExchanges[key]._id}>{listExchanges[key].name}</option>
										</React.Fragment>
									)
								})}

							
						</select>
					</div>							
					<div className="column col0 middle right">
						<label>Corretora</label>
					</div>				
				</div>
			</div>																	
			
		</>
	);


	return Object.assign({exchange: exchange}, result);

};






export default Component;
