import React,{useEffect,useState,useRef} from "react";

const Component = (props) => {

	

	return (
		<>
		{/*{JSON.stringify(props.listDays)}*/}
			<div className="   " visible={ props.listDays.length < 1?'false':'true' }>							 	
				<br/>
			 	{ props.listDays.map((key,index) => {

					
		        	return (

		        		<div className={"margin-bottom-20 itemDays boxItemBacktest "+props.listDays[index].status} index={index}  key={index}>

							<div className="line " >
								<div className="column left top ">

									<h3><small>{ props.listDays.length-index }</small>{props.listDays[index].date} <span className="loading -primary-text" visible={props.listDays[index].loading}></span> </h3>
								</div>
								<div className="column left right middle ">
									<span infobox={index}></span>
								</div>
							</div>
							
							<span className="result" result={index} >

								<table className="tableBacktest">
									<tbody>
										<tr>
											<th width="40%">Saldo inicial</th>
											<td >
												<span balancestart={index}>$ {props.listDays[index].balance_initial.toFixed(2)}</span>
												<small className="fee"><small test_trade_balance_start={index}></small></small>
											</td>
										</tr>
										<tr>
											<th width="40%">Aporte</th>
											<td >
												<span contribution={index}>$ {props.listDays[index].amount_initial.toFixed(2)}</span>
												<small className="fee"><small test_trade_balance_start={index}>({props.listDays[index].amount_percent}%)</small></small>
											</td>
										</tr>
										<tr>
											<th width="40%">Trade Gain</th>
											<td >
												<span tradegain={index}>{props.listDays[index].gain_count}</span>
												<small className="fee"><small test_trade_gain_percent={index}></small></small>
											</td>
										</tr>	
										<tr>
											<th>Trade Loss</th>
											<td >
												<span tradeloss={index}>{props.listDays[index].loss_count}</span>
												<small className="fee"><small test_trade_loss_percent={index}></small></small>
											</td>
										</tr>
										{/*<tr>
											<th>Trade ROI</th>
											<td >
												<span test_trade_general_percent={index}></span>
											</td>
										</tr>	*/}
										<tr>
											<th>ROI</th>
											<td >
												<span test_roi_estimate={index}>$ {props.listDays[index].amount_roi.toFixed(2)}</span>
											</td>
										</tr>{/*
										<tr>
											<th>ROI do Aporte</th>
											<td >
												<span test_balance_estimate={index}></span>
											</td>
										</tr>*/}
										<tr>
											<th>Saldo final</th>
											<td >
												<span balanceend={index}>$ {props.listDays[index].balance_final.toFixed(2)}</span>
											</td>
										</tr>	
										<tr>
											<th>Status</th>
											<td  statustd={index}>
												<span status={index}>
													{props.listDays[index].status}
												</span>
											</td>
										</tr>						
									</tbody>
								</table>
							</span>

						</div>

		        		
		        	)
		    	})}						
			</div>
		</>
	)
}

export default Component