import React, {useState,  useEffect,useContext, useRef} from "react";

import {GlobalContext} from "../../../contexts/global";

// import {botGet,botPut,botPost,botDelete,botClone} from "../../../../services/apiBots";
// import {exchangeGet,exchangePut,exchangePost,exchangeDelete,exchangeClone} from "../../../services/apiExchange";

import {userGet,userPut} from "../../../services/apiUser";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckSquare,faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

import QuestionInline from "../../../components/questioninline";

import {md5} from "../../../libs/md5";

import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
// import Rules from './rules';
// import BackTest from './backtest';

import "./style.css";

// import avatarImage from "../../../assets/img/avatar.png";
import avatarImageMiddle from "../../../assets/img/logo.png";
// import avatarImageMiddle from "../../../assets/img/avatar-middle.png";

const Admin = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null);

	const [listExchanges, listExchangesUpdate] = useState([]);
	
	const [fieldsCompare,fieldsCompareUpdate] = useState({});

	const [fields,fieldsUpdate] = useState({
		fields:{
			fields:{
				telegramuser:"",
				telegramuserchecked:false,
			}
		}
	});
	// const [listBots, setListBots] = useState([]);
	const [fieldsLoaded,fieldsLoadedUpdate] = useState(false);

	const onSubmitHandle = (e) =>{		
		e.preventDefault();

		save();

	}


	const save = () =>{

		if(inputRef.current.querySelector('form [type="submit"]'))
		inputRef.current.querySelector('form [type="submit"]').classList.add('loading');
		
		inputRef.current.classList.add('disabled');
		
		let dataNew = JSON.stringify(fields);
		dataNew = JSON.parse(dataNew);

		if( dataNew.fields.blockorders === true ) dataNew.fields.blockorders = 'true';
		if( dataNew.fields.blockorders === false ) dataNew.fields.blockorders = 'false';

		

		if(fieldsCompare.fields.telegramuser !== fields.fields.telegramuser){
			dataNew.fields.telegramuserchecked = false;
			dataNew.fields.telegramuserid = null;
		}

		
		dataNew.fields = JSON.stringify(dataNew.fields);

		
		/*if( props?.adminCurrent === 'new' ){


			userPut(window.username,  fields).then((response)=>{

				let {status,content} = response.data;
				
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
				inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');

				// if(inputRef.current.querySelector('form'))
				inputRef.current.classList.remove('disabled');

				if(status === 'success'){

					// if(inputRef.current.querySelector('form [type="submit"]')){
						// setListBots([]);
						fieldsUpdate({});

						fieldsLoadedUpdate(false);
						// props.settingsCurrentUpdate( null );
						// props.posAdminCallback();

						inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
						setTimeout(()=>{
							inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
						},2000);

					// }
			
				}else{

					inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
					setTimeout(()=>{
						inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
					},2000);
				}
			});


			

			return;
		}
*/
		userPut(window.username, dataNew).then((response)=>{

			let {status} = response.data;
			
			console.log(fields);
			fieldsCompareUpdate(JSON.parse(JSON.stringify(fields)));

			// return;
			inputRef.current.classList.remove('disabled');


			if(status === 'success'){

				if(inputRef.current.querySelector('form [type="submit"]')){
					inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
					inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');

					// setListBots([]);
					// fieldsUpdate({});

					fieldsLoadedUpdate(false);
					// props.settingsCurrentUpdate( null );
					// props.posAdminCallback();


					load();

					inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
					setTimeout(()=>{
						inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
					},2000);

				}
		
			}else{

				inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
				setTimeout(()=>{
					inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
				},2000);
			}
		});
		
	}


	const load = (e) =>{

		let body = globals.user;
		if(Object.keys(body).length < 1) return;
		body = [globals.user];


		
		if( body[0].fields.blockorders === 'true' ) body[0].fields.blockorders = true;
		if( body[0].fields.blockorders === 'false' ) body[0].fields.blockorders = false;			
		if( body[0].fields.telegramuserchecked === 'true' ) body[0].fields.blockorders = true;
		if( body[0].fields.telegramuserchecked === 'false' ) body[0].fields.blockorders = false;
		
		if(typeof body[0].fields.blockorders === 'undefined' ) body[0].fields.blockorders = false;
		if(typeof body[0].fields.telegramuser === 'undefined' ) body[0].fields.telegramuser = '';
		if(typeof body[0].fields.telegramuserchecked === 'undefined' ) body[0].fields.telegramuser = false;


		fieldsCompareUpdate(JSON.parse(JSON.stringify(body[0])));			
		fieldsUpdate(body[0]);

		setTimeout(()=>{
			fieldsLoadedUpdate(true);
		},50);


		/*return;

		

		userGet(window.username).then((response)=>{


			let {status,body} = response.data;
			if(status === 'success'){

				body[0].fields = JSON.parse(body[0].fields);
				

				if( body[0].fields.blockorders === 'true' ) body[0].fields.blockorders = true;
				if( body[0].fields.blockorders === 'false' ) body[0].fields.blockorders = false;			
				if( body[0].fields.telegramuserchecked === 'true' ) body[0].fields.blockorders = true;
				if( body[0].fields.telegramuserchecked === 'false' ) body[0].fields.blockorders = false;
				
				if(typeof body[0].fields.blockorders === 'undefined' ) body[0].fields.blockorders = false;
				if(typeof body[0].fields.telegramuser === 'undefined' ) body[0].fields.telegramuser = '';
				if(typeof body[0].fields.telegramuserchecked === 'undefined' ) body[0].fields.telegramuser = false;


				fieldsCompareUpdate(JSON.parse(JSON.stringify(body[0])));			
				fieldsUpdate(body[0]);

				setTimeout(()=>{
					fieldsLoadedUpdate(true);
				},50);
			}
		});*/
		
	}




	useEffect(()=>{

		if(props?.adminCurrent === null) return;

		props.linkUpdate({
			actionOnClose:()=>{					
				fieldsLoadedUpdate(false);
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');
				props.settingsCurrentUpdate( null );

				inputRef.current.querySelector('.sticky').scrollTo(0,0);
			}
		});

		

		
		inputRef.current.querySelector('.sticky').scrollTo(0,0);
		load();
	},[props.adminCurrent]);
	
	useEffect(()=>{
		fieldsLoadedUpdate(false);
		load();
	},[globals.user]);


	useEffect(()=>{
		if(fieldsLoaded === false) return;
		if(props?.adminCurrent === 'new') return;
			// if(inputRef.current.querySelector('form [type="submit"]')){
				inputRef.current.querySelector('form [type="submit"]').classList.add('mark');
			// }

	},[fields]);



	/*const openRuleHandle = (newValue) => {
	    // setOpen(newValue);
	  };*/

	  let [openBacktest, openBacktestUpdate] = useState(false);
	  const openBacktestHandle = key => {
		   // currentKeyUpdate(key);
		   openBacktestUpdate(true);
	  };
	  const handleBacktestClose = () => {

	  	openBacktestUpdate(false);
	  };

	
	  let [currentKey, currentKeyUpdate] = useState(null)
	  let [open, openUpdate] = useState(false)

	  


	  const openRuleHandle = key => {

	   currentKeyUpdate(key);
	   openUpdate(true);
	   // onCloseUpdate(value);
	  };

	 

	 

	  


	  const handleClose = () => {
	  	
	  	

	  	openUpdate(false);
	  };


	

	 // if( Object.keys(fields).length === 0 ) return;


	return (
		<form ref={inputRef} onSubmit={onSubmitHandle}>
			{/*<Dialog onClose={handleClose} open={open}  >

				<Rules handleClose={handleClose} listBots={listBots} setListBots={setListBots} currentKey={currentKey} ></Rules>
				
			</Dialog>

			<Dialog onClose={handleBacktestClose} open={openBacktest}  >
				<BackTest handleBacktestClose={handleBacktestClose} ></BackTest>				
			</Dialog>*/}
			
			<div className="--admin h100 ">
				


					<div className=" options_header">
						<div className="line mobile-nobreak tablet-nobreak padding">

							

							<div className="column col5 left middle">
								
								<button type="submit" className={"button -third w100"} onClick={(e)=>{  }} >						
									{ (props?.adminCurrent === 'new') ? (
										<>
											Criar
										</>
									):(
										<>
											Salvar
										</>
									)}
								</button>

								{/*<h1>Bot</h1>*/}
								{/*<label className="options_title" dangerouslySetInnerHTML={{__html: "" }} ></label>*/}

							</div>
							<div className="column  left middle">
							</div>
							

							
							<div className="column col0 right ">																	
								{/*<Switch checked={(fields.enabled)?true:false} onChange={(e)=>{ fields.enabled=e.target.checked; fieldsUpdate({...fields}) }} name="jason" />*/}
							</div>						
						</div>
					</div>

					<div  className="sticky vh70  "  >

						
						<div className="wrapper">
							<div className="header">

								<div className="avatar-area">
									<div className="line mobile-nobreak tablet-nobreak  padding-top-20 padding-bottom-20"  >
										
										<div className="column top center">
											
											{fields.email && (
												<div className="avatar-image" style={{"backgroundImage":"url("+avatarImageMiddle+")"}} ></div>												
											)}
											
											{/*<img className="avatar-image" src={"http://www.gravatar.com/avatar/"+md5(fields.email)+"?s=100"} />*/}
											{/*<label className="display:block margin-bottom-10">Avatar Icon by gravatar.com</label>*/}

										</div>
									</div>	
								</div>	

							</div>
							<div className="main">

								<div className="line mobile-nobreak tablet-nobreak padding"  >
									<div className="column top ">

										<h3 className="margin-bottom-10 right">Dados pessoais</h3>

										<div className="input-float flatmodel ">
										      <input type="text" name="username" readOnly disabled required key2={fields.username} defaultValue={fields.username} onChange={(e)=>{ fields.username=e.target.value; fieldsUpdate({...fields}) }} />
										      <label dangerouslySetInnerHTML={{__html: "Username"}} ></label>
										</div>	

										<div className="input-float flatmodel ">
										      <input type="text" name="name"  key2={fields.name} defaultValue={fields.name} onChange={(e)=>{ fields.name=e.target.value; fieldsUpdate({...fields}) }} />
										      <label dangerouslySetInnerHTML={{__html: "Name"}} ></label>
										</div>				
										
										
										<div className="input-float flatmodel ">
										      <input type="text" name="email"  key2={fields.email} defaultValue={fields.email} onChange={(e)=>{ fields.email=e.target.value; fieldsUpdate({...fields}) }} />
										      <label dangerouslySetInnerHTML={{__html: "Email"}} ></label>
										</div>				
										
										
										<h3 className="margin-top-30 margin-bottom-10 right">Configurações</h3>

										<div className="line mobile-nobreak tablet-nobreak "  >							
											<div className="column top ">
												
												{ (typeof fields.fields !== 'undefined') &&(
													<>
													<Switch checked={(fields.fields.blockorders)?true:false} onChange={(e)=>{ fields.fields.blockorders=e.target.checked; fieldsUpdate({...fields}) }} name="jason" />
													<label>Bloquear abertura de ordens</label>
													</>
												)}
														

											</div>
										</div>


										<h3 className="margin-top-30 margin-bottom-10 right">Notificações</h3>

										<div className="input-float flatmodel ">
										      <input type="text" name="name"   defaultValue={fields.fields.telegramuser} onChange={(e)=>{ fields.fields.telegramuser=e.target.value; fieldsUpdate({...fields}) }} />
										      <label dangerouslySetInnerHTML={{__html: "Usuário do telegram"}} ></label>
										</div>

										

										{ fields.fields.telegramuserchecked === true && (
											<cite className="line mobile-nobreak tablet-nobreak margin-top-10"  >
												<div className="column col0 middle center ">

														<span className="link -success" >
															<FontAwesomeIcon icon={faCheckSquare} />
														</span>

												</div>
												<div className="column top padding-left-10">
													<label>User checked ({fields.fields.telegramuserid})</label>
												</div>
											</cite>
										)}

										

										<div className="margin-top-20"></div>

										
											<ul className="list -style-none ">											
												<li>
													<label>Receber todas as notificações</label>
													<cite>Envie <strong>/startnotifications</strong> para o robo <strong>@chanbotbot</strong></cite>
												</li>
												<li>
													<label>Parar de receber todas as notificações</label>
													<cite>Envie <strong>/stopnotifications</strong> para o robo <strong>@chanbotbot</strong></cite>
												</li>
											</ul>

										<br/>
										<br/>

									</div>
								</div>

							</div>
							<div className="footer">

								

							</div>
						</div>
					

							

						

						

									
					</div>
			</div>
		</form>
	);
};


export default Admin;