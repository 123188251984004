import React,{useContext,useEffect,useState} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck,faTimes,faSyncAlt,faVial,faToggleOn,faToggleOff,faTrash} from '@fortawesome/free-solid-svg-icons'

import {notificationsList,notificationsDelete} from "../../../services/apiNotifications.js";

import LayoutCard from "../../../components/card/index.jsx";

import QuestionInline from "../../../components/questioninline";

import {objToArray,sortKey,sortKey2,stampToFormat,wait,typeOf} from "../../../libs/misc.js";

import {GlobalContext} from "../../../contexts/global";

import "./style.css";

const Component = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	const [erasing, erasingUpdate] = useState(false);
	const [erasingIndex, erasingIndexUpdate] = useState(0);

	const [list, listUpdate] = useState([]);

	const refreshList = () =>{
		
		let list = globals.notifications;

		
		list = objToArray(list);		
		// list = sortKey2(list,'fields','createtime');		
		list = sortKey(list,'createdat');		
		list = list.reverse();
		

		listUpdate(list);
	}

	useEffect(()=>{	
		// if( Object.keys(globals.notifications).length < 1 ) return;
		refreshList();
	},[globals.notifications]);

	/* useEffect(()=>{	
		if( Object.keys(globals.notifications).length < 1 ) return;
		listUpdate(globals.notifications);
	},[globals.notifications]); */

	const deleteAllHandle = async (e) =>{

		erasingUpdate(true);

		let index = 1;
		for(let key in list){

			erasingIndexUpdate(index);

			await notificationsDelete(window.username,list[key]._id).then((response)=>{
				let status = response.data.status;
				// let content = response.data.content;
						
			});

			index++;

			await wait(500);
		}

		erasingUpdate(false);
		globals.getNotifications(true);
		// refreshList();

		return true;
	}

	const deleteHandle = (key,index) =>{

		let id = list[key]._id;
		let title = list[key].name;
		let flag = list[key].fields.flag;
		let account = list[key].fields.account;
		
		notificationsDelete(window.username,id).then((response)=>{
			
			let status = response.data.status;
			let content = response.data.content;

			globals.getNotifications(true);

			// refreshList();
					
		}).catch((response)=>{
			
		});

	}

	

	return (
		<>
			{/*<div className="page notifications h100 ">*/}
			<div className=" notifications h100 ">

				<div className="wrapper">
					<div className="header ">
						
						<div className="line mobile-nobreak tablet-nobreak padding">
							<div className="column  middle left">
								
							</div>	
							<div className="column col5 middle right padding-left-10">


								<QuestionInline>
									<div question="">
										<button type="button" className={"button -secondary "}  >	
											{(erasing === true)? (												
												<>
													<small>{erasingIndex}/{Object.keys(list).length}</small>&nbsp;&nbsp;<label className="loading"></label>Limpar
												</>
											):(												
												<>
												<FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Limpar
												</>
											)}					
										</button>
									</div>
									<div alternatives="">
										<div response="yes">
											<button type="button" className={"button -error  "} style={{"whiteSpace":"nowrap"}} onClick={(e)=>{ deleteAllHandle() }} >						
												<FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;Limpar
											</button>											
										</div>			
									</div>
								</QuestionInline>
								
								
							</div>	
						</div>	

					</div>
					<div className="main sticky vh50 ">				
					

						{/*{ ( typeOf(list) === 'array' ) && (
							<div className="loading center padding link "></div>
						)}
*/}
						

						{ ( typeOf(list) === 'object' && Object.keys(list).length < 1 ) && (							
							<div className="center" >
								<p>Sem registro</p>
							</div>
						)}


						<div className="listCards">

							{/*{JSON.stringify(globals.notifications)}*/}

							<ul>
								{Object.keys(list).map((key,index) => {
							        return (
							        	<li key={key} onClick={()=>{ /*optionShowHandle(key);*/ }}>
							        		<LayoutCard 
					        					key={key} 
					        					enabledValue={list[key].enabled}
					        					
					        					splitMain={
								        			<>
								        				<div className="line mobile-nobreak tablet-nobreak ">
																
															<div className="column col0 middle center padding-h-10">	
																{ (list[key].fields.flag === 'error') && (
																	<span className="link  -error">
																		<FontAwesomeIcon icon={faTimes} />																	 
																	</span>
																)}

																{ (list[key].fields.flag === 'success') && (
																	<span className="link  -success">
																		<FontAwesomeIcon icon={faCheck} />																	 
																	</span>
																)}														
															</div>	
															<div className="column  middle left">															
										        				<small className="title" dangerouslySetInnerHTML={{__html:  list[key].fields.msg  }}></small>
										        				<small className="description"> { stampToFormat(new Date(parseInt(list[key].fields.createtime))) }  </small>
															</div>	

														</div>
								        			</>
								        		}

								        		splitInfo={
								        			<>
								        				
														<li>
															<QuestionInline>
																<div question="">
																	<button type="button" className={"link -secondary  "}  >						
																		<FontAwesomeIcon icon={faTrash} />
																	</button>
																</div>
																<div alternatives="">
																	<div response="yes">
																		<button type="button" className={"link -error  "} onClick={(e)=>{ deleteHandle(key,index) }} >						
																			<FontAwesomeIcon icon={faCheck} />
																		</button>
																		
																	</div>			
																</div>
															</QuestionInline>

															
														</li>	
														<li>	

																<small className="secondary"> { index+1 } </small>
															
														</li>	
								        			</>
								        		}


					        				/>
							     		</li>
							     	)
							     })
							    } 
								
								

								 	
							</ul>
						</div>

					</div>
					<div className="footer">

						<div className="line mobile-nobreak tablet-nobreak padding">
							<div className="column  middle  center">
								<label>{Object.keys(globals.notifications).length} Notifications</label>
							</div>
						</div>


					</div>
				</div>
			
			</div>
		</>
	);
};


export default Component;
