import React, {useState,useEffect,useContext} from "react";
import {Outlet} from "react-router-dom"

import {GlobalContext} from "../../contexts/global";

import Header from "../../layout/header/index.jsx";
import Menu from "../../layout/menu/index.jsx";

// import logo from "../../assets/img/logo-white-70.png";
// import logo2 from "../../assets/img/logo-white.png";
import pack from "../../../package.json";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronUp,faChevronDown,faGear,faBell} from '@fortawesome/free-solid-svg-icons'


import logo from "../../assets/img/logo.png";

import Options from "../../components/options/index.jsx";
import Settings from "./settings/index.jsx";
import Notifications from "./notifications/index.jsx";

import Selectexchanges from "../../layout/selectexchanges";



import "./style.css";

const Dashboard = () => {

	const {settings,globals} = useContext(GlobalContext);
	
	// const [serverStatus,serverStatusUpdate] = useState(null);


	const [linkNotifications,linkNotificationsUpdate] = useState({});
	const [notificationsCurrent,notificationsCurrentUpdate] = useState(null);
	const [optionNotificationsClose,optionNotificationsCloseUpdate] = useState(false);
	const optionNotificationsCloseHandle = () =>{			
		optionNotificationsCloseUpdate( (new Date()).getTime() );
	}

	const [optionNotificationsShow,optionNotificationsShowUpdate] = useState(false);
	const optionNotificationsShowHandle = (key) =>{		
		notificationsCurrentUpdate( (new Date()).getTime() );
		optionNotificationsShowUpdate( (new Date()).getTime() );
	}
	const onNotificationsClose = () =>{}


	const [toggleServerOffline,toggleServerOfflineUpdate] = useState(true);

	const [link,linkUpdate] = useState({});
	const [settingsCurrent,settingsCurrentUpdate] = useState(null);
	const [optionSettingsClose,optionSettingsCloseUpdate] = useState(false);
	const optionSettingsCloseHandle = () =>{			
		optionSettingsCloseUpdate( (new Date()).getTime() );
	}

	const [optionSettingsShow,optionSettingsShowUpdate] = useState(false);
	const optionSettingsShowHandle = (key) =>{		
		settingsCurrentUpdate( (new Date()).getTime() );
		optionSettingsShowUpdate( (new Date()).getTime() );
	}
	const onSettingsClose = () =>{}



	const posAdminCallback = () =>{
		// refreshList();
		// optionSettingsCloseHandle();
	}







	return (
		<>
			<Options className="--option-theme" open={optionNotificationsShow} close={optionNotificationsClose} onClose={onNotificationsClose} areaclass="w100 " baseelement="body [id='root']" link={link} >						
				<Notifications optionNotificationsCloseHandle={optionNotificationsCloseHandle} notificationsCurrent={notificationsCurrent} notificationsCurrentUpdate={notificationsCurrentUpdate} posAdminCallback={posAdminCallback} linkNotificationsUpdate={linkNotificationsUpdate} />
			</Options>

			<Options className="--option-theme" open={optionSettingsShow} close={optionSettingsClose} onClose={onSettingsClose} areaclass="w100 " baseelement="body [id='root']" link={link} >						
				<Settings optionSettingsCloseHandle={optionSettingsCloseHandle} settingsCurrent={settingsCurrent} settingsCurrentUpdate={settingsCurrentUpdate} posAdminCallback={posAdminCallback} linkUpdate={linkUpdate} />
			</Options>

			<div className="wrapper --dashboard" >
				<div className="header">	
					{/*<Header/>*/}
				</div>
				<div className="main">

					
				
					{ (globals.checkInternet===false) && (
						<>
							<div className="noconnection">								
								<p>Sem conexão</p>
							</div>
						</>
					)}

				
					{ /* (window?.serverStatus==='offline') && (
						<>
							<div className="serveroffline">
								<label className="display:block">Servidor offline</label>

								{toggleServerOffline && (
									<>
										<p>Todas as operações estão suspensas temporariamente</p>
										<p>Recomendamos que acesse sua corretora e monitore suas operações abertas</p>
									</>
								)}

								<button className={"link -secondary "} onClick={()=>{ toggleServerOfflineUpdate(!toggleServerOffline) }}>									
									{toggleServerOffline?(
										<FontAwesomeIcon icon={faChevronUp} className="padding-h-20" />										
									):(
										<FontAwesomeIcon icon={faChevronDown} className="padding-h-20" />										
									)}
								
								</button>

							</div>
						</>
					) */}

					

					<Outlet/>
				</div>
				<div className="footer">	

					<div className="footerareapre">


						<div className="headerArea">

							{/*<div dangerouslySetInnerHTML={{__html: globals.page.title }}></div>*/}
												
						</div>
						
						<div className="line  mobile-nobreak">

							<div className="column col1 middle center">
								{/*<img src={logo} className={"logo "+window?.serverStatus} alt=""/>*/}
								<button className={"link -secondary "} onClick={optionNotificationsShowHandle}>									
									<FontAwesomeIcon icon={faBell} className="padding-h-20" />		
									{Object.keys(globals.notifications).length > 0 && (
										<label className="badge notificationsBadge">{Object.keys(globals.notifications).length}</label>					
									)}
								</button>
							</div>
							<div className="column  middle center ">
								<Menu/>
							</div>
							<div className="column col1 middle center ">
								{/*<button className={"link -secondary padding-h-10 padding-bottom-10"} onClick={optionNotificationsShowHandle}>									
									<FontAwesomeIcon icon={faBell} />		
									{Object.keys(globals.notifications).length > 0 && (
										<label className="badge notificationsBadge">{Object.keys(globals.notifications).length}</label>					
									)}
								</button>*/}
								<button className={"link -secondary padding-h-20 "} onClick={optionSettingsShowHandle}>									
									<FontAwesomeIcon icon={faGear} />							
								</button>
							</div>
						</div>

						

					</div>

					
				</div>
			</div>
		</>
	);
};


export default Dashboard;
