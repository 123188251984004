import React, {useState,  useEffect,useContext, useRef} from "react";

// import {botGet,botPut,botPost,botDelete,botClone} from "../../../../services/apiBots";
// import {exchangeList} from "../../../../services/apiExchange";

import {botGet,botPut,botPost,botDelete,botClone} from "../../../../../services/apiBots";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash,faWaveSquare,faStore,faSave,faPlay,faStop,faThumbsUp,faThumbsDown,faSearch,faToggleOn,faChevronUp,faChevronDown,faBars,faBuildingColumns,faTimes,faEdit,faVial,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

// import QuestionInline from "../../../../components/questioninline";
// import {slugfy} from "../../../../libs/slugfy.jsx";

import {stampToFormat,typeOf} from "../../../../../libs/misc";


/*import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Rules from './rules';
import BackTest from './backtest';
import ModelsBot from './modelsbot';*/

// import ConfigInitial from './configinitial';
// import StrategyInitial from './strategyinitial';
// import BacktestInitial from './backtestinitial';

import {GlobalContext} from "../../../../../contexts/global";
// import {indicators} from "../../../../helpers/trades/indicators";

// import LocalStrategy from "./LocalStrategy/index.jsx";

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

import {strategyList,strategyGet,strategyPut,strategyPost,strategyDelete,strategyClone} from "../../../../../services/apiStrategy";

import QuestionInline from "../../../../../components/questioninline";

import "./style.css";

const Component = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null);

	const [selected,selectedUpdate] = useState(false);
	const [list,listUpdate] = useState([]);
	
	const [botCurrent,botCurrentUpdate] = useState([]);
	
	const [currentStrategy,currentStrategyUpdate] = useState([]);

	const refresh = () =>{

		// console.log(props.storeMode);
		
		if(props?.storeMode?._id){

			botGet(window.username,props.storeMode._id).then((response)=>{

				let status = response.data.status;

				if(status === 'success'){

					let content = response.data.body[0];

					if( content.enabled === 'true' ) content.enabled = true;
					if( content.enabled === 'false' ) content.enabled = false;

					content.fields = JSON.parse(content.fields);

					try{
						if(content.fields.backtest_orders)
						content.fields.backtest_orders = JSON.parse(content.fields.backtest_orders);
					}catch(e){}


					// setListBots(JSON.parse(content.fields.rulesbots));

					botCurrentUpdate({...content});

					
				}
				
				
			});

		}


		strategyList(window.username).then((result)=>{

				let {status,body} = result.data;

				if(status === 'success'){

					for(let key in body){

						body[key].fields = JSON.parse(body[key].fields);
						body[key].fields.backtest = JSON.parse(body[key].fields.backtest);
						// body[key].fields.rules = JSON.parse(body[key].fields.rules);
					}

					// console.log('--',body);
					
					

					// backtest


					listUpdate(body);
					return;
				}
				
				listUpdate({});
				// console.log(status,body);

		});
	}
	

	useEffect(()=>{
		refresh();
	},[]);

	const deleteStrategy = (key) =>{

		let id = list[key]._id;;


		strategyDelete(window.username,id).then((result)=>{

			let {status,body} = result.data;

			if(status === 'success'){
				// listUpdate(body);
				refresh();
			}
			// console.log(status,content);

			selectedUpdate(false);
		});

	}

	const selectStrategy = (key) =>{

		for(let keyb in list){			
			list[keyb].active = false;
		}

		list[key].active = true;

		currentStrategyUpdate(list[key]);
		// console.log( list[key].active );
		listUpdate({...list});
		selectedUpdate(true);
	}

	const useStrategy = (e) =>{

		e.target.classList.add('loading');
		e.target.classList.add('disabled');
		

		let botID = props.storeMode._id;

		props.storeMode.fields.rulesbots = JSON.parse(props.storeMode.fields.rulesbots);
		if(props.storeMode.fields.rulesbots.length > 0){
			let input = window.confirm('Já existe regra aplicada para este robo, deseja substituir?');
			if(input == false) return
		}



		props.storeMode.fields.rulesbotsByStrategy = currentStrategy.fields.rules;
		
		props.fieldsUpdate({...props.storeMode});
		props.storeCloseHandle();




	}

	const useStrategyNewBot = (e) =>{
	
		props.storeCloseHandle();
		props.newBotHandle({
			"name":"Bot by Strategy - "+currentStrategy.name+"",
			"description":""+stampToFormat((new Date())),		
			"rulesbots":currentStrategy.fields.rules
		});
	}

	return (
		<div ref={inputRef} className="LocalStrategy h100" >
			<div className="wrapper">
				<div className="header">
					

				</div>
				<div className="main h100 sticky ">

				

					{ ( typeOf(list) === 'array' && list.length < 1 ) && (
						<div className="loading center padding link "></div>
					)}

					

					{ ( typeOf(list) === 'object' && Object.keys(list).length < 1 ) && (							
						<div className="center" >
							Sem registro
						</div>
					)}


					<ul>
						{Object.keys(list).map((key,index)=>{

							return(
								<li key={key} className={list[key].active?'active':''}>
									<div className="line mobile-nobreak tablet-nobreak">
										<div className="column  left middle">
											<a onClick={ ()=>{ selectStrategy(key) } }>
												<div className="line mobile-nobreak tablet-nobreak">
													<div className="column col0 left middle padding-right-10">
														<FontAwesomeIcon icon={faWaveSquare} />
													</div>
													<div className="column left middle">
														<label>{list[key].name}</label>
														{list[key].description &&(
															<p className="description">{list[key].description}</p>
														)}
														
														<label className="margin-top-10">	
															<span><small className="-success-text padding-right-5">{list[key].fields.backtest.porcentgain.toFixed(2)}%</small></span>
															<span><small className="-error-text ">{list[key].fields.backtest.porcentloss.toFixed(2)}%</small></span>
														</label>	
														{/*<small>{stampToFormat( (new Date(list[key].createdat*1000)) )}</small>*/}

													</div>
												</div>
											</a>
										</div>
										
										<div className="column col0 left middle">

											<QuestionInline>
												<div question="">
													<button type="button" className={"link   "}  >						
														<FontAwesomeIcon icon={faTrash} />
													</button>
												</div>
												<div alternatives="">
													<div response="yes">
														<button type="button" className={"link  "} onClick={(e)=>{ deleteStrategy(key) }} >						
															<FontAwesomeIcon icon={faCheck} />
														</button>
														
													</div>			
												</div>
											</QuestionInline>

											{/*<a onClick={ ()=>{ deleteStrategy(key) } }>
												<FontAwesomeIcon icon={faTrash} />
											</a>*/}
										</div>
									</div>
								</li>
							)
						})}
						
					</ul>

				</div>
				<div className="footer">
					<div className="line mobile-nobreak tablet-nobreak margin-top-10 ">
						<div className="column col0 left middle"></div>
						<div className="column  right ">	
							{props.storeMode === null ?(
								<button type="button" className={"button -secondary w100 btnuse "+(selected?'active':'disabled')} onClick={useStrategyNewBot} >						
									Usar em novo robo
								</button>
							):(
								<button type="button" className={"button -secondary w100 btnuse "+(selected?'active':'disabled')} onClick={useStrategy} >						
									Usar neste robo<br/>
									<small>{botCurrent.name}</small>
								</button>
							)}																
						</div>						
					</div>
				</div>
			</div>
		</div>
	);
};


export default Component;