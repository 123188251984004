import React, {useEffect,useState, createContext} from "react";

import {checkServer} from "../services/apiServer";

import {botList} from "../services/apiBots.jsx";
import {orderList} from "../services/apiOrders.jsx";
import {userGet} from "../services/apiUser";
import {coinsList} from "../services/apiCoins";

import {exchangeList} from "../services/apiExchange.jsx";
import {backtestList} from "../services/apiBacktest.js";

import {notificationsList,notificationsDelete} from "../services/apiNotifications.js";
// import {convertCurrency} from "../services/apiWallet";
// import TokensJson from "../tokens.json";
import {checkJson} from "../libs/misc.js";

// import {AuthContext} from "./auth";

import { SnackbarProvider, enqueueSnackbar } from 'notistack'


export const GlobalContext = createContext();
export const GlobalProvider = ({children}) => {

	const [checkInternet,checkInternetUpdate] = useState(true);

	const [settings,settingsUpdate] = useState({});

	const [botsActive, botsActiveUpdate] = useState(0);
	const [listOrders, listOrdersUpdate] = useState([]);
	const [listBots, listBotsUpdate] = useState([]);
	const [listExchanges, listExchangesUpdate] = useState([]);
	const [orders, ordersUpdate] = useState(0);
	const [positions, positionsUpdate] = useState(0);
	const [balance, balanceUpdate] = useState(0);
	const [performance, performanceUpdate] = useState(0);
	const [totalVictoriesValue, totalVictoriesValueUpdate] = useState(0);
	const [totalLossValue, totalLossValueUpdate] = useState(0);
	const [coins, coinsUpdate] = useState([]);
	const [exchangeCoins, exchangeCoinsUpdate] = useState([]);
	const [user, userUpdate] = useState([]);
	const [backtest, backtestUpdate] = useState([]);


	let handleCheckServer =  () =>  {

		checkServer(window.username).then( response => {


			let status = response.data.status;
			let content = response.data.content;


			let serverStatus = 'offline';
			if( status === 'success' ){
				serverStatus = 'online';	
			}

			window.serverStatus = serverStatus;
			

		}).catch((error)=>{
		});

	}


	const getAll =  (refresh,callback) =>{
		try{

			
			globals.getExchanges(async(status)=>{
				

				if(status === 'error'){
					checkInternetUpdate(false);

					if(callback)callback(status);

					setTimeout(()=>{
						getAll(refresh,callback);
					},3000);

					return;
				}

				checkInternetUpdate(true);
				
				// try {
					
					handleCheckServer();
					let resultBots = await getBots();
					let resultOrders = await getOrders();
					let resultUserHandle = await getUserHandle();
					let resultNotifications = await getNotifications();
					let resultCoins = await getCoinsHandle();
					let resultBAcktest = await getBacktestHandle();
				
					
					listExchangesUpdate(global.listExchanges);
					
				/* } catch (error) {
					
				} */

				if(callback)callback(status);

				if(refresh === true){				
					setTimeout(()=>{
						getAll(refresh,callback);
					},10000);
				}
			});

		}catch(e){}
	}

	const getBacktestHandle = async () =>{
		let response = await backtestList(window.username);

		if(typeof response?.data?.status === 'undefined') return false;
		
		let {status,body} = response.data;

		
		let newList = {};
		if(status === 'success'){
			
			for(let key in body){
				let name = body[key].name;
				newList[name] = body[key];	

				newList[name].fields = JSON.parse(body[key].fields);				
				newList[name].fields.backtest_orders = JSON.parse(body[key].fields.backtest_orders);				
				newList[name].fields.lastprice = JSON.parse(body[key].fields.lastprice);				
				
			}
			
			backtestUpdate(newList);			
		} 
		
		return true;	
	}

	const getCoinsHandle = async () =>{
		let response = await coinsList(window.username);

		if(typeof response?.data?.status === 'undefined') return false;
		
		let {status,body} = response.data;

		let newCoins = {};
		if(status === 'success'){
			
			for(let key in body){
				let name = body[key].name;
				let fields = JSON.parse(body[0].fields);				
				newCoins[name] = fields;	

			}

			exchangeCoinsUpdate(newCoins);			
		}
		
		return true;	
	}



	const getUserHandle = async () =>{
		let response = await userGet(window.username);

		if(typeof response?.data?.status === 'undefined') return false;
		
		let {status,body} = response.data;

		if(status === 'success'){
			body[0].fields = JSON.parse(body[0].fields);			
			if( body[0].fields.blockorders === 'true' ) body[0].fields.blockorders = true;
			if( body[0].fields.blockorders === 'false' ) body[0].fields.blockorders = false;
			if( body[0].fields.telegramuserchecked === 'true' ) body[0].fields.blockorders = true;
			if( body[0].fields.telegramuserchecked === 'false' ) body[0].fields.blockorders = false;			
			if(typeof body[0].fields.blockorders === 'undefined' ) body[0].fields.blockorders = false;
			if(typeof body[0].fields.telegramuser === 'undefined' ) body[0].fields.telegramuser = '';
			if(typeof body[0].fields.telegramuserchecked === 'undefined' ) body[0].fields.telegramuser = false;		
			userUpdate(body[0]);			
		}
		
		return true;	
	}


	// const [notificationsStarted, notificationsStartedUpdate] = useState(false);
	const [listNotifications, listNotificationsUpdate] = useState([]);

	const getNotifications = async () =>{

		// await notificationsList(window.username).then((response)=>{
		let response = await notificationsList(window.username);
		
		if(typeof response?.data?.status === 'undefined') return false;

		let status = response.data.status;
		let list = response.data.body;

	
		// sort array of objects by createtime
		let listnew = [];
		for(let key in list){
			listnew[listnew.length] = {
				key:key,
				value:list[key],
				createtime:list[key].createtime
			}
		}
		listnew.sort(function(a,b){
		  return (new Date(b.createtime*1000)).getTime() - (new Date(a.createtime*1000)).getTime();
		});
		let list2 = [];
		for(let key in listnew){
			list2[list2.length] = listnew[key].value;
			// list2[listnew[key].key] = listnew[key].value;
		}

		for(let key in list2){
			list2[key].fields = JSON.parse(list2[key].fields);
			// console.log( list2[key].fields );
		}

	
		

		listNotificationsUpdate((listbk)=>{

			// console.log( listbk.length , list2.length );

			if(  list2.length > 0 && (global.notificationsStarted === true && ( list2.length > listbk.length ))  ){
				
				// console.log(list2[0]);

				let msg = list2[0].fields.msg ?? '';
				let flag = list2[0].fields.flag ?? 'default';

				msg = msg.replace(/<br>/g," - ");

				enqueueSnackbar(msg,{"variant":flag })
			}
			
			global.notificationsStarted = true;
			// notificationsStartedUpdate(true);

			return list2;
		});

		
	
		return true;
	}





	

	const getExchanges =  (callback) =>{
				
		// let response = await exchangeList(window.username);


		exchangeList(window.username).then((response)=>{
			

			let status = response.data.status;
			if(status !== 'success'){
				global.listExchanges = {};
				if(callback)callback('error');
				return false;
			}


			let list = response.data.body;


			
			let listNew = {};
			for(let key in list){
				
				list[key].fields = JSON.parse(list[key].fields);



				if( typeof list[key].fields?.balancelist === 'undefined' 
					|| list[key].fields?.balancelist === 'null'){

					list[key].fields.balancelist = {};
				}else{
					if( checkJson(list[key].fields.balancelist) === false ){				
						list[key].fields.balancelist = {};
					}else{
						list[key].fields.balancelist = JSON.parse(list[key].fields.balancelist);		
					}
				}


				if( typeof list[key].fields?.markets === 'undefined' 
					|| list[key].fields?.markets === 'null'){

					list[key].fields.markets = {};
				}else{
					if( checkJson(list[key].fields.markets) === false ){				
						list[key].fields.markets = {};
					}else{
						list[key].fields.markets = JSON.parse(list[key].fields.markets);		
					}
				}

				// console.log('--',list[key].fields);
				

				// if(typeof list[key].fields.balancelist === 'string')
					// list[key].fields.balancelist = JSON.parse(list[key].fields.balancelist);

				listNew[key] = list[key];
				
			}
			// content.balancelist = JSON.parse(content.balancelist);


			// console.log(listNew);
			// listExchangesUpdate(list);
			global.listExchanges = listNew;

			listExchangesUpdate({...listNew});

			/*if(refresh === true){				
				setTimeout(()=>{
					getExchanges(refresh,callback);
				},10000);
			}*/
			
			// console.log('==',list);

			// setTimeout(()=>{		
			

				if(callback)callback('success');
			// },3000);


		}).catch((response)=>{
				if(callback)callback('error');
		});

			// return response;
	}



	const getBots = async () =>{
				

		// await botList(window.username).then((response)=>{
		let response = await botList(window.username);

		if(typeof response?.data?.status === 'undefined') return false;
			
		let status = response.data.status;
		let list = response.data.body;

		let preBotsActive = 0;
		// let balance = 0;

		let exchangeAdded = {};

		let listNew = {};
		let countBotByExchange = 0;
		let botsByExchange = {};

		for(let key in list){

			list[key].fields = JSON.parse(list[key].fields);


			// list[key].fields.lastprice = JSON.parse(list[key].fields.lastprice);

			// console.log(global.exchangeID , list[key].fields.exchange);

			if( global.exchangeID !== '[full]' && (global.exchangeID !== list[key].fields.exchange)) continue;

			if(list[key].enabled === 'true'){
				preBotsActive++;
			}
/*
			if(typeof list[key].fields?.lastprice === 'undefined'){
				list[key].fields?.lastprice = {
					"date":"07/21/2023, 20:25:00","time":1689981900,"price_open":1891.13,"price_high":1891.69,"price_low":1890.96,"price_close":1891.4,"price_volume":1244.717,"condition":{"rule":["price_close:1891.4 > value:1892.40"]},"value":"1892.40","progress":[{"sell":21.87500000001221}]
				}				
			}
			// list[key].fields?.lastprice?.progress

			console.log('===',list[key].fields?.lastprice);*/
			


			if( typeof list[key].fields?.lastprice === 'undefined' 
				|| list[key].fields?.lastprice === 'null'){

				list[key].fields.lastprice = {
					progress:[]
				};
			}else{
				if( checkJson(list[key].fields.lastprice) === false ){				
					list[key].fields.lastprice = {
						progress:[]
					};
				}else{
					list[key].fields.lastprice = JSON.parse(list[key].fields.lastprice);		
				}
			}

			// console.log('=',list[key].fields.lastprice);
			// botsByExchange = {};

			if( typeof botsByExchange[list[key].fields.exchange] === 'undefined' )
				botsByExchange[list[key].fields.exchange] = [];

			let countBots = (botsByExchange[list[key].fields.exchange]).length;
			botsByExchange[list[key].fields.exchange][countBots] = list[key];

			listNew[key] = list[key];

			if(list[key].fields.balance !== undefined){

				
				if( exchangeAdded[list[key].fields.exchange] === undefined ){
					exchangeAdded[list[key].fields.exchange] = list[key].fields.exchange;
					// balance += parseFloat(list[key].balance);

					/*console.log(key);

					if(balance === 0){
						console.log(list);

						balance = parseFloat(list[key].balance_last);
					}*/

				}
			}

			if( list[key].fields.lastupdate !== undefined ){


				if( parseInt(list[key].fields.lastupdate) > (parseInt((new Date).getTime()/1000)-30) ) {
				// console.log(parseInt(list[key].fields.lastupdate) > (parseInt((new Date).getTime()/1000)-30));
					// if(list[key].fields.ordercanceled !== '1'){							
						// orders++;
						// console.log(key,orders);
					// }
					// botsactive++;
					
				}
			}
			// }

			

			for(let key2 in global.listExchanges){
				if(listNew[key].fields.exchange === global.listExchanges[key2]._id){
					global.listExchanges[key2].bots_active = preBotsActive;			
					global.listExchanges[key2].bots_list = botsByExchange[listNew[key].fields.exchange];	
				}
			}
		}

				
		// console.log(listNew);

		listBotsUpdate(listNew);

		// balanceUpdate(balance);
		botsActiveUpdate(preBotsActive);

		


		return true;		
	}


	const getOrders = async () =>{

		// try {
			
		
			// await orderList(window.username).then(async(response)=>{
			let response = await orderList(window.username);

			if(typeof response?.data?.status === 'undefined') return false;

			let status = response.data.status;
			let list = {};
			let performanceTime = 0;
			let totalBalancePre = 0;
			let botsactive = 0;
			let preBalance = 0;
			let preOrders = 0;
			let prePositions = 0;

			let totalVictoriesPre = 0;
			let totalLossPre = 0;

			let totalVictoriesValuePre = 0;
			let totalLossValuePre = 0;

			let coins = {};
			let coinsCount = [];
			let exchangeAdded = {};

			let totalBalancePre2 = 0;
			let listBalances = {};
			let listOrdersPre = {};
			let listPositions = {};
			let listData = {};
			
			if(status === 'success'){

				
				list = response.data.body;

				
				// console.log(list);
				

				for(let key in list){
						
					list[key].fields = JSON.parse(list[key].fields);

					listOrdersPre[key] = list[key];
					// console.log( list[key].fields );

					if( global.exchangeID !== '[full]' && (global.exchangeID !== list[key].fields.exchange)) continue;



					// if( parseInt(list[key].updatetime) > (parseInt((new Date).getTime()/1000)-30) ) {	
					if( list[key].fields.status === 'order' ){
						preOrders++;				
					}
			
					if( list[key].fields.status === 'positioned' ){
						prePositions++;				
					}


					if(preBalance === 0){
						preBalance = list[key].fields.balance_last;
					}

					let currentTotal = 0;
					if(list[key].fields.balance_last){
						currentTotal = (parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start));

					}else{
						currentTotal = ((list[key].fields.coin_last_price - list[key].fields.order_main_price) * list[key].fields.order_main_amount);
					}

					totalBalancePre += currentTotal;

					if( currentTotal >= 0){
						
						totalVictoriesValuePre += currentTotal;
						totalVictoriesPre++;
					}else{
						totalLossValuePre -= currentTotal;
						totalLossPre++;
					}

					performanceTime += (list[key].fields.updatetime - list[key].fields.createtime);

					let coinFrom = list[key].fields.order_main_symbol.split('/')[0];
					let coinTo = list[key].fields.order_main_symbol.split('/')[1];

					if(coinsCount[coinFrom] === undefined)
						coinsCount[coinFrom] = 0;

					coinsCount[coinFrom]++;

					coins[coinFrom+coinTo] = {
						"namefrom":coinFrom,
						"nameto":coinTo,
						"count":coinsCount[coinFrom],
					};

					
					// listData = {};

					for(let key2 in global.listExchanges){

						// console.log( list[key].fields.exchange , global.listExchanges[key2]._id );

						if(list[key].fields.exchange === global.listExchanges[key2]._id){


							let currentTotal2 = 0;
							if(list[key].fields.balance_last){
								currentTotal2 = (parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start));

							}else{
								currentTotal2 = ((list[key].fields.coin_last_price - list[key].fields.order_main_price) * list[key].fields.order_main_amount);
							}

							
							// console.log( currentTotal2 );

							// console.log(list[key].fields);
							

							// totalBalancePre2 += currentTotal2;

							if(typeof listBalances[list[key].fields.exchange] === 'undefined' )
								listBalances[list[key].fields.exchange] = 0;

							listBalances[list[key].fields.exchange] += currentTotal2;
							

							if(typeof listData[list[key].fields.exchange] === 'undefined' )
								listData[list[key].fields.exchange] = [];

							let countOrders = (listData[list[key].fields.exchange]).length;
							listData[list[key].fields.exchange][countOrders] = list[key];
							
							
							// console.log(list[key]);
							// console.log(list[key].fields.exchange,listBalances[list[key].fields.exchange]);

							if(typeof listOrdersPre[list[key].fields.exchange] === 'undefined' ) 
							listOrdersPre[list[key].fields.exchange] = 0;
							
							if( list[key].fields.status === 'order' ){					
								listOrdersPre[list[key].fields.exchange]++;				
							}

							if(typeof listPositions[list[key].fields.exchange] === 'undefined' ) 
								listPositions[list[key].fields.exchange] = 0;

							if( list[key].fields.status === 'positioned' ){					
								listPositions[list[key].fields.exchange]++;				
							}

							

							global.listExchanges[key2].orders_open = listOrdersPre[list[key].fields.exchange];
							// global.listExchanges[key2].orders_data = list;
							
							global.listExchanges[key2].orders_data = listData[list[key].fields.exchange];

							global.listExchanges[key2].postions_open = listPositions[list[key].fields.exchange];
							global.listExchanges[key2].performance = listBalances[list[key].fields.exchange];
						}
					}

					

				}
			}
			// console.log( global.listExchanges );

			listOrdersUpdate(list);

			balanceUpdate(preBalance);
			positionsUpdate(prePositions);
			ordersUpdate(preOrders);
			performanceUpdate(totalBalancePre);
			totalVictoriesValueUpdate(totalVictoriesValuePre);
			totalLossValueUpdate(totalLossValuePre);
			coinsUpdate(coins);
			

			if(document.querySelector('.dashboardorders')){					
				document.querySelector('.dashboardorders').setAttribute('number',preOrders+prePositions);
				document.querySelector('.dashboardorders').innerHTML = preOrders+prePositions;
			}

			return true;
		/* } catch (error) {
			return false;
				
		} */

	}






	// console.log(1);
				

	let globals = {
		getBacktestHandle:getBacktestHandle,
		backtest:backtest,
		getCoinsHandle:getCoinsHandle,
		exchangeCoins:exchangeCoins,
		checkInternet:checkInternet,
		getAll:getAll,
		getUserHandle:getUserHandle,
		user:user,
		getBots:getBots,
		getExchanges:getExchanges,
		getNotifications:getNotifications,
		getOrders:getOrders,
		botsActive:botsActive,
		notifications:listNotifications,
		exchanges:listExchanges,
		listBots:listBots,
		listOrders:listOrders,
		listExchanges:listExchanges,
		orders:orders,
		positions:positions,
		balance:Number(balance),
		performance:performance,
		order:{
			totalVictoriesValue:totalVictoriesValue,
			totalLossValue:totalLossValue,
			coins:coins,
		}
	}

	/*useEffect(()=>{		
		getBots();
	},[window.username]);*/

	useEffect(()=>{		
		
		if(window.appinterface)
		window.appinterface.loadOk();

	},[]);

	return (
		<GlobalContext.Provider value={{ settings,settingsUpdate,globals }}>						
			{children}			
		</GlobalContext.Provider>
	)
}