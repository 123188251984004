import React, {useEffect,useRef,useState} from "react";

import "./style.css";

const Component = (props) =>{

	// value={props.default}

	const [fieldValue,fieldValueUpdate] = useState( props.rule.config[props.type] ?? props.default);
	// const [fieldValue,fieldValueUpdate] = useState(props.default);

	/*useEffect(()=>{
		
		console.log(props.rule);	

	},[props.rule]);*/

	useEffect(()=>{

		// console.log( props.rule.config[props.type] );
		
		configHandle();	

	},[fieldValue]);

	const configHandle = () => {

		// console.log(props.rule);
		props.rule.config[props.type] = fieldValue;

		

		props.ruleUpdate({...props.rule});

	}

	return (
		<div >
			{ (props.type === 'source') && (
				<div className="line mobile-nobreak margin-bottom-10">
					<div className="column right col5 padding-right-10">
						<label>Source</label>
					</div>
					<div className="column left ">
						<select className="select w100" name="source" onChange={(e) => { fieldValueUpdate(e.target.value);   }}  value={fieldValue} >
							<option value="open">open</option>
							<option value="close">close</option>
							<option value="low">low</option>
							<option value="high">high</option>
						</select>
					</div>
				</div>
			)}
			{ (props.type === 'length') && (
				<div className="line mobile-nobreak margin-bottom-10">
					<div className="column right col5 padding-right-10">
						<label>Length</label>
					</div>
					<div className="column left ">				
						<input className="w100" type="number" onChange={(e) => { fieldValueUpdate(e.target.value);   }}  value={fieldValue}  />			    
					</div>
				</div>
			)}

			{ (props.type !== 'source' && props.type !== 'length') && (
				<div className="line mobile-nobreak margin-bottom-10">
					<div className="column right col5 padding-right-10">
						<label>{props.type}</label>
					</div>
					<div className="column left ">				
						<input className="w100" type="number" onChange={(e) => { fieldValueUpdate(e.target.value);   }}  value={fieldValue}  />			    
					</div>
				</div>
			)}
		</div>
	)
}

export default Component;
