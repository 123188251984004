
import {useLocation} from "react-router-dom";


const NotFound = () => {

	const location = useLocation();

	return (
		<div className="line">
			<div className="column middle">
				
			</div>
		</div>

	);
};


export default NotFound;