import React,{useEffect,useState,useRef,useContext} from "react";
// import {useLocation} from "react-router-dom";

import {GlobalContext} from "../../../../contexts/global";

import {orderDelete,cancelOrder} from "../../../../services/apiOrders.jsx";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan,faStopCircle,faHandPaper,faCheck,faTrash,faPlusSquare,faMinusSquare,faMoneyBillWave,faDonate,faHandHoldingUsd,faStopwatch,faCoins,faTimes,faSyncAlt,faVial,faBox,faDollarSign,faSignal,faClock,faCalendar,faUpLong,faDownLong} from '@fortawesome/free-solid-svg-icons'

import QuestionInline from "../../../../components/questioninline";
import ProgressBar from "../../../../components/ProgressBar";

import Switch from '@mui/material/Switch';

import {Convert2Digits,stampToFormat,secToDate} from "../../../../libs/misc.js";

import {order} from "../../../../libs/order.js";

import "./style.css";

const Rules = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	// const location = useLocation();
	const inputRef = useRef(null);

	const [currentItem,currentItemUpdate] = useState(null);
	const [list, listUpdate] = useState({});

	const [exchange,exchangeUpdate] = useState({});

	useEffect(()=>{

		let listPre = props.list[props.currentItem[0]];



		let exchangeIterate = null;

		for(let keypre in globals.exchanges){

			// console.log( globals.exchanges[keypre]._id , listPre[props.currentItem[1]].fields.exchange);

			if( globals.exchanges[keypre]._id === listPre[props.currentItem[1]].fields.exchange){
				exchangeIterate = globals.exchanges[keypre];
			}
		}


		if( exchangeIterate === null ){

			exchangeIterate = {
				"id":listPre[props.currentItem[1]]._id,
				title:"unknown",
				orders_data: listPre[props.currentItem[1]].orders_data,
			};
			
			{/*return (<React.Fragment key={index}></React.Fragment>);*/}
		
		}
		
		// console.log(exchangeIterate);

		exchangeUpdate(exchangeIterate);

		// console.log(listPre);

		listUpdate(listPre);
		currentItemUpdate(props.currentItem[1]);



		if(Object.keys(props.list[props.currentItem[0]]).length === 0) return;

	},[props.list]);


	/*useEffect(()=>{

		console.log( location.pathname );
	},[]);
*/


	/*useEffect(()=>{
		if(fieldsLoaded === true)
		inputRef.current.querySelector('.changebtn').classList.add('mark');

	},[rule]);
*/



	if( Object.keys(list).length === 0 ) 
		return;
	

	// if( Object.keys(list).length === 0 ) return;

	let handleDelete = (e,item) =>{

		// let input = window.confirm("Deseja apagar?");
		// if(input == false) return;

		e.target.classList.add('loading');
		// console.log(item);

		orderDelete(window.username,item._id).then((response)=>{
			props.refreshList();
			e.target.classList.remove('loading');
			props.closeOrderHandle();

			globals.getAll(true);
			
		});
	}

	let handleCancelOrder = async (event,order) =>{
		
		/*let input = window.confirm('Cancelar ordem ?');

		if(input == false) return;*/

		document.querySelector('.btnstoporder').classList.add('loading');
		document.querySelector('.btnstoporder').classList.add('disabled');
		document.querySelector('.btnstoporder svg').classList.add('hide');

		// event.target.classList.add('loading');
		
		// console.log(order);
		
		let orderid = order.fields.orderid;

		let botname = order.name;
		let symbol = order.fields.order_main_symbol;
		
		let order_main_type = order.fields.order_main_type;
		let order_main_amount = order.fields.order_main_amount;
		let order_main_side = order.fields.order_main_side;
		
		let order_sl_id = order.fields.order_sl_id;
		let order_sl_side = order.fields.order_sl_side;
		let order_sl_symbol = order.fields.order_sl_symbol;
		
		let order_tp_id = order.fields.order_tp_id;
		let order_tp_side = order.fields.order_tp_side;
		let order_tp_symbol = order.fields.order_tp_symbol;


		let position = symbol +":"+ order_sl_side +":"+ order_main_type.toUpperCase() +":"+ order_main_amount;
		let orders = order_tp_id +":"+ order_tp_symbol +","+ order_sl_id +":"+ order_sl_symbol;
		

		// console.log(orderid);

		// cancelOrder(window.username,botname,order_sl_id,position,orders).then((response)=>{
		// await cancelOrder(window.username,orderid).then((response)=>{
		await cancelOrder(window.username,order._id).then((response)=>{
			document.querySelector('.btnstoporder').classList.remove('loading');
			document.querySelector('.btnstoporder').classList.remove('disabled');
			document.querySelector('.btnstoporder svg').classList.remove('hide');
			props.refreshList();
			props.closeOrderHandle();
		});
	}


	return (
		<div className={"main-option h100 "+(order(list,props.currentItem[1]).statusbase)+" "+(order(list,props.currentItem[1]).status)+" "+(list[props.currentItem[1]].fields.action?'action-'+list[props.currentItem[1]].fields.action:'') } ref={inputRef} >			
			<div className="wrapper --option-theme">
				<div className="header dialog-header">

					{/*{JSON.stringify(list)}*/}
										
					<div className="line mobile-nobreak">
						<div className="column">

							<h2 className="dialog-title">Detalhe da ordem #&nbsp;{ Convert2Digits(Object.keys(list).length-parseInt(currentItem))}</h2>
							
						</div>
						<div className="column col0">
							<button type="button" className={"link padding"} onClick={props.closeOrderHandle} >						
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>

				</div>
				<div className="main sticky overflow-y-scroll">
					
					<div className="line  mobile-nobreak tablet-nobreak">

						<div className="column col5 left padding-left  date">
							
							<span className="order_status">

							

								{( order(list,props.currentItem[1]).status === 'positioned' && list[props.currentItem[1]].fields.action === 'cancel')&&(
									<>
										<FontAwesomeIcon icon={faHandPaper} />&nbsp;Cancelando...
									</>
								)}

								{( order(list,props.currentItem[1]).status === 'order' && list[props.currentItem[1]].fields.action === 'cancel')&&(
									<>
										<FontAwesomeIcon icon={faHandPaper} />&nbsp;Cancelando...
									</>
								)}


								{( order(list,props.currentItem[1]).status === 'positioned' && list[props.currentItem[1]].fields.action !== 'cancel')&&(
									<>Em execução</>
								)}

								{( order(list,props.currentItem[1]).status === 'order' && list[props.currentItem[1]].fields.action !== 'cancel')&&(
									<>Aguardando...</>
								)}

								{( order(list,props.currentItem[1]).status === 'canceled')&&(
									<>
										<FontAwesomeIcon icon={faHandPaper} />&nbsp;Cancelado
									</>
								)}


								{( order(list,props.currentItem[1]).status === 'gain' && list[props.currentItem[1]].fields.action !== 'canceled')&&(
									<>Ganhou</>
								)}

								{( order(list,props.currentItem[1]).status === 'loss' && list[props.currentItem[1]].fields.action !== 'canceled')&&(
									<>Perdeu</>
								)}


								{( order(list,props.currentItem[1]).status === 'gain' && list[props.currentItem[1]].fields.action === 'canceled')&&(
									<><FontAwesomeIcon icon={faBan} />&nbsp;Ganhou</>
								)}

								{( order(list,props.currentItem[1]).status === 'loss' && list[props.currentItem[1]].fields.action === 'canceled')&&(
									<><FontAwesomeIcon icon={faBan} />&nbsp;Perdeu</>
								)}


								{(order(list,props.currentItem[1]).status === 'equal' && list[props.currentItem[1]].fields.action === 'cancel' ) && (
									<>
										<FontAwesomeIcon icon={faHandPaper} />&nbsp;Cancelando...
									</>
								)}

								{(order(list,props.currentItem[1]).status === 'equal' && list[props.currentItem[1]].fields.action === 'canceled' ) && (
									<>
										<FontAwesomeIcon icon={faBan} />&nbsp;Cancelado
									</>
								)}


								{(order(list,props.currentItem[1]).status === 'equal' && order(list,props.currentItem[1]).statusbase === 'finish'  ) && (
									<>
										Sem alteração
									</>
								)}





								
							</span>
						</div>

						<div className="column  right date">
							<button type="button" className={"link -secondary padding-h-10 padding-top-10"}  >						
								<small>{ stampToFormat(new Date(parseInt(list[currentItem].fields.updatetime))).toLocaleString() } &nbsp;<FontAwesomeIcon icon={faCalendar} /></small>
							</button>

							<br/>

						</div>
						
					</div>

					<div className="line  mobile-nobreak tablet-nobreak margin-top-20 margin-bottom-10">
						<div className="column  center ">
							<button type="button" className={"link -invert padding-h-10"}  >						
								<small>{ list[currentItem].name }</small>
							</button>
						</div>
					</div>


					<div className="line  mobile-nobreak tablet-nobreak padding-h-20 ">
						<div className="column  ">
							<ProgressBar percentage={order(list,currentItem,'test').target_percent} width="90%" height="4px"/>
						</div>
					</div>
					

					


    				<div className="line  mobile-nobreak tablet-nobreak  margin-bottom-20">
						<div className="column  center ">
							<button type="button" className={"link -secondary padding-h-10"}  >						
								{<small>{ exchange.title }</small>}
							</button>
						</div>
					</div>


    				{ (list[currentItem].fields.status === 'loss' || list[currentItem].fields.status === 'gain' || list[currentItem].fields.status === 'canceled') && (
    					<>
	    				<div className={"line  mobile-nobreak tablet-nobreak margin-top-10 margin-bottom-10 statusfinish "+list[currentItem].fields.orderfinish}>
							<div className="column  center padding-h-10">
								{/*<span className={"button -secondary w100 padding "+' '+ (list[props.currentItem[1]].status==='loss'?'-error':( list[currentItem].status==='canceled'?'':'-success')) } title="Saldo total">*/}
								<span className={"button -secondary w100 padding "} title="Saldo total">
									<div className="line  mobile-nobreak tablet-nobreak">
										{/*<div className="column col2 left middle padding-right">
											<FontAwesomeIcon icon={faClock} />
										</div>*/}
										<div className="column  center middle ">									
											<strong className="display:block">Stop</strong>
											<small>{ list[currentItem].fields.status==='loss'?'Loss':'Gain' }</small>
										</div>								
									</div>
								</span>
							</div>
						</div>

						<hr/>
    					</>
    				)}


    				

    				

					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col4 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									{/*<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faCoins} />
									</div>*/}
									<div className="column  right middle ">									
											<strong className="display:block">Ativo</strong>
											<small>{list[currentItem].fields.order_main_symbol}</small>
									</div>								
								</div>
							</span>


						</div>
						<div className="column  center padding">
							
							<span className={"button -secondary w100 padding "+ order(list,currentItem).target_status } title="Saldo total">

								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle ">
										<div >
			        						{ (order(list,currentItem).target_status === 'gain') ? (
				        						<>																
													<span className={"miniicon " }><FontAwesomeIcon icon={faUpLong} /></span>
				        						</>																
											):(
				        						<>																
													<span className={"miniicon " }><FontAwesomeIcon icon={faDownLong} /></span> 
				        						</>																
											)}											
			    						</div>
									</div>
									<div className="column col6 center right">

										<strong className="display:block">Resultado</strong>									
										<div >


											<small className={"miniicon " }>
												$ {order(list,props.currentItem[1]).roi_pnl}  <small className="roi_porcent">{order(list,props.currentItem[1]).roi_porcent}%</small>
																								
												
											</small>

			        						{ list[currentItem].balance_last ? (
				        						<>																
													<small className={"miniicon " }>

													{/*$ {(parseFloat(list[currentItem].balance_last) - parseFloat(list[currentItem].balance_start)).toFixed(3) }*/}
													</small>
				        						</>																
											):(
				        						<>																
													{/*<small className={"miniicon " }>$ { ((list[currentItem].coin_last_price - list[currentItem].order_main_price) * list[currentItem].order_main_amount).toFixed(3)}</small>*/}
				        						</>																
											)}											
			    						</div>

									</div>										
								</div>								
							</span>

							
							
						</div>
					</div>

					<div className="line  mobile-nobreak tablet-nobreak">						
						<div className="column  center padding">							
							
							<span className="button -secondary w100 padding" title="Saldo total">

								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 middle left">
										
										<div className={"target_status "+list[currentItem].fields.order_main_side+" "+list[currentItem].target_status}>
			        						{ (list[currentItem].fields.order_main_side === 'buy') ? (
				        						<>																
													<span className={"miniicon " }><FontAwesomeIcon icon={faUpLong} /></span>
				        						</>																
											):(
				        						<>																
													<span className={"miniicon " }><FontAwesomeIcon icon={faDownLong} /></span> 
				        						</>																
											)}
											
			    						</div>
									</div>
									<div className="column col6 middle right">

										<strong className="display:block">Ordem</strong>									
										<div className={"target_status "+list[currentItem].fields.order_main_side+" "+list[currentItem].fields.target_status}>
												
											{ (list[currentItem].fields.order_main_side === 'buy') ? (
				        						<>																
													<small className={" "}>Comprado</small>
				        						</>																
											):(
				        						<>																
													<small className={" "}>Vendido</small>
				        						</>																
											)}
											
			    						</div>

									</div>										
								</div>
								

							</span>

						</div>
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faClock} />
									</div>
									<div className="column  right middle ">									
											<strong className="display:block">Duração</strong>
											<small>{ secToDate( (list[currentItem].fields.updatetime - list[currentItem].fields.createtime) / 1000 ) }</small>
									</div>								
								</div>
							</span>

						</div>
					</div>


					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faClock} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Hora entrada</strong>
										<small title="">{ stampToFormat(new Date(parseInt(list[currentItem].fields.createtime))).toLocaleString() }</small>
										
									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">
							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faClock} />
									</div>
									<div className="column  right middle ">		

										{ (order(list,props.currentItem[1]).status === 'positioned' || order(list,props.currentItem[1]).status === 'order' )?(											
											<strong className="display:block">Hora atual</strong>
										):(											
											<strong className="display:block">Hora saída</strong>
										)}

										<small title="">{ stampToFormat(new Date(parseInt(list[currentItem].fields.updatetime))).toLocaleString() }</small>
										
									</div>								
								</div>
							</span>
						</div>
					</div>


					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo inicial">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faDonate} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Saldo inicial</strong>
										<small >{ parseFloat(list[currentItem].fields.balance_start).toFixed(3)}</small>
									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">
							<span className="button -secondary w100 padding" title="Saldo final">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faMoneyBillWave} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Saldo final
										</strong>
										<small > { parseFloat(list[currentItem].fields.balance_last).toFixed(3)}</small>
									</div>								
								</div>
							</span>
						</div>
					</div>


					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faStopwatch} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Periodo</strong>
										<small>{list[currentItem].fields.order_interval}</small>											
									</div>								
								</div>
							</span>


						</div>
						<div className="column  center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									{/*<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faStopwatch} />
									</div>*/}
									<div className="column  right middle ">									
										<strong className="display:block">Alavancagem</strong>
										<small title="Leverage">&nbsp;<FontAwesomeIcon icon={faTimes} /> {list[currentItem].fields.order_leverage}</small>
									</div>								
								</div>
							</span>

							
							 
						</div>
					</div>

					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faHandHoldingUsd} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Aporte</strong>
										<small> { parseFloat(list[currentItem].fields.order_main_cost).toFixed(2) } </small>											
										

									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									{/*<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faStopwatch} />
									</div>*/}
									<div className="column  right middle ">									
										<strong className="display:block">Quantidade</strong>
										<small title="Amount">{list[currentItem].fields.order_main_amount}</small>
									</div>								
								</div>
							</span>

						</div>
					</div>

					

					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faDonate} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Entrada</strong>
										<small title="Amount">{ parseFloat(list[currentItem].fields.order_main_price).toFixed(3)}</small>
									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">
							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faMoneyBillWave} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">
										
										{ (order(list,props.currentItem[1]).status === 'active')?(
											<>Atual</>
										):(
											<>Saída</>
										)}
										</strong>
										<small title=""> { parseFloat(list[currentItem].fields.coin_last_price).toFixed(3)}</small>
									</div>								
								</div>
							</span>
						</div>
					</div>

					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faPlusSquare} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Ganho</strong>
										<small title=""> { parseFloat(list[currentItem].fields.order_tp_target).toFixed(3)}</small>
									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">
							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faMinusSquare} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Perca</strong>
										<small title=""> { parseFloat(list[currentItem].fields.order_sl_target).toFixed(3)}</small>
									</div>								
								</div>
							</span>
						</div>
					</div>
				


					
				


					<div className="line  mobile-nobreak tablet-nobreak">
						<div className="column col6 center padding">

							<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faPlusSquare} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Taxa %</strong>
										<small title=""> { parseFloat(list[currentItem].fields.order_main_fee)}</small>
									</div>								
								</div>
							</span>

						</div>
						<div className="column col6 center padding">
							{<span className="button -secondary w100 padding" title="Saldo total">
								<div className="line  mobile-nobreak tablet-nobreak">
									<div className="column col2 left middle padding-right">
										<FontAwesomeIcon icon={faMinusSquare} />
									</div>
									<div className="column  right middle ">									
										<strong className="display:block">Taxa USD</strong>
										<small title="">

										{ (order(list,props.currentItem[1]).status === 'positioned' || order(list,props.currentItem[1]).status === 'order' )?(											
											(parseFloat(list[currentItem].fields.order_main_price) * ( (parseFloat(list[currentItem].fields.order_main_fee) )  / 1000)).toFixed(4)
										):(											
											(parseFloat(list[currentItem].fields.order_main_price) * ( (parseFloat(list[currentItem].fields.order_main_fee) *2)  / 1000)).toFixed(4) 
										)}

										
										
										</small>
									</div>								
								</div>
							</span>}
						</div>
					</div>
				

				</div>
				<div className="footer">

					<hr/>

					<div className="line mobile-nobreak ">
						<div className="column left col6 ">
							
							{/*{ (list[currentItem].statusbase === 'order' || list[currentItem].statusbase === 'positioned')?(*/}
							{ ( list[props.currentItem[1]].fields.status === 'order' || list[props.currentItem[1]].fields.status === 'positioned')?(
								<>
									<QuestionInline>
										<div question="">
											<button type="button" title="Finalizar" className={"link btnstoporder -secondary padding"} onClick={(e)=>{  }} >						
												<FontAwesomeIcon icon={faStopCircle} />
											</button>
										</div>
										<div alternatives="">
											<div response="yes">
												<button type="button" className={"link -secondary padding"} onClick={(e)=>{ handleCancelOrder(e,list[currentItem]) }} >						
													<FontAwesomeIcon icon={faCheck} />
												</button>
												
											</div>			
										</div>
									</QuestionInline>
								</>
							):(
								<>
									<QuestionInline>
										<div question="">
											<button type="button" title="Apagar" className={"link -secondary padding"} onClick={(e)=>{  }} >						
												<FontAwesomeIcon icon={faTrash} />
											</button>
										</div>
										<div alternatives="">
											<div response="yes">
												<button type="button" className={"link -secondary padding"} onClick={(e)=>{ handleDelete(e,list[currentItem]) }} >						
													<FontAwesomeIcon icon={faCheck} />
												</button>
												
											</div>			
										</div>
									</QuestionInline>
								</>
							)}

			

						</div>
						<div className="column left col6 padding">
							{/*<button type="button" className={"changebtn button -secondary w100"} onClick={(e)=>{ testHandle() }} >						
								Testar
							</button>*/}
						</div>
					</div>
				
				</div>
			</div>
		</div>
	);
};


export default Rules;
