import { env } from '../.env.js';
import axios from "axios";


export const api = axios.create({
	baseURL: env.AchoordURL
});



export const orderList = async (username) => {	
	return await api.get("/orders/list/?username="+username);
	// return api.get("/storage/api/storage/orders/collections/name:wallrio/documents/");
}

export const orderGet = async (username,id) => {
	return await api.get("/orders/get/?username="+username);
	// return api.get("/storage/api/storage/orders/collections/name:wallrio/documents/"+id+"/");	
}

export const orderPut = async (id,data) => {
	// let collection = '0812887001668738913';

	// return await api.put("/orders/put/?username="+username,data);

	// return await api.put("/storage/api/storage/orders/collections/name:"+username+"/documents/"+id+"/",data);
}

// https://admin.achoord.com:1111/server/sendcommand?username=wallrio&project=trade&service=bottrade&command={get.command}

export const orderDelete = async (username,id) => {
	// let collection = '0812887001668738913';
	return await api.delete("/orders/delete/?username="+username+"&id="+id);
	// return api.delete("/storage/api/storage/orders/collections/name:wallrio/documents/"+id+"/");
}



export const cancelOrder = async (username,id) => {

	let data = {
		fields:"@"+JSON.stringify({
			action:"cancel"
		})
	};

	return await api.put("/orders/put/?username="+username+"&id="+id,data);

	// return api.put("/storage/api/storage/orders/collections/name:"+username+"/documents/"+id+"/",data);

  	// let command = "php%20cancel_order.php%20"+username+"%20"+botname+"%20"+position+"%20"+orders+"%20"+orderid;
  	// let command = "username="+username+"&botname="+botname+"&positions="+positions+"&orders="+orders+"&orderid="+orderid;

	// return api.get("http://localhost/crypto/macd/php/cancel_order.php?"+command);
	// return api.get("/orders/cancel/?command="+command);
	// return api.get("https://admin.achoord.com:1111/server/sendcommand?username=wallrio&project=trade&service=bottrade&command="+command);
}
