import React, {useState,  useEffect,useContext, useRef} from "react";

import {GlobalContext} from "../../../../contexts/global";

// import {botGet,botPut,botPost,botDelete,botClone} from "../../../../services/apiBots";
import {exchangeGet,exchangePut,exchangePost,exchangeDelete,exchangeClone} from "../../../../services/apiExchange";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

import QuestionInline from "../../../../components/questioninline";

import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
// import Rules from './rules';
// import BackTest from './backtest';

import {checkJson} from "../../../../libs/misc";
import {slugfy} from "../../../../libs/slugfy";

import "./style.css";

const Admin = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	

	const inputRef = useRef(null);

	let defaultFields = {
		_id:'',				
		enabled:'true',				
		name : "",
		description : "",
		fields : {
			exchange:"",
			futures:"",
			sandbox:"",
			secret:"",
			apikey:"",
			balancelist:{},
		},
		
	};

	const [fields,fieldsUpdate] = useState(defaultFields);
	const [fieldsCompare,fieldsCompareUpdate] = useState(defaultFields);

	const [listExchanges, listExchangesUpdate] = useState([]);
	const [listBots, setListBots] = useState([]);
	const [fieldsLoaded,fieldsLoadedUpdate] = useState(false);

	const onSubmitHandle = (e) =>{		
		e.preventDefault();

		save();

	}

	const save = () =>{

		if(inputRef.current.querySelector('form [type="submit"]'))
		inputRef.current.querySelector('form [type="submit"]').classList.add('loading');
		
		// if(inputRef.current.querySelector('form'))
		inputRef.current.classList.add('disabled');

		// fields.name = slugfy(fields.name);
		

		if( fields.enabled === true ) fields.enabled = 'true';
		if( fields.enabled === false ) fields.enabled = 'false';

		if( fields.fields.sandbox === true ) fields.fields.sandbox = 'true';
		if( fields.fields.sandbox === false ) fields.fields.sandbox = 'false';

		if( fields.fields.futures === true ) fields.fields.futures = 'true';
		if( fields.fields.futures === false ) fields.fields.futures = 'false';

	/*	
		console.log(fields.fields.secret , fieldsCompare.fields.secret);
		console.log(fields.fields.apikey , fieldsCompare.fields.apikey);
		console.log(fields.fields.exchange ,fieldsCompare.fields.exchange);
		console.log(String(fields.fields.sandbox) , String(fieldsCompare.fields.sandbox));
		console.log(String(fields.fields.futures) , String(fieldsCompare.fields.futures));

		*/
		

		if( fields.enabled === 'true' ){
			if(    fields.fields.secret !== fieldsCompare.fields.secret 
				|| fields.fields.apikey !== fieldsCompare.fields.apikey 
				|| fields.fields.exchange !== fieldsCompare.fields.exchange 
				|| String(fields.fields.sandbox) !== String(fieldsCompare.fields.sandbox)
				|| String(fields.fields.futures) !== String(fieldsCompare.fields.futures)
			){

				fields.fields.balancelist = '{}';
				fields.fields.updatetime = null;
				fields.fields.status = 'unknown';

			}
		}		

		
		
		// console.log(fields);
		// console.log(fieldsCompare);
		



		let fieldsData = JSON.stringify(fields);
		fieldsData = JSON.parse(fieldsData);



		// fieldsData.fields = JSON.stringify(fields.fields);

		if(typeof fields.fields.balancelist === 'object')
			fieldsData.fields.balancelist = JSON.stringify(fieldsData.fields.balancelist);

		// console.log(fieldsData.fields);


		fieldsData.fields = JSON.stringify(fieldsData.fields);

		// console.log(fields);

		if( props?.adminCurrent === 'new' ){


			exchangePost(window.username,  fieldsData).then((response)=>{

				let {status,content} = response.data;
				
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
				inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');

				// if(inputRef.current.querySelector('form'))
				inputRef.current.classList.remove('disabled');


				if(status === 'success'){

					// if(inputRef.current.querySelector('form [type="submit"]')){
						// setListBots([]);
						fieldsUpdate(defaultFields);

						fieldsLoadedUpdate(false);
						props.adminCurrentUpdate( null );
						props.posAdminCallback();

						inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
						setTimeout(()=>{
							try{
								inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
							}catch(e){}
						},2000);

					// }
			
				}else{

					inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
					setTimeout(()=>{
						try{							
							inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
						}catch(e){}
					},2000);
				}
			});


			

			return;
		}

		exchangePut(window.username, props?.adminCurrent?._id, fieldsData).then((response)=>{

			let {status,content} = response.data;
			
			// return;
			inputRef.current.classList.remove('disabled');


			if(status === 'success'){

				if(inputRef.current.querySelector('form [type="submit"]')){
					inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
					inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');


					setListBots([]);
					fieldsUpdate(defaultFields);

					fieldsLoadedUpdate(false);
					props.adminCurrentUpdate( null );
					props.posAdminCallback();

					inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
					setTimeout(()=>{
						
						try{														
							inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
						}catch(e){}
					},2000);

				}
		
			}else{

				inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
				setTimeout(()=>{
					try{																				
						inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
					}catch(e){}
					
				},2000);
			}
		});
		
	}


	const load = (e) =>{

		

		inputRef.current.querySelector('.sticky').scrollTo(0,0);

		if( props?.adminCurrent === 'new' ){

			setListBots([]);
			fieldsUpdate(defaultFields);
			if(inputRef.current.querySelector('form'))
			inputRef.current.querySelector('form').reset();

			let content = defaultFields;

			/*let content = {
				enabled:'true',				
				name : "",
				description : "",
				fields : {},
				
			};
*/
			if( content.enabled === 'true' ) content.enabled = true;
			if( content.enabled === 'false' ) content.enabled = false;

			if( content.fields.sandbox === 'true' ) content.fields.sandbox = true;
			if( content.fields.sandbox === 'false' ) content.fields.sandbox = false;

			if( content.fields.futures === 'true' ) content.fields.futures = true;
			if( content.fields.futures === 'false' ) content.fields.futures = false;

			// if(typeof content.fields.balancelist === 'string')
			// content.fields.balancelist = JSON.parse(content.fields.balancelist);

			if(typeof content.fields.futures === 'undefined')content.fields.futures = false;
			if(typeof content.fields.sandbox === 'undefined')content.fields.sandbox = false;

			
			content.fields.balancelist = {};

			// setListBots(JSON.parse(content.rulesbots));

			fieldsUpdate({...content});
			fieldsCompareUpdate(JSON.parse(JSON.stringify(content)));
			

			setTimeout(()=>{
				fieldsLoadedUpdate(true);
			},50);

			return;
		}

		exchangeGet(window.username,props?.adminCurrent?._id).then((response)=>{

			let {status,body} = response.data;
			if(status === 'success'){

				let content = body[0];

				content.fields = JSON.parse(content.fields);

				if( content.enabled === 'true' ) content.enabled = true;
				if( content.enabled === 'false' ) content.enabled = false;

				if( content.fields.sandbox === 'true' ) content.fields.sandbox = true;
				if( content.fields.sandbox === 'false' ) content.fields.sandbox = false;

				if( content.fields.futures === 'true' ) content.fields.futures = true;
				if( content.fields.futures === 'false' ) content.fields.futures = false;



				/*if(typeof content.fields.balancelist === 'string')
				content.fields.balancelist = JSON.parse(content.fields.balancelist);
*/

				if( typeof content.fields?.balancelist === 'undefined' 
					|| content.fields?.balancelist === 'null'){

					content.fields.balancelist = {};
				}else{
					if( checkJson(content.fields?.balancelist) === false ){				
						content.fields.balancelist = {};
					}else{
						content.fields.balancelist = JSON.parse(content.fields?.balancelist);		
					}
				}



				// if(typeof content.fields.balancelist === 'object')
				
				// console.log(content.fields.exchange);
				// setListBots(JSON.parse(content.rulesbots));

				fieldsUpdate({...content});
				fieldsCompareUpdate(JSON.parse(JSON.stringify(content)));

				setTimeout(()=>{
					fieldsLoadedUpdate(true);
				},50);
			}
		});
		
	}


	/*let getExchanges = () => {
		exchangeList(window.username).then((response)=>{
			let data = response.data.content;
			let newData = {};
			for(let key in data){
				if(data[key].enabled === 'false') continue;
				newData[key] = data[key];
			}
			listExchangesUpdate(newData);
		})
	}*/


	/*useEffect(()=>{

		if(fieldsLoaded === false) return;

		fields.rulesbots = JSON.stringify(listBots);

		fieldsUpdate({...fields});

	},[listBots]);*/


	useEffect(()=>{

		if(props?.adminCurrent === null) return;

		props.linkUpdate({
			actionOnClose:()=>{					
				fieldsLoadedUpdate(false);
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');
				props.adminCurrentUpdate( null );
			}
		});

		

		// getExchanges();
		load();
	},[props.adminCurrent]);

	useEffect(()=>{
		if(fieldsLoaded === false) return;
		if(props?.adminCurrent === 'new') return;
			// if(inputRef.current.querySelector('form [type="submit"]')){
				inputRef.current.querySelector('form [type="submit"]').classList.add('mark');
			// }

	},[fields]);


	  let [openBacktest, openBacktestUpdate] = useState(false);
	  const openBacktestHandle = key => {
		   // currentKeyUpdate(key);
		   openBacktestUpdate(true);
	  };
	  const handleBacktestClose = () => {
	  	openBacktestUpdate(false);
	  };

	
	  let [currentKey, currentKeyUpdate] = useState(null)
	  let [open, openUpdate] = useState(false)

	  


	  const openRuleHandle = key => {

	   currentKeyUpdate(key);
	   openUpdate(true);
	   // onCloseUpdate(value);
	  };

	  const deleteRuleHandle = key => {

	  	let listBots2 = JSON.stringify(listBots);
	  	listBots2 = JSON.parse(listBots2);

	  	delete listBots2[key];
	  	listBots2 = listBots2.filter(e => e);
	  	setListBots(listBots2);

	  };

	 

	 

	  


	  const handleClose = () => {
	  	openUpdate(false);
	  };


	  const deleteExchangeHandle = () => {

	  		
  			fieldsLoadedUpdate(false);
			inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');
  			props.optionCloseHandle();
  			

	  		exchangeDelete(window.username,  fields._id).then((response)=>{

				props.adminCurrentUpdate( null );
  				props.posAdminCallback();

  				globals.getExchanges();

			});

	  };


	  const cloneExchangeHandle = () => {

	  		
  			fieldsLoadedUpdate(false);
			inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');
  			props.optionCloseHandle();
  			

	  		exchangeClone(window.username,  fields._id).then((response)=>{

				props.adminCurrentUpdate( null );
  				props.posAdminCallback();

  				globals.getExchanges();

			});

	  };



	return (
		<form ref={inputRef} onSubmit={onSubmitHandle}>
			{/*<Dialog onClose={handleClose} open={open}  >

				<Rules handleClose={handleClose} listBots={listBots} setListBots={setListBots} currentKey={currentKey} ></Rules>
				
			</Dialog>

			<Dialog onClose={handleBacktestClose} open={openBacktest}  >
				<BackTest handleBacktestClose={handleBacktestClose} ></BackTest>				
			</Dialog>*/}
			
			<div className="--admin h100 ">
				


					<div className=" options_header">
						<div className="line mobile-nobreak tablet-nobreak padding">

							

							<div className="column col5 left middle">
								
								<button type="submit" className={"button -third w100"} onClick={(e)=>{  }} >						
									{ (props?.adminCurrent === 'new') ? (
										<>
											Criar
										</>
									):(
										<>
											Salvar
										</>
									)}
								</button>

								{/*<h1>Bot</h1>*/}
								{/*<label className="options_title" dangerouslySetInnerHTML={{__html: "" }} ></label>*/}

							</div>
							<div className="column  left middle">
							</div>
							

							
							<div className="column col0 right ">																	
								<Switch checked={(fields.enabled)?true:false} onChange={(e)=>{ fields.enabled=e.target.checked; fieldsUpdate({...fields}) }} name="jason" />
							</div>						
						</div>
					</div>

					<div  className="padding sticky vh70  "  >

						{ fields.status === 'success' && (
							<>
								<div className="-success-text padding margin-bottom-10 ">
									<div className="line mobile-nobreak tablet-nobreak ">									
										<div className="column col2 center middle">
											<FontAwesomeIcon icon={faCheck} />	
										</div>
										<div className="column  left middle">Conectado com a corretora</div>
									</div>
								</div>
							</>
						)}

						{ fields.status === 'error' && (
							<>
								<div className="-error-text padding margin-bottom-10 ">
									<div className="line mobile-nobreak tablet-nobreak ">									
										<div className="column col2 center middle">
											<FontAwesomeIcon icon={faTimes} />	
										</div>
										<div className="column  left middle">Não conectado com a corretora</div>
									</div>
								</div>
							</>
						)}

						

						{ fields.status === 'unknown' && (
							<>
								<div className="-warning-text padding margin-bottom-10 ">
									<div className="line mobile-nobreak tablet-nobreak ">									
										<div className="column col2 center middle loading">
											{/*<FontAwesomeIcon icon={faTimes} />	*/}
										</div>
										<div className="column  left middle">Aguardando conexão...</div>
									</div>
								</div>
							</>
						)}


						{ (props?.adminCurrent !== 'new') && (
							<div className="options_control margin-bottom-10">
								<div className="line mobile-nobreak tablet-nobreak ">									
										<div className="column col0 right middle">		

											<QuestionInline>
												<div question="">
													<button type="button" className={"button -link  "}  >						
														<FontAwesomeIcon icon={faClone} />
													</button>
												</div>
												<div alternatives="">
													<div response="yes">
														<button type="button" className={"button -link "} onClick={(e)=>{ cloneExchangeHandle() }} >						
															<FontAwesomeIcon icon={faCheck} />
														</button>
														
													</div>			
												</div>
											</QuestionInline>					
											
										</div>

						
										<div className="column  right middle">		

											<QuestionInline>
												<div question="">
													<button type="button" className={"button -link   "}  >						
														<FontAwesomeIcon icon={faTrash} />
													</button>
												</div>
												<div alternatives="">
													<div response="yes">
														<button type="button" className={"button -link "} onClick={(e)=>{ deleteExchangeHandle() }} >						
															<FontAwesomeIcon icon={faCheck} />
														</button>
														
													</div>			
												</div>
											</QuestionInline>					
											
										</div>							
								</div>
							</div>
						)}
					

						<div className="line mobile-nobreak tablet-nobreak "  >
							<div className="column top ">


								<input type="text" name="username"  autoComplete="username" style={{"display":"none"}} />

								<div className="input-float flatmodel ">
								      <input type="text" name="id" required readOnly disabled value={fields._id}  />
								      <label dangerouslySetInnerHTML={{__html: "ID"}} ></label>
								</div>

							<div className="input-float flatmodel ">
								      <input type="text" name="name" required value={fields.name} onChange={(e)=>{ fields.name=e.target.value; fieldsUpdate({...fields}) }} />
								      <label dangerouslySetInnerHTML={{__html: "Title"}} ></label>
								</div>	

								<div className="input-float flatmodel ">
								      <input type="text" name="description" value={fields.description} onChange={(e)=>{ fields.description=e.target.value; fieldsUpdate({...fields}) }} />
								      <label dangerouslySetInnerHTML={{__html: "Description"}} ></label>
								</div>			
								
								

								

									
								

							</div>
						</div>	

						<div className="line mobile-nobreak tablet-nobreak "  >
							<div className="column col2 top ">

								<label>Corretora</label>
								<select className="select" required value={fields.fields.exchange} onChange={(e)=>{ fields.fields.exchange=e.target.value; fieldsUpdate({...fields}) }} >				
									<option value="" disabled>Corretora</option>
									<option value="binance">Binance</option>
									<option value="coinbase">Coinbase</option>
									<option value="kraken">Kraken</option>
									<option value="robinhood">Robinhood</option>
									<option value="crypto.com">Crypto.com</option>
									<option value="ftx">FTX</option>
									<option value="gemini">Gemini</option>
									<option value="etoro">eToro</option>
									<option value="gmocoin">GMO Coin</option>
									<option value="huobiglobal">Huobi Global</option>
								</select>

							</div>
							<div className="column  ">
								
								<label className="checkbox margin-top-20 padding">
									<input 
										type="checkbox" 
										name="futures"
										checked={ fields.fields.futures===false?false:true } 
										onChange={(e)=>{ fields.fields.futures=e.target.checked; fieldsUpdate({...fields}) }}									
									/>
									<span>Futures</span>
								</label>

							</div>
							<div className="column  ">
								
								<label className="checkbox margin-top-20 padding">
									<input 
										type="checkbox" 
										name="sandbox"
										checked={ fields.fields.sandbox===false?false:true } 
										onChange={(e)=>{ fields.fields.sandbox=e.target.checked; fieldsUpdate({...fields}) }}									
									/>
									<span>Sandbox</span>
								</label>

							</div>
						</div>	

						<div className="line mobile-nobreak">
							<div className="column col6 left ">
								
								

								<div className="input-float flatmodel ">
								      <input type="text" name="secret" required value={fields.fields.secret} onChange={(e)=>{ fields.fields.secret=e.target.value; fieldsUpdate({...fields}) }} />
								      <label dangerouslySetInnerHTML={{__html: "Secret"}} ></label>
								</div>	

								<div className="input-float flatmodel ">
								      <input type="text" name="apikey" required value={fields.fields.apikey} onChange={(e)=>{ fields.fields.apikey=e.target.value; fieldsUpdate({...fields}) }} />
								      <label dangerouslySetInnerHTML={{__html: "ApiKey"}} ></label>
								</div>

							

							</div>
						</div>	


						<div className="line mobile-nobreak">
							<div className="column col6 left ">
								
							

							

							
								<fieldset className="balance margin-top-20">
									<legend>Saldo </legend>
									
									<table>
										<tbody>

											

											{ 

												 Object.keys(fields.fields.balancelist).length > 0 && (
													<>
														{Object.keys(fields.fields.balancelist).map((value,index)=>{
															return (
																<tr key={index}>
																	<th>{value}</th>
																	<td>{(fields.fields.balancelist[value].toFixed(3))}</td>
																</tr>
															)
														})}
													</>
												)
												
											}

											
										</tbody>
									</table>

									

								</fieldset>
							

							

							</div>
						</div>	

									
					</div>
			</div>
		</form>
	);
};


export default Admin;