import { env } from '../.env.js';
import axios from "axios";

export const api = axios.create({
	baseURL: env.AchoordURL
});


export const notificationsList = async (username) => {	
	return await api.get("/notifications/list/?username="+username);
}

export const notificationsGet = async (username,botid) => {
	return await api.get("/notifications/get/?username="+username+"&botid="+botid);
}

export const notificationsPut = async (username,botid,data) => {	
	return await api.put("/notifications/put/?username="+username+"&botid="+botid,data);	
}

export const notificationsPost = async (username,data) => {
	return await api.post("/notifications/post/?username="+username,data);	
}

export const notificationsDelete = async (username,botid) => {
	return await api.delete("/notifications/delete/?username="+username+"&botid="+botid);
}



export const notificationsClone = async (username,botid) => {

	let response = await api.get("/notifications/get/?username="+username+"&botid="+botid);
	let posfix = (new Date()).toLocaleTimeString();

	let data = response.data.content;
	data.name = data.name+' Clone '+posfix;
	data.title = data.title+' Clone '+posfix;

	

	delete data.id;
	return await api.post("/notifications/post/?username="+username,data);


}


export const notificationsBacktest = async (username,namebot,date,useBalance,getPrice,simulateBalance) => {

	// console.log('--',simulateBalance);

	if(useBalance === undefined) useBalance = 'false';
	if(getPrice === undefined) getPrice = 'false';
	if(simulateBalance === 0) simulateBalance= '';
	// let collection = 'name:'+username;	

	// fetch('http://example.com/movies.json')
	  // .then((response) => response.json())
	  // .then((data) => console.log(data));

	  /*return fetch('https://admin.achoord.com:1111/server/sendcommand?username=wallrio&project=trade&service=bottrade&command=php%20backtest.php%20wallrio%20invert-histogram%202022/10/21')
	  .then((response)=>{
	  	return response.json()
	  });
	   */
  	// .then((response) => response.json())
  	// .then((data) => console.log('--',data));

  	// http://api.chainbot.wallrio.com/global/backtest
  	return await api.post("global/backtest?user="+username+"&date="+date+"&balance="+simulateBalance);
	// return api.get("http://192.168.15.30/crypto/macd/php/backtest.php?account="+username+"&bot="+namebot+"&targetdate="+date+"&getbalance="+useBalance+"&getprices="+getPrice+"&simulatebalance="+simulateBalance);
	// return api.get("https://admin.achoord.com:1111/server/sendcommand?username=wallrio&project=trade&service=bottrade&command=php%20backtest.php%20"+username+"%20"+namebot+"%20"+date+"%20"+useBalance+"%20"+getPrice+"%20"+simulateBalance);
}
