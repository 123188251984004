import React, {useState,useContext} from "react";



import pack from "../../../package.json";


import logo from "../../assets/img/logo.png";
import "./style.css";


const Splash = () => {

	return (
		<>
			
			<div className="--splash h100 ">

				<div className="wrapper">
					<div className="header ">

					</div>
					<div className="main  ">				
						
						<div className="line h100">
							<div className="column middle center h100">
							
								<img src={logo} className="logo" alt="logo" />
								<h1>{pack.title}</h1>

							</div>
						</div>
					
					
					</div>
					<div className="footer"></div>
				</div>
			
			</div>
		</>
	);


};


export default Splash;
