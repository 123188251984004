import React from "react";
import { Link } from "react-router-dom"


// import "./style.css";

const Card = (props) => {
	
	return (
		<div className="layoutcard" enabled={props.enabledValue} mode={props.mode}>
			<div className="line">
				<div className="column col0 left top">										
					{props.sideLeft}					
				</div>
				<div className="column  left top">										
				

					<div className="line mobile-nobreak">
						<div className={"column  left middle "+props.numColMin}>										
							{props.splitMain}					
						</div>
						<div className="column right middle ">
							<ul className="sideLi">
								{props.splitInfo}						
							</ul>
						</div>
					</div>
					<div className="line mobile-nobreak tablet-nobreak controlLine">

						<div className={"column left "+props.numCol}>
							{props.splitControlLeft}			
						</div>
						<div className="column right">
							<ul className="sideLi">						
								{props.splitControlRight}					
								
							</ul>
						</div>

					</div>
					
				</div>
				<div className="column col0 right top">										
					{props.sideRight}					
				</div>


			</div>
			{props.areabottom}					
		</div>
	);
};


export default Card;