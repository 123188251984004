import React,{useEffect,useState,useRef} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

import Switch from '@mui/material/Switch';

import {botBacktest,botPut} from "../../../../../services/apiBots";



import HeaderControl from "./HeaderControl/index.jsx";
import FooterControl from "./FooterControl/index.jsx";
import MiniDash from "./MiniDash/index.jsx";
import ListDays from "./ListDays/index.jsx";

import "./style.css";

const Rules = (props) => {

	const inputRef = useRef(null);

	const [listDays, listDaysUpdate] = useState([]);	

	const [currentDay, currentDayUpdate] = useState(0);
	const [dayProcess, dayProcessUpdate] = useState(0);


	let initialBacktestVal = {

		"tradepercent_gainloss":0,
		"test_trade_total":0,
		"trade_total":0,
		"trade_gain":0,
		"trade_loss":0,
		
		"rest_percent_loss":0,
		"rest_percent_gain":0,
		"balance":0,
		"roi":0,
		"test_balance_estimate":0,
		"balanceStart":0,
		"balanceEnd":0,
	};


	const [loadingBacktestGeral, setloadingBacktestGeral] = useState({"status":"false"});	
	const [averageGeneralPercent, averageGeneralPercentUpdate] = useState(0);
	const [totalBacktest, setTotalBacktest] = useState(initialBacktestVal);
	const [profitMargin, profitMarginUpdate] = useState('');
	const [balanceSimulate, balanceSimulateUpdate] = useState(1000);
	const [fromDate, fromDateUpdate] = useState();	
	const [toDate, toDateUpdate] = useState();	

	



	return (
		<div className="h100" ref={inputRef}>			
			<div className="wrapper --option-theme">
				<div className="header dialog-header">


										
					<div className="line mobile-nobreak">
						<div className="column">

							<h2 className="dialog-title">Backtest </h2>
							
						</div>
						<div className="column col0">

							

							<button type="button" className={"link padding"} onClick={props.handleBacktestClose} >						
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>

				</div>
				<div className="main  ">
					
					<div className="mainSec">
						
						<HeaderControl 
						currentDay={currentDay} 
						currentDayUpdate={currentDayUpdate} 
						listDays={listDays} 
						listDaysUpdate={listDaysUpdate} 
						balanceSimulateUpdate={balanceSimulateUpdate} 
						balanceSimulate={balanceSimulate}  
						dayProcess={dayProcess}
						dayProcessUpdate={dayProcessUpdate}
						/>
														
						<div className={" areaList  mainScrollY" }>

					

							<div className="padding" visible={ (listDays.length < 1 && currentDay !== 'day')?'true':'false' }>
								<div className="line mobile-nobreak margin-bottom-20 padding alerttest" >
									<div className="column col2 left middle center " >
										
											<FontAwesomeIcon icon={faExclamationCircle} />
										
									</div>
									<div className="column left top " >
										<p>O teste realizado não garante o resultado em negociação no mercado.</p>
										<p>Realize suas operações com cuidado</p>
									</div>
								</div>
							</div>


							<div className="padding-h">															
								<MiniDash 
								currentDay={currentDay} 
								currentDayUpdate={currentDayUpdate} 
								listDays={listDays} 
								listDaysUpdate={listDaysUpdate} 
								totalBacktest={totalBacktest} 
								setTotalBacktest={setTotalBacktest} 
								dayProcess={dayProcess}
								dayProcessUpdate={dayProcessUpdate} />												

								<ListDays 
								listDays={listDays} 
								listDaysUpdate={listDaysUpdate} />

							</div>

						</div>

					</div>

						

				</div>
				<div className="footer">

					<hr/>

					<FooterControl 
					listDays={listDays} 
					listDaysUpdate={listDaysUpdate}
					currentDay={currentDay} 					
					loadingBacktestGeral={loadingBacktestGeral}
					setloadingBacktestGeral={setloadingBacktestGeral}
					fields={props.fields}
					balanceSimulateUpdate={balanceSimulateUpdate} 
					balanceSimulate={balanceSimulate}
					totalBacktest={totalBacktest} 
					setTotalBacktest={setTotalBacktest}
					dayProcess={dayProcess}
					dayProcessUpdate={dayProcessUpdate}
					/>

					
				
				</div>
			</div>
		</div>
	);
};


export default Rules;
