import {objToArray,sortKey,sortKey2} from "./misc.js";

const order = (list,key,test) => {

	// console.log(list,key,test);

	/*
	if( typeof list[key] === 'undefined' ) return {
		status: 0,
		// status: content[key].orderstatus,
		target_status: 0,
		target_percent: 0,
		roi_pnl: 0,
		roi_porcent: 0,
	}*/

	let content = JSON.stringify(list);
	content = JSON.parse(content);

	content = objToArray(content);
	content = sortKey2(content,'fields','updatetime');


	content = content.reverse();

	let status = null;
	let target_status = null;
	let target_percent = null;

	let totalBalancePre = 0;
	let totalVictoriesPre = 0;
	let totalLossPre = 0;

	let performanceListPreDate = [];
	let performanceListPrePrice = [];
	let performanceListPrePriceCoin = [];

	let moneyVictoriesValue = 0;
	let moneyLossValue = 0;

	for(let key2 in content){


		let a = content[key2].fields.order_sl_target - content[key2].fields.order_tp_target;
		let b = content[key2].fields.coin_last_price - content[key2].fields.order_tp_target;
		let c = (b*100/a) * -1;

		c = Math.abs(c);

		if(a < 0){
			c = (100-c);
		}
		c = c.toFixed(2);
		content[key2].fields.target_percent = c;
		target_percent = c;

		// console.log(test, content[key2].name , content[key2].fields.order_sl_target - content[key2].fields.order_tp_target );
		// console.log(test, content[key2].name , content[key2].fields.coin_last_price - content[key2].fields.order_tp_target );
		// console.log(test, content[key2].name , target_percent );

		

		content[key2].fields.orderstatus = '0';

		

		let currentTotal = 0;


		if(content[key2].fields.balance_last){

			currentTotal = (parseFloat(content[key2].fields.balance_last) - parseFloat(content[key2].fields.balance_start));

		}else{
			currentTotal = ((content[key2].fields.coin_last_price - content[key2].fields.order_main_price) * content[key2].fields.order_main_amount);
		}

		totalBalancePre += currentTotal;

		performanceListPrePrice[performanceListPrePrice.length] = currentTotal.toFixed(2);
		
		performanceListPrePriceCoin[performanceListPrePriceCoin.length] = parseFloat(content[key2].fields.coin_last_price).toFixed(2);
		// performanceListPreDate[performanceListPreDate.length] = content[key2].createtime;
		performanceListPreDate[performanceListPreDate.length] = (new Date(parseInt( content[key2].fields.updatetime )*1000)).toLocaleString();
		// performanceListPreDate[performanceListPreDate.length] = secToDate(new Date(parseInt( content[key2].updatetime - content[key2].createtime )));

	
		if( currentTotal >= 0){

			totalVictoriesPre++;
			moneyVictoriesValue += currentTotal;
		}else{
			totalLossPre++;
			moneyLossValue += currentTotal;
		}


		/*if( parseFloat(content[key2].fields.balance_last) - parseFloat(content[key2].fields.balance_start) === 0){
			content[key2].fields.target_status = 'equal';
			target_status = 'equal';
		}else{
			if( parseFloat(content[key2].fields.balance_last) - parseFloat(content[key2].fields.balance_start) > 0){
				content[key2].fields.target_status = 'gain';
				target_status = 'gain';
			}else{
				content[key2].fields.target_status = 'loss';					
				target_status = 'loss';
			
			}
		}*/


		/*if( content[key2].orderstatus !== 'active' ){
			content[key2].status = 'finish';
			target_status = 'finish';
		}*/

		/*if( content[key2].order_main_side === 'sell' ){
			content[key2].target_percent = 100 - content[key2].target_percent;
			target_percent = 100 - content[key2].target_percent;
		}*/

	}



	performanceListPreDate.reverse();
	performanceListPrePrice.reverse();
	// performanceListPrePriceCoin.reverse();

	let performanceListPre = {
		"status":true,
		"pricecoin":performanceListPrePriceCoin,
		"date":performanceListPreDate,
		"price":performanceListPrePrice
	};

	let roi_pnl = 0;;
	let roi_porcent = 0;
	

		if( content[key].fields.modeorder === 'order_normal'){			
			let percent = content[key].fields.balance_last/content[key].fields.balance_start*100;

			
			content[key].fields.target_percent = percent;
			target_percent = percent;
		}
			
		if( content[key].fields.order_main_side === 'sell' ){
			content[key].fields.target_percent = 100 - content[key].fields.target_percent;
			target_percent = 100 - content[key].fields.target_percent;
		}


		

	try{
		roi_pnl = ( parseFloat(list[key].fields.balance_last) - parseFloat(list[key].fields.balance_start) ).toFixed(2);
	}catch(e){}

	try{
		roi_porcent = (roi_pnl / list[key].fields.order_main_cost * 100).toFixed(2);
	}catch(e){}

	// console.log( list[key].fields.balance_last ,'=', list[key].fields.balance_start );

	content[key].fields.statusbase = '';

	if( Math.abs(parseInt(content[key].fields.updatetime/1000) -  parseInt( (new Date()).getTime() /1000)) > 60 ) {
		if( content[key].fields.status === 'canceled' ){

			if(roi_pnl > 0) status = 'gain';
			if(roi_pnl < 0) status = 'loss';
			if(roi_pnl == 0 ) status = 'equal';

			/*if(roi_pnl > 0) content[key].status = 'gain finish ';
			if(roi_pnl < 0) content[key].status = 'loss finish ';
			if(roi_pnl == 0  ) content[key].status = 'equal finish ';*/
			// content[key].statusbase = 'finish';
		}
	}


	if( content[key].fields.status === 'positioned' ){


		// if( content[key].fields.modeorder !== 'order_oco'){	
			/*if(roi_pnl > 0) content[key].status = 'gain positioned';
			if(roi_pnl < 0) content[key].status = 'loss positioned';
			if(roi_pnl == 0  ) content[key].status = 'equal positioned ';*/
			/*if(roi_pnl > 0) status = 'gain';
			if(roi_pnl < 0) status = 'loss';
			if(roi_pnl === '-0.00' ) status = 'equal';
			*/

		// }

		// console.log(status,roi_pnl);
		status = 'positioned';

	}else if( content[key].fields.status === 'order' ){


		// if( content[key].fields.modeorder !== 'order_oco'){	
			/*if(roi_pnl > 0) content[key].status = 'gain positioned';
			if(roi_pnl < 0) content[key].status = 'loss positioned';
			if(roi_pnl == 0  ) content[key].status = 'equal positioned ';*/
			/*if(roi_pnl > 0) status = 'gain';
			if(roi_pnl < 0) status = 'loss';
			if(roi_pnl === 0) status = 'equal ';*/

		// }

		// console.log(status,roi_pnl);
		status = 'order';

	}else if( content[key].fields.status === 'canceled' ){
		if(roi_pnl > 0) status = 'gain';
		if(roi_pnl < 0) status = 'loss';
		if(roi_pnl == 0 ) status = 'equal';
		
		/*if(roi_pnl > 0) content[key].status = 'gain finish ';
		if(roi_pnl < 0) content[key].status = 'loss finish ';
		if(roi_pnl == 0  ) content[key].status = 'equal finish ';*/
		

		// content[key].statusbase = 'canceled';
	}

	if( content[key].fields.status === 'canceled'  
		|| content[key].fields.status === 'finish' 
		|| content[key].fields.status === 'gain' 
		|| content[key].fields.status === 'loss' 
		|| content[key].fields.status === 'equal' ){
		
		if(roi_pnl > 0) status = 'gain';
		if(roi_pnl < 0) status = 'loss';
		if(roi_pnl == 0 ) status = 'equal';

		// console.log( content[key].fields.status );

		/*if(roi_pnl > 0) content[key].status = 'gain finish ';
		if(roi_pnl < 0) content[key].status = 'loss finish ';
		if(roi_pnl == 0 ) content[key].status = 'equal finish ';*/
		// console.log(roi_pnl);
		content[key].fields.statusbase = 'finish';
		// status = 'order';
	}

	if( content[key].fields.status === 'cancel' ){
		// content[key].statusbase = 'cancel';
	}


	if( content[key].fields.status === 'order' ){
		// content[key].statusbase = 'order';
	}

	

	/*if( content[key].status === 'finish' ){
		content[key].statusbase = 'finish-'+content[key].orderfinish;
	}*/

	// content[key].fields.target_percent = Math.abs(content[key].fields.target_percent);
/*
	if(test !== 'test'){

			console.log('==', content[key].fields.target_percent );
		}
*/
	
	if(roi_pnl === '-0.00'){
		// console.log(roi_pnl);
		roi_pnl = Math.abs(roi_pnl);
	}

	return {
		status: status,
		// status: content[key].fields.status,
		statusbase: content[key].fields.statusbase,
		target_status: content[key].fields.target_status,
		target_percent: content[key].fields.target_percent,
		roi_pnl: roi_pnl,
		roi_porcent: roi_porcent
	}
}

export {order}
