import React,{useEffect,useState,useRef} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import Switch from '@mui/material/Switch';

import {botBacktest,botPut} from "../../../../../services/apiBots";

import {sequentialArray} from "../../../../../libs/sequentialArray.jsx";

import "./style.css";

const Rules = (props) => {

	const inputRef = useRef(null);

	const [selected,selectedUpdate] = useState(false);


	const [list,listUpdate] = useState({
		
		"macd_cross":{
			"title":"Cruzamento MACD",
			"description":"Este modelo utiliza o indicador MACD",
			"fields":{ 
				"enabled": true,
			    "name": "cruzamento-macd-"+(new Date()).toLocaleString(),
			    "title": "Cruzamento MACD "+(new Date()).toLocaleString(),
			    "description": "Este modelo utiliza o indicador MACD",			    
			    
			    "coinpair": "BTC/USDT",	   
			    "invertorder": "false",
			    "targetdate": "",			    
			    "typeorder": "market",
			    "interval": "5m",
			    "porcentgain": "0.50",
			    "porcentloss": "0.20",
			    "leverage": "1",
			    "amount": "20%",
			    "rulesbots": "[{\"candle\":\"1\",\"candle2\":\"1\",\"check\":\"true\",\"condition\":\"\",\"indicator2\":\"macdline\",\"operator\":\">\",\"order\":\"sell\",\"firstoccurrence\":true,\"period1\":\"\",\"period2\":\"\",\"indicator\":\"macdsignal\"},{\"candle\":\"1\",\"candle2\":\"1\",\"check\":\"true\",\"condition\":\"\",\"indicator2\":\"macdline\",\"operator\":\"<\",\"order\":\"buy\",\"firstoccurrence\":true,\"period1\":\"\",\"period2\":\"\",\"indicator\":\"macdsignal\"}]",   
			    "margemtype": "isolated",
			    
			}
		}

	});

	const selectModelHandle = (key) =>{

		for(let keyfor in list){
			list[keyfor].active = '';
		}

		list[key].active = 'active';


		listUpdate({...list});

		selectedUpdate(key);
	}
	
	const importModelHandle = (e) =>{

		// console.log( list[selected] );

		props.fieldsUpdate({...list[selected].fields});
		props.setListBots(JSON.parse(list[selected].fields.rulesbots));
		props.openModelsClose();
	}
	
	return (
		<div className="h100" ref={inputRef}>			
			<div className="wrapper --option-theme">
				<div className="header dialog-header">


										
					<div className="line mobile-nobreak">
						<div className="column">

							<h2 className="dialog-title">Modelos </h2>
							
						</div>
						<div className="column col0">

							

							<button type="button" className={"link padding"} onClick={props.openModelsClose} >						
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>

				</div>
				<div className="main  ">
					
					<div className="mainSec">
						{/*<div className="headerControl">
							<div className="line  mobile-nobreak  padding-h-10 padding-bottom-10">
								<div className="column col6 "></div>
								<div className="column padding-left"></div>
							</div>						
						</div>*/}


							
						<div className={"padding areaList  mainScrollY" }>

							<ul className="listmodels">
								
								{Object.keys(list).map((key,index)=>{
									return (
										<li key={key} className={ list[key].active } onClick={()=>{ selectModelHandle(key) }}>
											<label>{list[key].title}</label>
											<p>{list[key].description}</p>
										</li>
									)
								})}

							</ul>

						</div>

				</div>

						

				</div>
				<div className="footer">

					<hr/>

					<div className="line mobile-nobreak ">					
						<div className="column left col3 padding middle">
						
							<button type="button" className={"changebtn button -primary w100 padding-h-10 "+(selected===false?'disabled':'') } onClick={importModelHandle} >Importar</button>
							
						</div>
					</div>
				
				</div>
			</div>
		</div>
	);
};


export default Rules;
