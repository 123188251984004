
let sequentialArray = (options) =>{

	let array = options['array'];
	let index = options['index'];
	let callback = options['callback'];
	let callbackFinish = options['callbackFinish'];

	if(index === undefined) index = 0;

	if(array[index] === undefined){
		if(callbackFinish)callbackFinish();
		return false;
	}

	let next = () =>{
		options['index'] = index + 1;
		sequentialArray(options);
	}
	if(callback)callback(index,array[index],next);


}

export {sequentialArray}