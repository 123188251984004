import React, {useState,  useEffect,useContext, useRef} from "react";

import {botGet,botPut,botPost,botDelete,botClone} from "../../../../services/apiBots";
import {exchangeList} from "../../../../services/apiExchange";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave,faPlay,faStop,faThumbsUp,faThumbsDown,faSearch,faToggleOn,faChevronUp,faChevronDown,faBars,faBuildingColumns,faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

import QuestionInline from "../../../../components/questioninline";
import {slugfy} from "../../../../libs/slugfy.jsx";

import {stampToFormat,typeOf,checkJson} from "../../../../libs/misc";



import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Rules from './rules';
import BackTest from './backtest';
import ModelsBot from './modelsbot';

import ConfigInitial from './configinitial';
import StrategyInitial from './strategyinitial';
import BacktestInitial from './backtestinitial';

import {GlobalContext} from "../../../../contexts/global";
// import {indicators} from "../../../../helpers/trades/indicators";

// import Tabs from "../../../../components/tabs/index.jsx";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';


import "./style.css";

const Admin = (props) => {

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null);


	const [loading, loadingUpdate] = useState(true);

	const [hasEdited, hasEditedUpdate] = useState(null);

	const [listExchanges, listExchangesUpdate] = useState([]);
	const [fieldsOld,fieldsOldUpdate] = useState({});
	
	const [tabIndex, setTabIndex] = useState(0);

	const fieldsDefined = {
	    "enabled": true,
	    "name": "",
	    "description": "",
	    "fields": {
		    "exchange": "",
		    "targetdate": "",
		    "coinfrom": "",
		    "cointo": "",
		    "leverage": "1",
		    "amount": "",
		    "amounttype": "",
		    "margemtype": "",
		    "timeinforce": "",
		    "interval": "5m",
		    "rulesbots": "[]",	    
		}
	};

	const [fields,fieldsUpdate] = useState(fieldsDefined);

	const [listBots, setListBots] = useState([]);
	const [fieldsLoaded,fieldsLoadedUpdate] = useState(false);

	const onSubmitHandle = (e) =>{		
		e.preventDefault();
		save();
	}


	useEffect(()=>{


		if(hasEdited === null) return;
		if(fieldsLoaded === false) return;
		
		// console.log(fields,fieldsOld);

		if(fields.enabled !== fieldsOld.enabled ){
			// hasEditedUpdate(null);
			return;
		}
		

		// console.log('==',fields,fieldsOld);

		fields.fields.backteststatus = 'reset';


		fieldsUpdate({...fields});
		fieldsOldUpdate({...fields});

		// hasEditedUpdate(null);

	},[hasEdited]);


	const save = () =>{



		fieldsLoadedUpdate(false);

		if(inputRef.current.querySelector('form [type="submit"]'))
		inputRef.current.querySelector('form [type="submit"]').classList.add('loading');
		
		// if(inputRef.current.querySelector('form'))
		inputRef.current.classList.add('disabled');

		let coinfrom = inputRef.current.querySelector('[name="coinfrom"]');		
		if(coinfrom) fields.fields.coinfrom = coinfrom.value;

		let cointo = inputRef.current.querySelector('[name="cointo"]');		
		if(cointo) fields.fields.cointo = cointo.value;

		// console.log(fields.fields.coinfrom);
		// console.log(fields.fields.cointo);
		
		if(coinfrom === 'error' || cointo === 'error'){
			return;
		}
		
		let interval = inputRef.current.querySelector('[name="interval"]');
		if(interval) fields.fields.interval = interval.value;

	/*	let margemtype = inputRef.current.querySelector('[name="margemtype"]');
		if(margemtype) fields.fields.margemtype = margemtype.value;

		let timeinforce = inputRef.current.querySelector('[name="timeinforce"]');
		if(timeinforce) fields.fields.timeinforce = timeinforce.value;

		let timeinforce = inputRef.current.querySelector('[name="timeinforce"]');
		if(timeinforce) fields.fields.timeinforce = timeinforce.value;*/

		

		fields.fields.rulesbots = JSON.stringify(listBots);

		fields.fields.backtest_gain = '';
		fields.fields.backtest_loss = '';
		// console.log('+',listBots);
		// console.log('=',fields);

		let newField = JSON.stringify(fields);
		newField = JSON.parse(newField);

		if( newField.enabled === true ) newField.enabled = 'true';
		if( newField.enabled === false ) newField.enabled = 'false';

		if( newField.enabled === '1' ) newField.enabled = 'true';
		if( newField.enabled === '0' ) newField.enabled = 'false';

		// newField.name = slugfy(newField.name);

		delete newField.fields.backtest_orders;
		delete newField.fields.lastprice;

		// console.log(newField);


		if( props?.adminCurrent === 'new' ){


			botPost(window.username,  newField).then((response)=>{

				let {status,content} = response.data;
				
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
				inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');

				// if(inputRef.current.querySelector('form'))
				inputRef.current.classList.remove('disabled');

				if(status === 'success'){

					// if(inputRef.current.querySelector('form [type="submit"]')){
						setListBots([]);
						fieldsUpdate(fieldsDefined);
						fieldsOldUpdate(fieldsDefined);

						props.adminCurrentUpdate( null );
						props.posAdminCallback();

						hasEditedUpdate(false);
						// fieldsLoadedUpdate(true);

						setTimeout(()=>{
							fieldsLoadedUpdate(false);
							// fieldsLoadedUpdate(true);
						},500);

						inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
						setTimeout(()=>{
							//fieldsLoadedUpdate(true);
							if(inputRef.current.querySelector('form [type="submit"]'))
							inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
						},2000);

					// }
			
				}else{

					if(inputRef.current.querySelector('form [type="submit"]'))
					inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
					
					setTimeout(()=>{
						fieldsLoadedUpdate(true);
					},500);

					setTimeout(()=>{
						if(inputRef.current.querySelector('form [type="submit"]'))
						inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
					},2000);
				}
			});


			

			return;
		}



		botPut(window.username, props?.adminCurrent?._id, newField).then((response)=>{

			let {status,content} = response.data;
			
			// return;
			inputRef.current.classList.remove('disabled');


			if(status === 'success'){

				if(inputRef.current.querySelector('form [type="submit"]')){
					inputRef.current.querySelector('form [type="submit"]').classList.remove('mark')
					inputRef.current.querySelector('form [type="submit"]').classList.remove('loading');

					hasEditedUpdate(false);
					// fieldsLoadedUpdate(true);

					// setListBots([]);
					// fieldsUpdate({});
					// fieldsOldUpdate({});

					props.adminCurrentUpdate( null );
					props.posAdminCallback();

					setTimeout(()=>{
						fieldsLoadedUpdate(true);
					},500);

					inputRef.current.querySelector('form [type="submit"]').classList.add('-success');
					setTimeout(()=>{
						//fieldsLoadedUpdate(true);
						if(inputRef?.current && inputRef?.current?.querySelector('form [type="submit"]'))
						inputRef.current.querySelector('form [type="submit"]').classList.remove('-success');
					},2000);

				}
		
			}else{

				setTimeout(()=>{
					fieldsLoadedUpdate(true);
				},500);

				inputRef.current.querySelector('form [type="submit"]').classList.add('-error');
				setTimeout(()=>{
					//fieldsLoadedUpdate(true);
					if(inputRef.current.querySelector('form [type="submit"]'))
					inputRef.current.querySelector('form [type="submit"]').classList.remove('-error');
				},2000);
			}
		});
		
	}


	const load = (e) =>{

		inputRef.current.querySelector('.sticky').scrollTo(0,0);


		if(fields.fields.rulesbotsByStrategy){
			fieldsDefined.fields.rulesbots = fields.fields.rulesbotsByStrategy;
			setListBots(JSON.parse(fieldsDefined.fields.rulesbots));
			delete fields.fields.rulesbotsByStrategy;

			if(inputRef.current.querySelector('form [type="submit"]'))
			inputRef.current.querySelector('form [type="submit"]').classList.add('mark');
			return;
		}


		if( props?.adminCurrent === 'new' ){

			// setListBots([]);
			// fieldsUpdate({});
			// fieldsOldUpdate({});
			if(inputRef.current.querySelector('form'))
			inputRef.current.querySelector('form').reset();

			/*let content = {
				enabled:'true',				
				title : "",
				description : "",
				rulesbots : "[]",
				
			};*/

			// if( content.enabled === 'true' ) content.enabled = true;
			// if( content.enabled === 'false' ) content.enabled = false;


			if(props.adminCurrentParameters){
				fieldsDefined.name = props.adminCurrentParameters.name ?? '';
				fieldsDefined.description = props.adminCurrentParameters.description ?? '';
				fieldsDefined.fields.rulesbots = props.adminCurrentParameters.rulesbots ?? '';
			}

			// console.log(fieldsDefined);

			

			setListBots(JSON.parse(fieldsDefined.fields.rulesbots));

			fieldsUpdate({...fieldsDefined});
			fieldsOldUpdate({...fieldsDefined});

			setTimeout(()=>{
				loadingUpdate(false);
				fieldsLoadedUpdate(true);
			},50);

			return;
		}
		
		

		botGet(window.username,props?.adminCurrent?._id).then((response)=>{

			// console.log(response.data);

			let status = response.data.status;


			if(status === 'success'){

				let content = response.data.body[0];

				if( content.enabled === 'true' ) content.enabled = true;
				if( content.enabled === 'false' ) content.enabled = false;

				content.fields = JSON.parse(content.fields);

				try{
					if(content.fields.backtest_orders)
					content.fields.backtest_orders = JSON.parse(content.fields.backtest_orders);
				}catch(e){}
	
				
				// fields.fields.lastprice.progress




				if( typeof content.fields?.lastprice === 'undefined' 
					|| content.fields?.lastprice === 'null'){

					content.fields.lastprice = {
						progress:[]
					};
				}else{
					if( checkJson(content.fields.lastprice) === false ){				
						content.fields.lastprice = {
							progress:[]
						};
					}else{
						content.fields.lastprice = JSON.parse(content.fields.lastprice);		
					}
				}


		

				setListBots(JSON.parse(content.fields.rulesbots));

				fieldsUpdate({...content});
				fieldsOldUpdate({...content});

				

				setTimeout(()=>{
					loadingUpdate(false);
					fieldsLoadedUpdate(true);
				},500);
			}
		});
		
	}


	let getExchanges = () => {
		
		try {		
		
			exchangeList(window.username).then((response)=>{
				let data = response.data.body;

				let newData = {};
				for(let key in data){
					if(data[key].enabled === 'false') continue;

					// console.log( typeof data[key].fields );

					if(typeof data[key].fields === 'string')
						data[key].fields = JSON.parse(data[key].fields);

					// console.log(data[key].fields?.balancelist);

					if( typeof data[key].fields?.balancelist === 'undefined'
						|| data[key].fields?.balancelist === 'null'){
						data[key].fields.balancelist = {};
					}else{
						if( checkJson(data[key].fields.balancelist) === false ){				
							data[key].fields.balancelist = {};
						}else{
							data[key].fields.balancelist = JSON.parse(data[key].fields.balancelist);				
						}
					}
					
					if( typeof data[key].fields?.markets === 'undefined'
						|| data[key].fields?.markets === 'null'){
						data[key].fields.markets = {};
					}else{
						if( checkJson(data[key].fields.markets) === false ){				
							data[key].fields.markets = {};
						}else{
							data[key].fields.markets = JSON.parse(data[key].fields.markets);				
						}
					}


					newData[key] = data[key];
				}
				
				

				listExchangesUpdate(newData);
			});
		} catch (error) {
				
		}
	}


	useEffect(()=>{


		if(fieldsLoaded === false) return;

		fields.fields.rulesbots = JSON.stringify(listBots);

		

		fieldsUpdate({...fields});
		// fieldsOld({...fields});

	},[listBots]);


	useEffect(()=>{

		loadingUpdate(true);
		setTabIndex(0);
		
		props.linkUpdate({
			actionOnClose:()=>{				

				if(inputRef.current.querySelector('form [type="submit"]'))
				inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');
		

				setListBots([]);
				fieldsUpdate(fieldsDefined);
				fieldsOldUpdate(fieldsDefined);

				props.adminCurrentUpdate( null );
				props.posAdminCallback();

				
				fieldsLoadedUpdate(false);
				hasEditedUpdate(false);



				/*setTimeout(()=>{
					fieldsLoadedUpdate(false);
				},1000);*/
			}
		});

		

		getExchanges();
		load();
	},[props.adminCurrent]);

	/*useEffect(()=>{
		console.log('==',fields);
	},[fields]);*/

	useEffect(()=>{


		if(fieldsLoaded === false) return;
		if(props?.adminCurrent === 'new') return;
		hasEditedUpdate(true);
		
		if(inputRef.current.querySelector('form [type="submit"]'))
		inputRef.current.querySelector('form [type="submit"]').classList.add('mark');
		
	},[fields]);


/*
	  let [openBacktest, openBacktestUpdate] = useState(false);
	  const openBacktestHandle = key => {
		   openBacktestUpdate(true);
	  };
	  const handleBacktestClose = () => {
	  	openBacktestUpdate(false);
	  };
*/
	
	  let [currentKey, currentKeyUpdate] = useState(null)
	  let [open, openUpdate] = useState(false)
	  
	  


	  const handleClose = () => {

	  	openUpdate(false);
	  };


	  const deleteBotHandle = () => {

	  		
  			fieldsLoadedUpdate(false);

  			if(inputRef.current.querySelector('form [type="submit"]'))
			inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');

  			props.optionCloseHandle();
  			
  			// console.log(fields);

	  		botDelete(window.username,  props.adminCurrent?._id).then((response)=>{

	  			hasEditedUpdate(false);
				props.adminCurrentUpdate( null );
  				props.posAdminCallback();

			});

	  };


	  const cloneBotHandle = () => {

	  		
  			fieldsLoadedUpdate(false);

  			if(inputRef.current.querySelector('form [type="submit"]'))
			inputRef.current.querySelector('form [type="submit"]').classList.remove('mark');

  			props.optionCloseHandle();
  			

	  		botClone(window.username,  props.adminCurrent?._id).then((response)=>{

	  			hasEditedUpdate(false);
				props.adminCurrentUpdate( null );
  				props.posAdminCallback();

			});

	  };

	

	   let [openModels, openModelsUpdate] = useState(false);
	  const openModelsHandle = key => {
		   // currentKeyUpdate(fields.name);
		   openModelsUpdate(true);
	  };
	  const openModelsClose = () => {
	  		openModelsUpdate(false);
	  };


	  

	 
		

	return (
		<form ref={inputRef} onSubmit={onSubmitHandle}>


			


			<Dialog onClose={handleClose} open={open}  >
				<Rules handleClose={handleClose} listBots={listBots} setListBots={setListBots} currentKey={currentKey} ></Rules>				
			</Dialog>

			

			<Dialog onClose={openModelsClose} open={openModels}  >
				<ModelsBot openModelsClose={openModelsClose} fields={fields} fieldsUpdate={fieldsUpdate} setListBots={setListBots}   ></ModelsBot>
			</Dialog>
			
			<div className="--admin h100 ">
				

				


					<div className=" options_header">
						<div className="line mobile-nobreak tablet-nobreak padding">

							{ ( loading === false ) && (
								<>
									<div className="column col5 left middle">
										
										<button type="submit" className={"button -third w100"} onClick={(e)=>{  }} >						
											{ (props?.adminCurrent === 'new') ? (
												<>
													Criar
												</>
											):(
												<>
													Salvar
												</>
											)}
										</button>

										{/*<h1>Bot</h1>*/}
										{/*<label className="options_title" dangerouslySetInnerHTML={{__html: "" }} ></label>*/}

									</div>
									<div className="column col2 right middle">		
									</div>							
									<div className="column col0 right middle">		

										<QuestionInline>
											<div question="">
												<button type="button" className={"link -secondary  "}  >						
													<FontAwesomeIcon icon={faTrash} />
												</button>
											</div>
											<div alternatives="">
												<div response="yes">
													<button type="button" className={"link "} onClick={(e)=>{ deleteBotHandle() }} >						
														<FontAwesomeIcon icon={faCheck} />
													</button>
													
												</div>			
											</div>
										</QuestionInline>					
										
									</div>
									<div className="column col0 right middle">		

										<QuestionInline>
											<div question="">
												<button type="button" className={"link -secondary  "}  >						
													<FontAwesomeIcon icon={faClone} />
												</button>
											</div>
											<div alternatives="">
												<div response="yes">
													<button type="button" className={"link "} onClick={(e)=>{ cloneBotHandle() }} >						
														<FontAwesomeIcon icon={faCheck} />
													</button>
													
												</div>			
											</div>
										</QuestionInline>					
										
									</div>
									
									

									
									<div className="column col0 right ">																	
										<Switch checked={(fields.enabled)?true:false} onChange={(e)=>{ fields.enabled=e.target.checked; fieldsUpdate({...fields}) }} name="jason" />
									</div>						
								</>
							)}
						</div>
					</div>

					<div  className="padding sticky vh70 scrolly "  >

						{Object.keys(globals.exchanges).map((key,index)=>{
							return (
								<div key={key} >
									{
										((globals.exchanges[key]._id === fields.exchange)?(globals.exchanges[key].fields.status):('')) === 'error' && (
											<div className="-error-text padding margin-bottom-10 ">
												<div className="line mobile-nobreak tablet-nobreak ">									
													<div className="column col2 center middle">
														<FontAwesomeIcon icon={faBuildingColumns} />	
													</div>
													<div className="column  left middle">Não conectado com a corretora</div>
												</div>
											</div>
										)
									}
								</div>
							)
						})}

				


					{ ( loading === true ) && (
					<div className="loading center padding link "></div>
					)}

					{ ( loading === false ) && (						
						<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
							<TabList className="react-tabs__tab-list padding-bottom-20">
								<Tab>Inicial</Tab>
								<Tab>Estratégia</Tab>
								<Tab>Backtest</Tab>
							</TabList>

							<TabPanel>
								<ConfigInitial fields={fields} fieldsUpdate={fieldsUpdate} listExchanges={listExchanges} props={props} />
							</TabPanel>
							<TabPanel>
								<StrategyInitial fields={fields} fieldsUpdate={fieldsUpdate} listBots={listBots} setListBots={setListBots} currentKeyUpdate={currentKeyUpdate} openUpdate={openUpdate} reloadCurrentBot={load} />
							</TabPanel>
							<TabPanel>
								<BacktestInitial fields={fields} fieldsUpdate={fieldsUpdate} />
							</TabPanel>
						</Tabs>
											
					)}
						


									
									
					</div>
			</div>
		</form>
	);
};


export default Admin;