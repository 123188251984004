import React, {useEffect,useRef,useContext,useState} from "react";

import {GlobalContext} from "../../../../../contexts/global";
// import "./style.css";

const Component = ({fields,fieldsUpdate,listExchanges,props}) =>{

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null); 	
 	// let idcomp = (new Date()).getTime();

 	const [cointoList,cointoListUpdate] = useState({});

 	useEffect(()=>{

		let coinsArray = JSON.parse(JSON.stringify(globals.exchangeCoins));

		let exchange_slug = null;

		for(let key in listExchanges){
			 if(listExchanges[key]._id === fields.fields.exchange){
				let name = listExchanges[key].name;
				let exchange_fields = listExchanges[key].fields;
				exchange_slug = exchange_fields.exchange;
				
				
				for(let key2 in globals.listBots){
					let cointo = globals.listBots[key2].fields.cointo;
					
					if(fields.fields.cointo !== cointo)
					delete coinsArray[exchange_slug][cointo];
					
				}
				
			}
		}
		

		// console.log( exchange_slug, coinsArray ); 
		if(exchange_slug) cointoListUpdate( coinsArray[exchange_slug] ); 
		
 	
 	},[globals.exchangeCoins,fields.fields.exchange]);


	return (
		<div  ref={inputRef} >	
		
			<input type="text" name="username"  autoComplete="username" style={{"display":"none"}} />

			<div className="input-float flatmodel ">
			      <input type="text" name="name" required value={fields.name} onChange={(e)=>{ fields.name=e.target.value; fieldsUpdate({...fields}) }} />
			      <label dangerouslySetInnerHTML={{__html: "Title"}} ></label>
			</div>	

			<div className="input-float flatmodel ">
			      <input type="text" name="description"   value={fields.description} onChange={(e)=>{ fields.description=e.target.value; fieldsUpdate({...fields}) }} />
			      <label dangerouslySetInnerHTML={{__html: "Description"}} ></label>
			</div>				
			
			<div className="line mobile-nobreak tablet-nobreak ">
				<div className="column middle left ">
					<label className="padding-h-10">Corretora</label>					
						
					<select className="select w100" required onChange={(e)=>{ fields.fields.exchange=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.exchange}  required>				
					

						{ Object.keys(listExchanges).length < 1 && (
							<option value=""  >Crie uma corretora na aba corretora</option>
						)}						

						{ Object.keys(listExchanges).length > 0 && (							
							<option value=""  >Selecione uma corretora</option>
						)}						
						

						{ Object.keys(listExchanges).map((key,index)=>{
							return (

								<option key={key} value={listExchanges[key]._id}>
									{listExchanges[key].name} &nbsp;
									{listExchanges[key].fields.status==='error'?'[Não conectado]':''}
								</option>
							);
						})}
					</select>
				</div>
			</div>

	


			<div className="line mobile-nobreak tablet-nobreak margin-top-10">
				<div className="column middle col6 left padding-right">
					<label className="padding-h-10">Ordem de Tempo</label>
					<select className="select w100" name="timeinforce" required onChange={(e)=>{ fields.fields.timeinforce=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.timeinforce} >						
						<option value="" disabled>Ordem de Tempo</option>
						<option value="ioc" >IOC - Imediata parcial</option>
						<option value="gtc">GTC - Aguarda ser completada ou cancelada</option>
						<option value="fok">FOK - Imediata completa</option>
					</select>	
				</div>
				<div className="column middle left ">
					<label className="padding-h-10">Tipo de margem</label>
					<select className="select w100" name="margemtype" required onChange={(e)=>{ fields.fields.margemtype=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.margemtype} >						
						<option value="" disabled>Tipo de margem</option>
						<option value="isolated" >Isolado</option>
						<option value="cross">Cruzado</option>
					</select>
				</div>
			</div>

			<div className="line mobile-nobreak tablet-nobreak ">
				<div className="column middle col6  padding-right">

					

					<label className="padding-h-10">Coin from</label>
					<select className="select w100" name="coinfrom" required onChange={(e)=>{ fields.fields.coinfrom=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.coinfrom} >						
						<option value="" disabled>Coin from</option>
						

						{ !fields.fields.exchange && (
							<>
								<option key="1" value="">select a exchange</option>
							</>
						)}


						{ Object.keys(listExchanges).map((key,index)=>{


							if( listExchanges[key]._id !== fields.fields.exchange ) return;

							
							let balancelist = (listExchanges[key].fields.balancelist);											
							
							if( Object.keys(balancelist).length < 1 ) 
								return(
									<option key="1" value="error">select a exchange</option>
								)

							return (
								<React.Fragment key={index}>
									{Object.keys(balancelist).map((key2,index2)=>{
										return(
											<option key={key2} value={key2}>{key2} - {balancelist[key2].toFixed(2)}</option>
										)
									})}

								</React.Fragment>											
							);
						})}

					</select>

				</div>
				<div className="column middle left ">

					<label className="padding-h-10">Coin to</label>
					<select className="select w100" name="cointo" required onChange={(e)=>{ fields.fields.cointo=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.cointo} >						
						<option value="" disabled>Coin to</option>
						
						
						{( !fields.fields.exchange || Object.keys(cointoList).length < 1) && (
							<>
								<option key="1" value="">select a exchange</option>
							</>
						)}

						{ (fields.fields.exchange && Object.keys(cointoList).length > 0) && (
							<>
								{ Object.keys(cointoList).map((key,index)=>{
									return (
										<React.Fragment key={index}>																						
											<option key={key} value={key}>{key}</option>										
										</React.Fragment>											
									);
								})}
							</>
						)}

					</select>


			
				</div>
			</div>

			<div className="line mobile-nobreak tablet-nobreak ">
			
				<div className="column col6 middle right padding-right">
					<div className="input-float flatmodel">
					      <input type="text" name="amount" required  value={fields.fields.amount} onChange={(e)=>{ fields.fields.amount=e.target.value; fieldsUpdate({...fields}) }} />
					      <label >Amount</label>
					</div>
				</div>
				<div className="column middle left ">
					<label className="padding-h-10">Amount type</label>
					<select className="select w100" name="amounttype" required onChange={(e)=>{ fields.fields.amounttype=e.target.value; fieldsUpdate({...fields}) }} value={fields.fields.amounttype} >						
						<option value="" disabled>Amount type</option>
						<option value="coin">Coin</option>
						<option value="usd">USD</option>
						<option value="percentage" >Percentage</option>
					</select>
				</div>
			</div>

			

			{ props.typeorderValue === 'limit' && (
				<div className="input-float flatmodel">
				      <input type="text" name="price" required  value={fields.fields.price} onChange={(e)=>{ fields.fields.price=e.target.value; fieldsUpdate({...fields}) }} />
				      <label >Price</label>
				</div>
			)}

		

			<div className="line mobile-nobreak tablet-nobreak ">
				<div className="column col6 middle right padding-right">
					<div className="input-float flatmodel">
					      <input type="text" name="leverage" required  value={fields.fields.leverage} onChange={(e)=>{ fields.fields.leverage=e.target.value; fieldsUpdate({...fields}) }} />
					      <label >Alavancagem</label>
					</div>
				</div>
				<div className="column middle left ">
					<label className="padding-h-10">Intervalo</label>
					<select className="select w100" name="interval" required  value={fields.fields.interval} onChange={(e)=>{ fields.fields.interval=e.target.value; fieldsUpdate({...fields}) }} >						
						<option value="" disabled>Intervalo</option>							
						<option value="1m">1 minuto</option>
						<option value="3m">3 minuto</option>
						<option value="5m">5 minuto</option>
						<option value="15m">15 minuto</option>
						<option value="30m">30 minuto</option>
						<option value="1h">1 hora</option>
						<option value="2h">2 hora</option>
						<option value="4h">4 hora</option>
						<option value="6h">6 hora</option>
						<option value="8h">8 hora</option>
						<option value="12h">12 hora</option>
						<option value="1d">1 dia</option>
						<option value="3d">3 dia</option>
						<option value="1w">1 semana</option>
						<option value="1M">1 mês</option>
					</select>
				</div>
			</div>
			

		</div>
	)
}

export default Component;
