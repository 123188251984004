import React, {useContext,useEffect} from "react";

import {AuthProvider,AuthContext} from "./contexts/auth";
import {GlobalProvider} from "./contexts/global";

import {
	BrowserRouter,
	Route,
	Routes,
	Navigate,	
	useLocation
} from "react-router-dom"


import SplashPage from "./pages/splash/index.jsx";
import LoginPage from "./pages/login/index.jsx";
import NotFoundPage from "./pages/notfound/index.jsx";
import DashboardPage from "./pages/dashboard/index.jsx";

import DashboardNotFoundPage from "./pages/dashboard/notfound/index.jsx";
import DashboardHomePage from "./pages/dashboard/home/index.jsx";
import DashboardBotsPage from "./pages/dashboard/bots/index.jsx";
import DashboardExchangesPage from "./pages/dashboard/exchanges/index.jsx";
import DashboardOrdersPage from "./pages/dashboard/orders/index.jsx";
import DashboardNotificationsPage from "./pages/dashboard/notifications/index.jsx";

const AppRoutes = () =>{

	let basename = "/";

	if(window.location.protocol === 'file:'){
		basename = "/android_asset/index.html";
	}

	

	const Private = ({children}) =>{

		// const {settings,globals,pageTitleUpdate} = useContext(GlobalContext);
	
		const {authenticated} = useContext(AuthContext);
		
		const location = useLocation();

		
		/*setTimeout(()=>{
			if(document.querySelector('.wrapper .footer .headerArea')){
			
				let prePageTitle = document.querySelector('.wrapper .footer .headerArea').innerHTML;
				
				if(document.querySelector('.footerareapre .headerArea'))
				document.querySelector('.footerareapre .headerArea').innerHTML = prePageTitle;
				
				console.log(prePageTitle);
				// pageTitleUpdate(prePageTitle);
			}
		},2000);
*/

		document.querySelector('html').setAttribute('route',location.pathname);
		



		if(!authenticated){
			return <Navigate to="/" />;
		}
		return children;
	}	

	useEffect(()=>{
		document.querySelector('html').classList.remove('initializing');
	},[]);

	return (
		<>	
			<BrowserRouter basename={basename}>			
				<GlobalProvider>
						<AuthProvider>
							<Routes>
								<Route exact path="/" element={<SplashPage />} />
						        
						        <Route path="login" element={<LoginPage/>} />
						        
								<Route path="dashboard" element={<Private><DashboardPage /></Private>}>				          				        
							        <Route path="home" element={<DashboardHomePage/>} />
							        <Route path="bots" element={<DashboardBotsPage/>} />
							        <Route path="exchanges" element={<DashboardExchangesPage/>} />
							        <Route path="orders" element={<DashboardOrdersPage/>} />
							        <Route path="notifications" element={<DashboardNotificationsPage/>} />
									<Route path="*" element={<DashboardNotFoundPage/>} />
						        </Route>

								<Route path="*" element={<NotFoundPage />} />
							</Routes>
						</AuthProvider>
				</GlobalProvider>
			</BrowserRouter>			
		</>
	);
};

export default AppRoutes;