import React, {useState,  useEffect,useRef} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import style from './style.css';


const Options = (props) => {

	const inputRef = useRef(null);

	const baseelement = props.baseelement ?? "body";

	
	// const {logout,user,walletCurrent,location} = useContext(AuthContext);

	const [open,openUpdate] = useState(false);

	useEffect(() => {		

		let areatop = props.areatop;

		if(props.open !== false){
			openUpdate(true);
			// document.querySelector('body').classList.add('optionactive');
				
			let h1 = inputRef.current.querySelector('.options_area').offsetHeight;
			// let h1 = document.querySelector('body  .options_area').offsetHeight;

			if(document.querySelector(baseelement)){
				document.querySelector(baseelement).style['transform'] = 'translateY('+(h1)+'px)';
				document.querySelector(baseelement).style['transition'] = '600ms';
			}

			//console.log(inputRef);

			// document.querySelector('.options').style['transform'] = 'translateX(0%) translateY(-'+(h1)+'px)';
			inputRef.current.style['transform'] = 'translateX(0%) translateY(-'+(h1)+'px)';
			
			// inputRef.current.style['opacity'] = '1';
			/*let options_areaList = document.querySelectorAll('.options .options_area');
			for (var i = options_areaList.length - 1; i >= 0; i--) {
				options_areaList[i].style['transform'] = 'translateX(-50%) translateY(-'+(areatop*2)+'%)';
			}
			*/
		}

		// body.optionactive [id="root"]

	},[props.open]);


	useEffect(() => {
		if(props.close !== false){
			openUpdate(false);
			document.querySelector('body').classList.remove('optionactive');

			closeOption();
		}
	},[props.close]);


	const closeHandle = () =>{

		if(typeof props.onClose !== 'undefined')
		props.onClose();

		if(typeof props.link?.actionOnClose !== 'undefined')
		props.link.actionOnClose();
		
		
		inputRef.current.classList.remove('active');
		openUpdate(false);
		document.querySelector('body').classList.remove('optionactive');

		closeOption();
	}


	const closeOption = () =>{

		// let h1 = document.querySelector('body  .options_area').offsetHeight;
		let h1 = inputRef.current.querySelector('.options_area').offsetHeight;

		// if(document.querySelector(baseelement))
		document.querySelector(baseelement).style['transform'] = 'translateY(-'+0+'px)';
		document.querySelector(baseelement).style['transition'] = '600ms';

		inputRef.current.style['transform'] = 'translateX(0%) translateY(-'+(h1*3)+'px)';
		

		// if(document.querySelector('body.optionactive [id="root"]'))
		/*document.querySelector(baseelement).style['transform'] = 'translateY(0%)';
		
		let options_areaList = document.querySelectorAll('.options .options_area');
		for (var i = options_areaList.length - 1; i >= 0; i--) {
			options_areaList[i].style['transform'] = 'translateX(-50%) translateY(-100%)';
		}*/
	}

	return (
		<div className={props.className+" options "+(open?'active':'')+" "+ (props.noscroll?'noscroll':'') } ref={inputRef} style={style} >

			<div className="options_bg"></div>
			<div className={"options_area "+(props.areaclass??'')}>
				<div className="options_area_top">
					<div className="line mobile-nobreak h100">
						<div className="column middle left">

							{props.children}
							
						</div>
					</div>
				</div>
				<div className="options_area_bottom">
					
					<div className="line mobile-nobreak">
						<div className="column middle center">

							<button className={"button -link  " } onClick={closeHandle}  >
				    			<FontAwesomeIcon icon={faTimes} /> 
				    		</button>
							
						</div>
					</div>

				</div>
			</div>

		</div>
	);
};


export default Options;