
import {useLocation} from "react-router-dom";


const NotFound = () => {


	const location = useLocation();

	return (
		<div className="line">
			<div className="column middle">
				<h1>404</h1>
				<p>Página não encontrada</p>
				<span><strong>{location.pathname}</strong></span>
			</div>
		</div>

	);
};


export default NotFound;