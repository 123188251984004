import React, {useEffect,useRef,useState,useContext,useLayoutEffect} from "react";

import {GlobalContext} from "../../../../../contexts/global";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave,faPlay,faStop,faThumbsUp,faThumbsDown,faSearch,faToggleOn,faChevronUp,faChevronDown,faBars,faBuildingColumns,faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

import QuestionInline from "../../../../../components/questioninline";

import Dialog from '@mui/material/Dialog';
// import Options from "../../../../../components/options/index.jsx";

import BotStore from "../../botstore/index.jsx";

import {strategyList,strategyGet,strategyPut,strategyPost,strategyDelete,strategyClone} from "../../../../../services/apiStrategy";

import {md5} from "../../../../../libs/md5.js";


import ProgressBar from "../../../../../components/ProgressBar";
 


// apiStrategy.js
// import "./style.css";

const Component = ({fields,fieldsUpdate,listBots, setListBots,currentKeyUpdate,openUpdate,reloadCurrentBot}) =>{

	const {settings,globals} = useContext(GlobalContext);

	const inputRef = useRef(null);
 	
 	// let idcomp = (new Date()).getTime();



 	const moveUpHandle = (key) => {

	  	key = Number(key);

	  	if(key === 0) return;

	  	let itemUp = JSON.stringify(listBots[key-1]);
	  	let itemCurrent = JSON.stringify(listBots[key]);

	  	listBots[key-1] = JSON.parse(itemCurrent);
	  	listBots[key] = JSON.parse(itemUp);

	  	
	  	setListBots([...listBots]);

	  	checkStrategyHandle(listBots);

	  	fields.fields.lastprice.progress = [];
	  	progressbarVisibleUpdate(false);

	  };

	  const moveDownHandle = (key) => {
	  	
	  	key = Number(key);
	  	if( key === listBots.length-1 ) return;
	  	

	  	let itemUp = JSON.stringify(listBots[key+1]);
	  	let itemCurrent = JSON.stringify(listBots[key]);

	  	listBots[key+1] = JSON.parse(itemCurrent);
	  	listBots[key] = JSON.parse(itemUp);

	  	
	  	setListBots([...listBots]);

	  	checkStrategyHandle(listBots);

	  	fields.fields.lastprice.progress = [];
	  	progressbarVisibleUpdate(false);
	  };


	  const cloneRuleHandle = key => {

	  	let listBots2 = JSON.stringify(listBots);
	  	listBots2 = JSON.parse(listBots2);

	  	let item = JSON.stringify(listBots2[key]);
	  	item = JSON.parse(item);

	  	let nextIndex = 0;
	  	for(let key2 in listBots2){	  		
	  		if( listBots2[key2].index > nextIndex ) nextIndex = Number(listBots2[key2].index);
	  	}	  	
	  	item.index = nextIndex+1;


	  	listBots2.push(item);

	  	// console.log('==',listBots2);

	  	// listBots2[listBots2.length] = item;	  	
	  	setListBots(JSON.parse(JSON.stringify(listBots2)));
	 	
	  	checkStrategyHandle(JSON.parse(JSON.stringify(listBots2)));

	  	if(typeof fields?.fields?.lastprice?.progress !== 'undefined')
	  	fields.fields.lastprice.progress = [];
	  	progressbarVisibleUpdate(false);
	  };

	 
	  const addRuleHandle = key => {

	  	// console.log(listBots);

	  	let listBots2 = JSON.stringify(listBots);
	  	listBots2 = JSON.parse(listBots2);

	  	// let item = listBots2[Object.keys(listBots2).length-1];

	  	let item = {
	  		// "index": 0,
	  		"candle": 0,
		    "candle2": 0,
		    "check": "true",
		    "condition": "",
		    "indicator": "",
		    "indicator2": "",
		    "operator": "",
		    "order": "",
		    "firstoccurrence": true,
		    "orderoco": true,
		    "typeorder":"market",
			"limitvalue":"",
			"modeorder" : "order_normal",
			"config": {},
			"config2": {},
			"porcentgain":"",
			"porcentloss":"",
	  	};

	  	let nextIndex = 0;
	  	for(let key2 in listBots2){	  		
	  		if( listBots2[key2].index > nextIndex ) nextIndex = Number(listBots2[key2].index);
	  	}	  	
	  	item.index = nextIndex+1;


	  	listBots2[Object.keys(listBots2).length] = item;	  

	  	listBots2 = JSON.parse(JSON.stringify(listBots2));

	  	/*let listBots2New = [];
	  	for(key in listBots2){
	  		listBots2New[key] = listBots2[key];
	  	}*/
	  	
	  	// console.log('==',listBots2New);

	  	setListBots([...listBots2]);
	  	// setListBots(listBots2);

	  	openRuleHandle(Object.keys(listBots2).length-1);
	 	
	 	checkStrategyHandle(listBots2);

	 	if(typeof fields?.fields?.lastprice?.progress !== 'undefined')
	 	fields.fields.lastprice.progress = [];
	 	progressbarVisibleUpdate(false);

	  };


	   const cleanRuleHandle = key => {

	  	// let listBots2 = JSON.stringify(listBots);
	  	// listBots2 = JSON.parse(listBots2);

	  	// let item = listBots2[key];
	  	// listBots2[listBots2.length] = item;	  	
	  	setListBots([]);
	 	
	 	checkStrategyHandle([]);

	 	if(typeof fields?.fields?.lastprice?.progress !== 'undefined')
	 	fields.fields.lastprice.progress = [];
	 	progressbarVisibleUpdate(false);

	  };




	  

	  const openRuleHandle = key => {

	   currentKeyUpdate(key);
	   openUpdate(true);
	   // onCloseUpdate(value);


	  };

	  const deleteRuleHandle = key => {

	  	let listBots2 = JSON.stringify(listBots);
	  	listBots2 = JSON.parse(listBots2);

	  	delete listBots2[key];
	  	listBots2 = listBots2.filter(e => e);
	  

	  	setListBots(listBots2);



	  	fields.fields.rulesbots = JSON.stringify(listBots2);


		fieldsUpdate({...fields});

		checkStrategyHandle(listBots2);

		if(typeof fields?.fields?.lastprice?.progress !== 'undefined')
		fields.fields.lastprice.progress = [];
		progressbarVisibleUpdate(false);

/*

		let index = 0;
		for(let key2 in fields.fields?.lastprice?.progress){
			for(let key3 in fields.fields?.lastprice?.progress[key2]){		
				if(Number(key) === Number(index)){
					delete fields.fields?.lastprice?.progress[key2][key3];
				}
				index++;
			}
		}
		*/
	

	  };

	  	

	  	let [storeMode, storeModeUpdate] = useState(null);
		let [openStore, openStoreUpdate] = useState(false);
		  const openStoreHandle = key => {		  	
			   // console.log('--',fields);
			   openStoreUpdate(true);
			   storeModeUpdate(fields);

		  };
		  const storeCloseHandle = () => {
		  		
		  		reloadCurrentBot();
		  		// checkStrategyHandle();
		  		openStoreUpdate(false);

		  		if(typeof fields?.fields?.lastprice?.progress !== 'undefined')
		  		fields.fields.lastprice.progress = [];
		  		progressbarVisibleUpdate(false);
		  };


		  let [checkStrategy, checkStrategyUpdate] = useState(false);
		  const checkStrategyHandle = async (listBotsRet) => {

		  	if( listBotsRet.length < 1){
		  		checkStrategyUpdate({});
		  		return false;
		  	}

		  	let result = await strategyList(window.username);

		  	let {status,body} = result.data;

		  	for(let key in body){		  	
		  		body[key].fields = JSON.parse(body[key].fields);
		  	
		  		if( body[key].fields.hash === md5(JSON.stringify(listBotsRet)) ){		  			
		  			checkStrategyUpdate(body[key]);
		  			return;
		  		}
		  	}
		  	
		  	checkStrategyUpdate(false);
			
			return true;
		  }

		  const saveStrategyHandle = async () => {

		  		// console.log(fields.fields.backtest_porcentgain);
		  		// return;

		  		let input = window.prompt("Insira um nome para esta estratégia");
		  		if(input == null) return;
				
				let description = window.prompt("Insira a descrição para esta estratégia");
		  		if(description == null) return;

		  		// console.log(fields);

		  		let data = {
		  			name:input,
		  			description:description,
		  			fields:{
		  				botid:fields._id,
		  				rules:JSON.stringify(listBots),
		  				hash:md5(JSON.stringify(listBots)),
		  				backtest:JSON.stringify({
		  					porcentgain:fields.fields.backtest_porcentgain,
		  					porcentloss:fields.fields.backtest_porcentloss
		  				}),
		  				createtime:(new Date()).getTime(),
		  			}
		  		};
		  		
		  		let result = await strategyPost(window.username,data);

		  		let status = result.data.status;
		  		if(status === 'success'){
		  			alert('Salvo com sucesso');
		  			checkStrategyHandle(listBots);
		  			return;
		  		}else if(status === 'name-already-exists'){
		  			alert('Esta nome já existe');
				}
				
				return false;
		  };


		  let [progressbarVisible, progressbarVisibleUpdate] = useState(false);
		  let [progressbarData, progressbarDataUpdate] = useState([]);
		  let [progressbarOrder, progressbarOrderUpdate] = useState([]);
		  const progressHandle = ()=>{
				
			// console.log('--',globals.backtest[fields.name]?.fields);

			  	let newProgress = [];
			  	let newProgressOrder = [];
			  	for(let key in globals.backtest[fields.name]?.fields?.lastprice?.progress){


					// console.log( '==', globals.backtest[fields.name]?.fields?.lastprice?.progress[key] );
					
					for(let key2 in globals.backtest[fields.name]?.fields?.lastprice?.progress[key] ){
				  		newProgress[newProgress.length] = globals.backtest[fields.name]?.fields?.lastprice?.progress[key][key2];
				  		newProgressOrder[newProgressOrder.length] = key2;
				  	} 
				}

				
				
			  	progressbarDataUpdate([...newProgress]);
			  	progressbarOrderUpdate([...newProgressOrder]);

				// console.log(globals.backtest[fields.name]?.fields?.lastprice.progress);
				
			  	progressbarVisibleUpdate(true);
		  }


		  useEffect(()=>{
		  	
		  	progressHandle();

		  },[]);

		  useEffect(()=>{

		  	
		  	// progressHandle();

		  	checkStrategyHandle(listBots);
		  	

		  },[listBots]);


	return (
		<div ref={inputRef} className="strategyinitial" >	

			<Dialog onClose={storeCloseHandle} open={openStore}  >
				<BotStore storeCloseHandle={storeCloseHandle} storeMode={storeMode} choseAction={reloadCurrentBot} fieldsUpdate={fieldsUpdate} />
			</Dialog>
				


			<fieldset className=" nomargin">
				<legend className="margin-bottom-10 center"></legend>


				<div className="line mobile-nobreak tablet-nobreak margin-bottom-10">
					<div className="column col0 middle left ">
						

						<QuestionInline>
							<div question="">
								<button type="button" title="Apagar regras" className={"link -primary padding"} onClick={(e)=>{  }} >						
									<FontAwesomeIcon icon={faTrash} />
								</button>
							</div>
							<div alternatives="">
								<div response="yes">
									<button type="button" className={"link -primary padding"} onClick={(e)=>{ cleanRuleHandle() }} >						
										<FontAwesomeIcon icon={faCheck} />
									</button>
									
								</div>			
							</div>
						</QuestionInline>

					</div>
					<div className="column col0 middle left ">
						<button type="button" title="Modelos" className={"link -primary padding"} onClick={(e)=>{ openStoreHandle(); }} >						
							<FontAwesomeIcon icon={faListAlt} />
						</button>
					</div>
					<div className="column col0 middle left ">
						<button type="button" title="Salvar Estratégia" className={"link  padding "+(checkStrategy?'-secondary disabled':'-primary')} onClick={(e)=>{ saveStrategyHandle(); }} >						
							<FontAwesomeIcon icon={faSave} />
						</button>
					</div>
					<div className="column middle right padding-right-10">
						<button type="button" className={"button -secondary padding"} onClick={(e)=>{ addRuleHandle(e) }} >						
							Adicionar regra
						</button>
					</div>
				</div>

			</fieldset>

			<br/>

			<fieldset className="noborder nomargin">
				<legend className="margin-bottom-10 center w100">
					<div className={"line mobile-nobreak tablet-nobreak " }>
						<div className={"column left" }>
							{ Object.keys(listBots).length} regra(s)
						</div>
						<div className={"column col0 right" }>
							{checkStrategy.name}
						</div>
					</div>
				</legend>

				<ul className="rules">
					{Object.keys(listBots).map((key,index) => {
			        	return (
			        		<li key={key} statusorder={listBots[key].order}>
			        			<div className={"line mobile-nobreak tablet-nobreak " }>

									<div className={"column col2 middle center icon-enabled "+(listBots[key].check==='true'?'enabled':'disabled')}>
										<FontAwesomeIcon icon={faToggleOn} />
									</div>
			        				<div className={"column middle left "+(listBots[key].check==='true'?'':'disabled')}>

										<label>{parseInt(listBots[key].index)} # <cite>{listBots[key].indicator}</cite> </label>
					
									</div>
									<div className={"column col2 middle center "+(listBots[key].check==='true'?'':'disabled')}>
										<label className={"order "+listBots[key].order}>{listBots[key].order}</label>
									</div>

									<div className={"column col0 middle left "}>

										<div type="button" className={"link -secondary padding ulist"} >						
											<FontAwesomeIcon icon={faBars} />
											<ul>																	
												<li>
													<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{ moveUpHandle(key) }} >						
														<FontAwesomeIcon icon={faChevronUp} /> <span>Mover para cima</span>
													</button>
												</li>
												<li>
													<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{ moveDownHandle(key) }} >						
														<FontAwesomeIcon icon={faChevronDown} /> <span>Mover para baixo</span>
													</button>
												</li>
												<li>
													<QuestionInline className="w100">
														<div question="" className="w100">
															<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{  }} >						
																<FontAwesomeIcon icon={faClone} /> <span>Clonar</span>
															</button>
														</div>
														<div alternatives="">
															<div response="yes" className="w100">
																<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{ cloneRuleHandle(key) }} >						
																	<FontAwesomeIcon icon={faCheck} /> <span>Clonar</span>
																</button>
																
															</div>			
														</div>
													</QuestionInline>
												</li>
												<li>
													
													<QuestionInline className="w100">
														<div question="" className="w100">
															<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{  }} >						
																<FontAwesomeIcon icon={faTrash} /> <span>Remover</span>
															</button>
														</div>
														<div alternatives="">
															<div response="yes" className="w100">
																<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{ deleteRuleHandle(key) }} >						
																	<FontAwesomeIcon icon={faCheck} /> <span>Remover</span>
																</button>
																
															</div>			
														</div>
													</QuestionInline>
														
												</li>
												<li>
													<button type="button" className={"link left -secondary padding w100"} onClick={(e)=>{ openRuleHandle(key) }} >						
														<FontAwesomeIcon icon={faPenSquare} /> <span>Editar</span>
													</button>
												</li>
											</ul>
										</div>
					
									</div>
									
									
									
								</div>		

								<div className="line mobile-nobreak tablet-nobreak padding-h-10 ">
									<div className="column  ">
									
									
									{(progressbarVisible === true && (
										<>
											
											<ProgressBar  percentage={progressbarData[key]} style={{"width":"100%","height":"2px"}} className={progressbarOrder[key]}/>
											
										</>
									))}

									
		        		
									
							    	</div>
							    </div> 


							    { ( index+1 < Object.keys(listBots).length &&  (listBots[key].order === 'buy' || listBots[key].order === 'sell')) && (
							    	<div className="separateorder"><label>OR</label><span className="line"></span></div>
							    )}

			        		</li>
			        	)
			        })}
				</ul>

				
				

			</fieldset>

		</div>
	)
}

export default Component;
