import { env } from '../.env.js';
import axios from "axios";

export const api = axios.create({
	baseURL: env.AchoordURL
});


export const strategyList = async (username) => {	
	return await api.get("/strategies/list/?username="+username);
}

export const strategyGet = async (username,strategyid) => {
	return await api.get("/strategies/get/?username="+username+"&id="+strategyid);
}

export const strategyGetByBot = async (username,botid) => {
	return await api.get("/strategies/get/?username="+username+"&id="+botid);
}

export const strategyPut = async (username,strategyid,data) => {	
	return await api.put("/strategies/put/?username="+username+"&id="+strategyid,data);	
}

export const strategyPost = async (username,data) => {
	return await api.post("/strategies/post/?username="+username,data);	
}

export const strategyDelete = async (username,strategyid) => {
	return await api.delete("/strategies/delete/?username="+username+"&id="+strategyid);
}



export const strategyClone = async (username,strategyid) => {

	let response = await api.get("/strategies/get/?username="+username+"&id="+strategyid);
	let posfix = (new Date()).toLocaleTimeString();

	let data = response.data.content;
	data.name = data.name+' Clone '+posfix;
	data.title = data.title+' Clone '+posfix;

	

	delete data.id;
	return await api.post("/strategies/post/?username="+username,data);


}

