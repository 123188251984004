import React,{useEffect,useState,useRef} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import Switch from '@mui/material/Switch';

import {indicators} from "../../../../../helpers/trades/indicators";

import FieldConfig from "./FieldConfig/index.jsx";

import "./style.css";

const Rules = (props) => {

	const inputRef = useRef(null);

	const [candle2_visible,candle2_visibleUpdate] = useState(true);
	const [period2_visible,period2_visibleUpdate] = useState(true);

	const [fieldsLoaded,fieldsLoadedUpdate] = useState(false);
	let [rule, ruleUpdate] = useState({
	   "candle": 0,
	    "candle2": 0,
	    "check": "true",
	    "condition": "",
	    "indicator": "",
	    "indicator2": "",
	    "operator": "",
	    "order": "",
	    "firstoccurrence": true,
	    "orderoco": true,
	    "typeorder":"market",
		"limitvalue":"",
		"modeorder" : "order_normal",
		"config": {},
		"config2": {},
		"porcentgain":"",
		"porcentloss":"",
	});

	useEffect(()=>{


		let listBots2 = JSON.stringify(props.listBots[props.currentKey]);


		listBots2 = JSON.parse(listBots2);
		

		if( listBots2.check === 'true' ) listBots2.check = true;
		if( listBots2.check === 'false' ) listBots2.check = false;
		if( listBots2.check === 'undefined' ) listBots2.check = true;

		// console.log(listBots2);


		ruleUpdate(listBots2);

		setTimeout(()=>{
			fieldsLoadedUpdate(true);
		},500);

	},[props.currentKey,props.listBots[props.currentKey]]);


	let indicatorList = JSON.stringify(indicators);;
	indicatorList = JSON.parse(indicatorList);
	delete indicatorList['value'];
	
	// let indicatorList2 = {};
	let indicatorList2 = JSON.stringify(indicators);
	indicatorList2 = JSON.parse(indicatorList2);
	/*for(let key in indicatorList){
		indicatorList2[key] = indicatorList[key];
	}*/

	// delete indicatorList['-group5'];
	// setTimeout(()=>{
		
	// },500);


	useEffect(()=>{
		if(fieldsLoaded === true)
		inputRef.current.querySelector('.changebtn').classList.add('mark');

		if(rule.indicator2 === 'value'){
			candle2_visibleUpdate(false);
			period2_visibleUpdate(false);
		}else{
			candle2_visibleUpdate(true);
			period2_visibleUpdate(true);
		}

	},[rule]);


	const saveRuleHandle = () =>{
		
		if(typeof rule.firstoccurrence === 'undefined') rule.firstoccurrence = true;
		
		rule.check = String(rule.check);
		props.listBots[props.currentKey] = rule;
			
		

		let list = JSON.stringify(props.listBots);


		props.setListBots(JSON.parse(list));
		fieldsLoadedUpdate(false);
		props.handleClose();
	}


	const onSubmitRuleHandle = (e) =>{
		e.preventDefault();
		// console.log(e);
		saveRuleHandle();
	}


	return (
		<form className="h100" ref={inputRef} onSubmit={onSubmitRuleHandle}>			
			<div className="wrapper --option-theme">
				<div className="header dialog-header">


										
					<div className="line mobile-nobreak">
						<div className="column">

							<h2 className="dialog-title">Rule {parseInt(props.currentKey)+1}</h2>
							
						</div>
						<div className="column col0">
							<button type="button" className={"link padding"} onClick={props.handleClose} >						
								<FontAwesomeIcon icon={faTimes} />
							</button>
						</div>
					</div>

				</div>
				<div className="main " >				
					<div className="dialog-main " >				
					
						
						<div className="line mobile-nobreak ">
							<div className="column col0 right ">																	
								<Switch checked={(rule.check)?true:false} onChange={(e)=>{ rule.check=e.target.checked;  ruleUpdate({...rule}) }} name="jason" />
							</div>						
							<div className="column  left ">																	
								<label>{(rule.check)?"Ativado":"Desativado"}</label>		
							</div>						
						</div>						

						<div className="padding-h-10">
							<label >1º passo: <strong>Objeto</strong></label>
						</div>

						<div className="wrapperRuleField">
							<div className="line mobile-nobreak padding-h-10 padding-top-10">
								
								<div className="column left padding-right ">
									
									<label >Indicador</label>			
									<select name="indicator" required className="select w100" onChange={(e) => { rule.indicator = e.target.value; ruleUpdate({...rule})  }}  value={rule.indicator}>					
										<option value="" disabled>Indicadores</option>	

										{Object.keys(indicatorList).map((key,index)=>{
											return (
												<React.Fragment key={index}>											
													<option key={index}  value={key}>{indicatorList[key].title}</option>										
												</React.Fragment>
											)

										})}				
										
									</select>

								</div>
								<div className="column left col3 ">
								    <label >Candle</label>
									<input className="w100" type="number" name="candle" min="0" onChange={(e) => { rule.candle = e.target.value; ruleUpdate({...rule})  }}  value={rule.candle}  />			    								
								</div>
							</div>

							<div className="line mobile-nobreak padding-h-10">
							
								
							
								<div className="column left top ">

									<label >Operador</label>
									<select required className="select w100" name="operator" onChange={(e) => { rule.operator = e.target.value; ruleUpdate({...rule})  }}  value={rule.operator}  >
										
										<option value="" disabled>Operador</option>
										<option value="==">==</option>
										<option value="!=">!=</option>
										<option value="===">===</option>
										<option value="!==">!==</option>
										<option value="<="> &lt;= </option>
										<option value=">="> &gt;= </option>
										<option value="<"> &lt; </option>
										<option value=">"> &gt; </option>
									</select>


								</div>
							</div>

							<div className="line mobile-nobreak padding-h-10 margin-top-10">
								<div className="column left ">
									<ul className="fieldconfig">
										{ (typeof indicatorList[rule.indicator] !== 'undefined') && (
											<>
												{Object.keys(indicatorList[rule.indicator].config).map((key,index)=>{
													return (
														<li key={key}>
															<FieldConfig type={key} default={indicatorList[rule.indicator].config[key]} rule={rule} ruleUpdate={ruleUpdate} />
														</li>
													)
												})}
											</>
										)}
									</ul>
								</div>
							</div>

						</div>


						<div className="padding-h-10">
							<label >2º passo: <strong>Se</strong></label>
						</div>

						<div className="wrapperRuleField2">
							<div className="line mobile-nobreak padding-h-10 padding-top-10">
								<div className="column left  ">

									
									<label >Indicador</label>
									<select className="select w100" required name="indicator2" onChange={(e) => { rule.indicator2 = e.target.value; ruleUpdate({...rule})  }}   value={rule.indicator2} >					
										<option value="" disabled>Indicadores</option>

									
										{Object.keys(indicatorList2).map((key,index)=>{
											return (
												<React.Fragment key={index}>
													<option key={index}  value={key}>{indicatorList2[key].title}</option>										
												</React.Fragment>
											)

										})}		

										
									</select>

								</div>
								
								{(rule.indicator2 !== 'value') &&(
								<div className="column left col3 padding-left">
										<>									
										    <label >Candle</label>
											<input type="number" className="w100" name="candle2" min="0"  onChange={(e) => { rule.candle2 = e.target.value; ruleUpdate({...rule})  }}  value={rule.candle2}  />			    																			
										</>									
								</div>
								)}

							</div>
							
							<div className="line mobile-nobreak padding-h-10">

								{rule.indicator2 &&(
									<div className="column left col3">			
										{(rule.indicator2 === 'value') &&(
											<>
											    <label >Condição</label>
												<input type="number" className="w100" name="condition"  onChange={(e) => { rule.condition = e.target.value; ruleUpdate({...rule})  }}  value={rule.condition}  />
											</>							
										)}

										{(rule.indicator2 !== 'value') &&(
										<ul className="fieldconfig2">	
											{ (typeof indicatorList2[rule.indicator2] !== 'undefined') && (
												<>
													{Object.keys(indicatorList2[rule.indicator2].config).map((key,index)=>{
														return (
															<li key={key}>
																<FieldConfig type={key} default={indicatorList2[rule.indicator2].config[key]} rule={rule} ruleUpdate={ruleUpdate} />
															</li>
														)
													})}
												</>
											)}
										</ul>
										)}

									</div>
								)}


								
									
										
							

							</div>



						</div>

						<
						div className="padding-h-10">
							<label >3º passo: <strong>Então</strong></label>
						</div>

						<div className="line  mobile-nobreak padding-top-10 padding-bottom-10">					
							<div className="column left  padding-h-10 " >
								<select className="select w100" required name="order" onChange={(e) => { rule.order = e.target.value; ruleUpdate({...rule})  }}  value={rule.order}  >
									<option value="" disabled>--- Ordem ---</option>
									<option value="buy">Comprar (long)</option>
									<option value="sell">Vender (short)</option>
									
									<option value="" disabled>--- Next Rule ---</option>
									<option value="and">AND</option>
									<option value="or">OR</option>
								</select>
							</div>
						</div>


						{ (rule.order === 'buy' || rule.order === 'sell') && (
							<>
							{/*<div className="padding-h-10">
								<label >4º passo: <strong>Ordem</strong></label>
							</div>*/}
							
							<hr/>

							<div className="line mobile-nobreak tablet-nobreak padding-h-10 padding-bottom-10">
								<div className="column col6 middle left padding-right">
									<label className="padding-h-10">Tipo de ordem</label>													
									<select className="select w100" name="typeorder" required onChange={(e)=>{ rule.typeorder=e.target.value; ruleUpdate({...rule}) }} value={rule.typeorder} >						
										<option value="" disabled>Tipo de Ordem</option>
										<option value="market" >Market</option>
										<option value="limit">Limit</option>
										<option value="limitmarket">Limit on last price</option>
									</select>	
								</div>
								<div className={"column middle left "+(rule.typeorder==='limit'?'':'disabled') } >

									<div className="input-float flatmodel" >
										<input type="number" name="limitvalue" max="0"  onChange={(e) => { rule.limitvalue = e.target.value; ruleUpdate({...rule})  }}  value={rule.limitvalue}  />			    
									    <label >Valor do limite</label>
									</div>

								</div>
							</div>

							</>
						)}

						{ (rule.order === 'buy' || rule.order === 'sell') && (
							<>
								<div className="padding-h-20 padding-top-10 padding-bottom-10 bg_b">
									<label>Modo de ordem: </label>
									<div className="line  mobile-nobreak  ">													
										<div className="column left ">
											<label className="radio   ">
												<input 
													type="radio" 
													name="modeorder"
													checked={ rule.modeorder==='order_normal' } 
													onChange={(e) => {  rule.modeorder = 'order_normal'; ruleUpdate({...rule})  }}
																							
												/>
												<span>Normal</span>
											</label>
										</div>
										<div className="column left ">
											<label className="radio   ">
												<input 
													type="radio" 
													name="modeorder"
													checked={ rule.modeorder==='order_oco' } 
													onChange={(e) => {  rule.modeorder = 'order_oco'; ruleUpdate({...rule})  }}
																							
												/>
												<span>OCO</span>
											</label>
										</div>
										<div className="column left ">
											<label className="radio   ">
												<input 
													type="radio" 
													name="modeorder"
													checked={ rule.modeorder==='order_finish' } 
													onChange={(e) => {  rule.modeorder = 'order_finish'; ruleUpdate({...rule})  }}
																							
												/>
												<span>Encerrar aberta</span>
											</label>
										</div>
									</div>
								</div>
								
						
								{ (rule.modeorder === 'order_oco' ) && (
									<div className="padding-h-20 padding-bottom-10 ">
										<div className="line  mobile-nobreak  ">
											<div className="column middle col6 right padding-right">
													<div className="line mobile-nobreak tablet-nobreak ">
														<div className="column middle col6 right padding-right">
															<div className="input-float flatmodel">
															      <input type="text" name="porcentgain" required  value={rule.porcentgain} onChange={(e)=>{ rule.porcentgain=e.target.value; ruleUpdate({...rule}) }} />
															      <label >Porcent Gain</label>
															</div>
														</div>
														<div className="column middle right ">
															<div className="input-float flatmodel">
															      <input type="text" name="porcentloss" required  value={rule.porcentloss} onChange={(e)=>{ rule.porcentloss=e.target.value; ruleUpdate({...rule}) }} />
															      <label >Porcent Loss</label>
															</div>
														</div>
													</div>


											</div>
										</div>
									</div>
								)}

								<div className="padding-h-20 padding-top-10 padding-bottom-10 bg_a">							
									<label className="checkbox   ">
										<input 
											type="checkbox" 
											name="firstoccurrence"
											checked={ rule.firstoccurrence===false?false:true } 
											onChange={(e) => {  rule.firstoccurrence = e.target.checked; ruleUpdate({...rule})  }}									
										/>
										<span>Run on Only First Occurrence</span>
									</label>							
								</div>
							</>
						)}
					</div>
				</div>
				<div className="footer">

					<hr/>

					<div className="line mobile-nobreak ">
						<div className="column left col6 padding">
							
						</div>
						<div className="column left col6 padding">
							<button type="button" className={"changebtn button -secondary w100"} onClick={(e)=>{ saveRuleHandle() }} >						
								Alterar
							</button>
						</div>
					</div>
				
				</div>
			</div>
		</form>
	);
};


export default Rules;
