import {botBacktest,botPut} from "../../../../../services/apiBots";
import {sequentialArray} from "../../../../../libs/sequentialArray.jsx";

let RunBacktest = (props) => {

	window.stopRunBackTest = 'false';

	window.balanceSimulate = props.balanceSimulate;

	props.setloadingBacktestGeral({"status":'true'});

	props.listDays.map((el)=>{
		el.status = '';
		el.loading = 'false';
		el.balance_initial = 0;
		el.amount_initial = 0;
		el.gain_count = 0;
		el.loss_count = 0;
		el.amount_roi = 0;
		el.balance_final = 0;
		return el;
	});
	props.listDaysUpdate(JSON.parse(JSON.stringify(props.listDays)));


	sequentialArray({			
		'array':props.listDays,
		'callback':(index,item,next) =>{

			if( window.stopRunBackTest === 'true' ){
				/*props.listDays.map((el)=>{
					el.status = '';
					el.loading = 'false';
					el.balance_initial = 0;
					el.amount_initial = 0;
					el.gain_count = 0;
					el.loss_count = 0;
					el.amount_roi = 0;
					el.balance_final = 0;
					return el;
				});
				props.listDaysUpdate(JSON.parse(JSON.stringify(props.listDays)));*/
				props.setloadingBacktestGeral({"status":'false'});
				return;
			}

			props.setloadingBacktestGeral({"status":'true',"index":index+1});
			// console.log(index);

			props.listDays[index].loading = 'true';
			props.listDaysUpdate(JSON.parse(JSON.stringify(props.listDays)));

			// props.dayProcess = index;
			props.dayProcessUpdate(index);
			// setTimeout(()=>{

				botBacktest(window.username, props.fields.name, item.date, true,false, window.balanceSimulate).then((response)=>{
					
					let status = response.data.status;
					let data = JSON.parse(response.data.data);
					let body = data.body;

					// console.log(body);

					body.date = props.listDays[index].date;
					body.loading = props.listDays[index].loading;
					props.listDays[index] = body;
					props.listDays[index].loading = 'false';

					props.listDaysUpdate(JSON.parse(JSON.stringify(props.listDays)));

					window.balanceSimulate = body.balance_final;

					// props.balanceSimulateUpdate(body.balance_final);
					// props.balanceSimulate = body.balance_final;

					setTimeout(()=>{
						next();
					},500);

				});
			// },2000);
		},'callbackFinish':() =>{
			//console.log('finish');
			// props.setloadingBacktestGeral('false');
			props.setloadingBacktestGeral({"status":'false'});
			
			// setTimeout(()=>{
				// saveResultTest();
			// },1000);
		}
	});

}


export {RunBacktest}	