import { env } from '../.env.js';
import axios from "axios";

export const api = axios.create({
	baseURL: env.AchoordURL
});

export const requestLogon = async (fields) => {


	return await api.get("/session/signin/",{
	  headers: fields
	});

}

export const checkDevice = async (deviceid) => {

	
	// return api.get("/session/check?device="+deviceid,{
	return await api.get("/session/check?device="+deviceid,{
	  headers: {
	  	"accept": "application/json"
	  }
	});

}

export const disconnectDevice = async (deviceid) => {

	
	// return api.get("/session/check?device="+deviceid,{
	return await api.get("/session/signout?device="+deviceid,{
	  headers: {
	  	"accept": "application/json"
	  }
	});

}