import { env } from '../.env.js';
import axios from "axios";


export const api = axios.create({
	baseURL: env.AchoordURL
});



export const userGet = async (userid) => {
	// console.log(userid);
	// return await api.get("/users/api/username/"+userid+"/");
	return await api.get("/account/get/?userid="+userid);
}

/*export const requestEdit = async (botid) => {
	let collection = '0812887001668738913';
	return await api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");
	// return await api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/name:"+botid+"/");
}*/

export const userPut = async (userid,data) => {
	// let collection = '0812887001668738913';
	return await api.put("/account/put/?userid="+userid,data);
	// return await api.put("/users/api/username/"+userid+"/",data);
	// return await api.put("/users/api/id/0436200001667165384/",data);
	// https://admin.achoord.com/api/v1/accounts/wallrio/trade/p/users/api/id/0436200001667165384/
	// https://admin.achoord.com/api/v1/accounts/wallrio/trade/p/users/api/id/0436200001667165384/
}


/*export const userLogout = async (userid,data) => {
	// let collection = '0812887001668738913';
	return await api.delete("https://admin.achoord.com/api/v1/accounts/wallrio/trade/p/users/api/devices/connected/"+userid+"/");
	// return await api.put("/users/api/username/"+userid+"/",data);
	// return await api.put("/users/api/id/0436200001667165384/",data);
	// https://admin.achoord.com/api/v1/accounts/wallrio/trade/p/users/api/id/0436200001667165384/
	// https://admin.achoord.com/api/v1/accounts/wallrio/trade/p/users/api/id/0436200001667165384/
}*/
/*
export const requestPost = async (data) => {
	let collection = '0812887001668738913';
	return await api.post("/storage/api/storage/Exchanges/collections/"+collection+"/documents/",data);
}

export const requestDelete = async (botid) => {
	let collection = '0812887001668738913';
	return await api.delete("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");
}
*/

/*
export const requestClone = async (botid) => {
	let collection = '0812887001668738913';

	let response = await await api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");

	let posfix = (new Date()).toLocaleTimeString();//(new Date()).toISOString();

	let data = response.data.content;
	data.name = data.name+' Clone '+posfix;
	data.title = data.title+' Clone '+posfix;

	delete data.id;
	return await api.post("/storage/api/storage/Exchanges/collections/"+collection+"/documents/",data);

}

*/