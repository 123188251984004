import React, {useEffect,useRef} from "react";

import "./style.css";

const Component = ({children,percentage,className,style}) =>{

	const inputRef = useRef(null);
 	
 	// let idcomp = (new Date()).getTime();
/*
	useEffect(() => {

		let question = inputRef.current.querySelector('[question]');
		question.onclick = function(e){

			let alternatives = inputRef.current.querySelector('[alternatives]');
			let responseList = inputRef.current.querySelectorAll('[response]');
			
			for (var i = 0; i < responseList.length; i++) {
				responseList[i].onclick = function(){
					alternatives.classList.remove('active');
					question.classList.remove('hide');	
				}
			}

			alternatives.classList.add('active');
			question.classList.add('hide');

			setTimeout(()=>{
				alternatives.classList.remove('active');
				question.classList.remove('hide');	
			},2000);
		}

	}, []);*/



	return (
		
		<div 			
			// status={ list[key][index2].fields.target_status} 
			// idcomp={idcomp} 
			ref={inputRef} 
			className={'progressbar '+(className?className:'')}
			style={style}
		>
			<div className="wrap">
				<span style={{width:(percentage)+'%'}}>
					{children&&(
						<label>
							{children}
						</label>
					)}
				</span>
			</div>
		</div>
	
	)
}

export default Component;
