import React, {useEffect,useLayoutEffect,useRef,useState,useContext} from "react";

import {GlobalContext} from "../../../../../contexts/global";

import {stampToFormat} from "../../../../../libs/misc";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave,faPlay,faStop,faThumbsUp,faThumbsDown,faSearch,faToggleOn,faChevronUp,faChevronDown,faBars,faBuildingColumns,faTimes,faEdit,faVial,faTrash,faClone,faEraser,faListAlt,faPenSquare,faCheck} from '@fortawesome/free-solid-svg-icons'

import Dialog from '@mui/material/Dialog';
import BackTest from '../backtest';

import {typeOf} from "../../../../../libs/misc.js";

import "./style.css";

const Component = ({fields}) =>{

	const {settings,globals} = useContext(GlobalContext);
	const inputRef = useRef(null);
 
 	
	const [backtest, backtestUpdate] = useState({});


	  let [openBacktest, openBacktestUpdate] = useState(false);
	  const openBacktestHandle = key => {
		   openBacktestUpdate(true);
	  };
	  const handleBacktestClose = () => {
	  	openBacktestUpdate(false);
	  };

	useLayoutEffect(()=>{		
		if(globals.backtest[fields.name]?.fields === undefined){
			backtestUpdate({});				
			return;
		}

		backtestUpdate(globals.backtest[fields.name].fields);				
	},[globals.backtest]);
	
	const refreshBacktest = () =>{
		globals.getBacktestHandle();
	}

	return (
		<div ref={inputRef} className="backtest" >	

			<Dialog onClose={handleBacktestClose} open={openBacktest}  >
				<BackTest handleBacktestClose={handleBacktestClose} fields={fields}    ></BackTest>				
			</Dialog>

			{ ( typeOf(backtest) === 'object' && Object.keys(backtest).length < 1 ) && (							
					<div className="center" >
						<p>Nenhum test</p>
					</div>
				)}


			{ (typeof backtest.backtest_porcentgain !== 'undefined') && (
				<>

					<div className="line mobile-nobreak margin-bottom-20 header1">
						<div className="column col6 left ">
							<label>ROI</label>
							<span className={ (backtest.backtest_status) }>
								$&nbsp;
								{ backtest.backtest_status === 'loss'&&(
									<>-</>
								)}
								{Math.abs(Number(backtest.backtest_balancefinish - backtest.backtest_balanceinitial)).toFixed(2)}  ({Number(backtest.backtest_balancefinish).toFixed(2)})
							</span>
							
						</div>
						<div className="column right ">
							<label>Última atualização</label>
							<span>{ stampToFormat(new Date( Number(backtest.backtest_updatetime) ))}</span>
						</div>
					</div>


					<fieldset className=" noborder nomargin">
						<legend></legend>
						
						<div className="line mobile-nobreak tablet-nobreak header2"  >
							<div className="column top center col4 ">
								<label className="">ROI: </label><br/>
								<span className={ (backtest.backtest_status) }>
									<small>  

										{ (backtest.backtest_porcentgain - backtest.backtest_porcentloss).toFixed(2) }%

									</small>
								
									{backtest.backtest_countgain - backtest.backtest_countloss}
								</span>
							</div>
							<div className="column top center col4 ">
								<label className="-success-text">Gain: </label><br/>
								<span>
									<small>{Number(backtest.backtest_porcentgain).toFixed(2)}%</small>
									{backtest.backtest_countgain} 
								</span>
							</div>
							<div className="column top center col4 ">
								<label className="-error-text">Loss: </label><br/>
								<span>
									<small>{Number(backtest.backtest_porcentloss).toFixed(2)}%</small>
									{backtest.backtest_countloss} 
								</span>
							</div>
							{/*<div className="column top center col0">
								<button type="button" className={"button left padding w100 "+(backtest_orders_visible===true?'-primary':'-secondary')} onClick={(e)=>{ backtest_orders_visibleUpdate(!backtest_orders_visible) }} >						
									<FontAwesomeIcon icon={faSearch} /> 
								</button>
							</div>*/}
						</div>

						{ (backtest.backtest_orders) && (
							<div className="backtest_orders">
								<ul>
									{(backtest.backtest_orders).map((key,index)=>{
										
										{/*console.log(backtest.backtest_orders[index]?.backtest?.start?.dateto);*/}
										{/*console.log(backtest.backtest_orders[index]?.backtest?.finish?.status);*/}
										{/*console.log(backtest.backtest_orders[index]?.backtest?.finish?.status);*/}

										{/*backtest.backtest_orders[index]?.backtest?.finish?.status?fields.backtest_orders[index]?.backtest?.finish?.status+' finish':''*/}
										
										let status = '';

										if(backtest.backtest_orders[index]?.backtest?.finish?.status) 
											status = backtest.backtest_orders[index]?.backtest?.finish?.status+' finish';

										return (
											<li key={index} className={(backtest.backtest_orders[index]?.backtest?.start?.dateto?'start':'')+' '+status}>
												<div className="line mobile-nobreak tablet-nobreak "  >
													<div className="column middle col0 padding-right-10">
														<small className="-number" style={{"whiteSpace":"nowrap"}}>{index+1}</small>
													</div>
													<div className="column middle col0 padding-right-10">
														<span style={{"whiteSpace":"nowrap"}}>{backtest.backtest_orders[index].date}</span>
													</div>
													<div className="column col0 middle left  padding-right-10">
														<span className="-order">
															{backtest.backtest_orders[index]?.orders && (
																<>
																	{ backtest.backtest_orders[index]?.orders[0].order }
																</>
															)}
														</span>
													</div>
													<div className="column col2 middle left">

														{ backtest.backtest_orders[index]?.backtest?.start?.dateto  && (
															<FontAwesomeIcon icon={faPlay} />
														)}

														{ backtest.backtest_orders[index]?.backtest?.finish?.datefrom  && (
															<FontAwesomeIcon icon={faStop} />
														)}
													
													</div>
													<div className="column col1 middle right">
														
														{backtest.backtest_orders[index]?.backtest?.finish?.status === 'gain' && (
															<FontAwesomeIcon icon={faThumbsUp} />
														)}
														{backtest.backtest_orders[index]?.backtest?.finish?.status === 'loss' && (																
															<FontAwesomeIcon icon={faThumbsDown} />
														)}
														
													</div>
													<div className="column col0 middle right">
														{backtest.backtest_orders[index]?.backtest?.finish?.balance ? (																
															<span className="padding-left-10">
																$&nbsp;{Number(backtest.backtest_orders[index]?.backtest?.finish?.balance).toFixed(2)}
															</span>
														):(
															<span className="padding-left-10">
																{ backtest.backtest_orders[index]?.backtest?.start?.balance?(
																	<>
																		$&nbsp;{Number(backtest.backtest_orders[index]?.backtest?.start?.balance).toFixed(2)}
																	</>
																):(
																	<>
																		
																	</>
																)}
																
															</span>
														)}
														
													</div>
												</div>
											</li>
										)
									})}
								</ul>
							</div>
						)}

					</fieldset>
				</>
			)}

			<div className="line mobile-nobreak tablet-nobreak margin-top-10"  >
				<div className="column top right ">
					{/* <button type="button" className={"button -secondary w100  "} onClick={(e)=>{ refreshBacktest() }} >						
						Atualizar
					</button> */}
				</div><div className="column top right ">
					<button type="button" className={"button -secondary   "} onClick={(e)=>{ openBacktestHandle() }} >						
						Teste Manual
					</button>
				</div>
			</div>

		</div>
	)
}

export default Component;
