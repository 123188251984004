import React,{useEffect,useState,useRef} from "react";

const Component = (props) => {


	let profitMargin = 0;
	let profitMarginString = '';
	let balance_final = 0;
	let averageGeneral = 0;
	let averageGeneralPercent = 0;
	let gain_count = 0;
	let loss_count = 0;
	for(let key in props.listDays){
		gain_count += props.listDays[key].gain_count;
		loss_count += props.listDays[key].loss_count;

		averageGeneral += props.listDays[key].amount_roi;
	}

	if( props.listDays.length === 1 ){

		if(props.listDays[props.dayProcess]){
			balance_final = props.listDays[props.dayProcess].balance_final;		
		}
		if(props.listDays[props.dayProcess]){
			profitMargin = (( props.listDays[props.dayProcess].balance_final - props.listDays[0].balance_initial) / props.listDays[0].balance_initial * 100);
			if(isNaN(profitMargin)) profitMargin = 0;
			profitMarginString = profitMargin>0?'positive':(profitMargin<0?'negative':'');
		}
	}else if( props.listDays.length > 1 ){
		if(props.listDays[props.dayProcess-1]){
			balance_final = props.listDays[props.dayProcess-1].balance_final;		
		}
		if(props.listDays[props.dayProcess-1]){
			profitMargin = (( props.listDays[props.dayProcess-1].balance_final - props.listDays[0].balance_initial) / props.listDays[0].balance_initial * 100);
			if(isNaN(profitMargin)) profitMargin = 0;
			profitMarginString = profitMargin>0?'positive':(profitMargin<0?'negative':'');
		}
	}
	

	


	averageGeneral = averageGeneral / props.listDays.length;


	


	return (
		<>
			<div className=" line margin-bottom-20 margin-top-10" visible={ props.listDays.length < 1?'false':'true' }>
				<div className="column left top tableBacktestTotal" >

					

					{ props.listDays.length !== 0 && (
						<>

							<div className="line barControl mobile-nobreak tablet-nobreak">							
								<div className="column col6 center padding-h">
									
									<span id="backtest_balance" className="button -secondary w100" >
										<strong  className="display:block">Saldo inicial</strong>
										{/*<span >$ { (props.totalBacktest.balanceStart).toFixed(3) }</span>*/}
										
										{/*{window.balance && (*/}
											<span >$ { (props.listDays[0].balance_initial).toFixed(3) }</span>
										{/*)}*/}
									</span>
									
								</div>					
								<div className="column  center padding-h">
									
									<span id="backtest_test_balance_estimate" className="button -secondary w100" >
										<strong  className="display:block">Saldo final</strong>
										
										

										{/*{JSON.stringify(props.listDays[props.currentDay-1])}*/}

										{/*{ props.listDays[props.dayProcess-1]?(
											<>*/}
												<span >$ { (balance_final).toFixed(3) }</span>
{/*											</>
										):(
											<>
												<span >$ 0</span>
											</>
										)}*/}
									</span>

									{/*
									<span id="backtest_test_balance_estimate" className="button -secondary w100" >
										<strong  className="display:block">Negociado</strong>
										<span >$ { (props.totalBacktest.test_balance_estimate).toFixed(3) }</span>
									</span>*/}
									
								</div>
							</div>

							<div className="line barControl mobile-nobreak tablet-nobreak">							
								<div className="column col6 center padding-h padding-top-10 padding-bottom-10">
									
									<span id="backtest_margin" className="button -secondary w100" signal={ profitMarginString } >
										<strong  className="display:block">Margem de ganho</strong>

										
											<span >{ profitMargin.toFixed(2) } %</span>
											{/*<span >{ (( props.listDays[props.dayProcess-1].balance_final - props.listDays[0].balance_initial) / props.listDays[0].balance_initial * 100).toFixed(2) } %</span>*/}
										

										{/*<span   >{ isNaN( (props.totalBacktest.balanceEnd - props.totalBacktest.balanceStart) / props.totalBacktest.balanceStart * 100 )?'':( (props.totalBacktest.balanceEnd - props.totalBacktest.balanceStart) / props.totalBacktest.balanceStart * 100 ).toFixed(2) } %</span>*/}
										{/*<span   >{ ((props.totalBacktest.balanceEnd - props.totalBacktest.balanceStart) / props.totalBacktest.balanceStart * 100).toFixed(2) } %</span>*/}
									</span>
									
								</div>															
							</div>

							

							<div className="line barControl mobile-nobreak tablet-nobreak">							
								<div className="column col6 center padding-h">
									
									{/*<span  className="button -secondary w100" signal2={ (props.totalBacktest.balance===0)?'':props.totalBacktest.balance >0?'positive':'negative' } signal={ isNaN( (props.totalBacktest.test_balance_estimate-props.totalBacktest.balance) / props.totalBacktest.balance * 100)?'':( (props.totalBacktest.test_balance_estimate-props.totalBacktest.balance) / props.totalBacktest.balance * 100 === 0)?'':( (props.totalBacktest.test_balance_estimate-props.totalBacktest.balance) / props.totalBacktest.balance * 100) < 0?'negative':'positive' } >*/}
									<span  className="button -secondary w100"  >
										<strong  className="display:block">Gain</strong>
										<span   >{gain_count}</span>
									</span>
									
								</div>					
								<div className="column  center padding-h">
									
									<span className="button -secondary w100" >
										<strong  className="display:block">Loss</strong>													
										<span>{loss_count}</span>
									</span>
									
								</div>
							</div>

							<div className="line barControl mobile-nobreak tablet-nobreak">							
								<div className="column col6 center padding-h padding-top-10 padding-bottom-10">
									
									<span id="backtest_margin" className="button -secondary w100"  signal={  ((averageGeneralPercent > 0)?'positive':( (averageGeneralPercent < 0)?'negative':'')) } >
										<strong  className="display:block">Média de ganho diário</strong>
										{/*<span> { isNaN( (averageGeneralPercent / props.totalBacktest.trade_total ))?'':(averageGeneralPercent / props.totalBacktest.trade_total ).toFixed(2) } %</span>*/}
										<span>$ {averageGeneral.toFixed(2)}</span>
									</span>													
								</div>															
							</div>

							
						</>
					)}
				</div>
			</div>
		</>
	)
}

export default Component