import React,{useEffect,useLayoutEffect,useState,useContext} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck,faPlus,faStore,faAngleUp,faAngleDown,faCoins,faBuildingColumns,faSyncAlt,faVial,faToggleOn,faToggleOff} from '@fortawesome/free-solid-svg-icons'

import {GlobalContext} from "../../../contexts/global";

import {botList} from "../../../services/apiBots.jsx";

import LayoutCard from "../../../components/card/index.jsx";
import ProgressBar from "../../../components/ProgressBar";

import Options from "../../../components/options/index.jsx";
import Admin from "./admin/index.jsx";
import BotStore from "./botstore/index.jsx";
import Dialog from '@mui/material/Dialog';

import Selectexchanges from "../../../layout/selectexchanges";

import {typeOf} from "../../../libs/misc.js";

import "./style.css";

const Component = () => {

	const {settings,globals} = useContext(GlobalContext);

	const [list, listUpdate] = useState([]);


	/* let listBots = window.localStorage.getItem('listBots');
	listBots = JSON.parse(listBots);
	
	useLayoutEffect(()=>{		
		listUpdate(listBots);
	},[listBots]);
 */


	const [backtest, backtestUpdate] = useState({});

	useLayoutEffect(()=>{		
		/* if(globals.backtest === undefined){
			backtestUpdate({});				
			return;
		}
 */
		// console.log('==',backtest);

		backtestUpdate(globals.backtest);				
	},[globals.backtest]);

	

	const refreshList = () =>{
		
		let list2 = globals.listBots;

		// window.localStorage.setItem('listBots',JSON.stringify(list2));


		for(let key in list2){

			list2[key].orderstatus = '0';
			if( parseInt(list2[key].lastupdate) > (parseInt((new Date).getTime()/1000)-30) ) {
				list2[key].orderstatus = '1';
			}

			for(let key2 in globals.exchanges){
				if( globals.exchanges[key2]._id === list2[key].exchange ){
					list2[key].exchangestatus = globals.exchanges[key2].status;
				}
			}


		}



		listUpdate(list2);


	}


	useLayoutEffect(()=>{
		refreshList();
	},[window.username,globals.listBots]);



	const [adminCurrent,adminCurrentUpdate] = useState(null);
	const [adminCurrentParameters,adminCurrentParametersUpdate] = useState(null);

	const [optionClose,optionCloseUpdate] = useState(false);
	const optionCloseHandle = () =>{			
		optionCloseUpdate( (new Date()).getTime() );
	}
	const [optionShow,optionShowUpdate] = useState(false);
	const optionShowHandle = (key) =>{	
		
		// console.log('-',list[key]);	

		adminCurrentUpdate( list[key] );
		optionShowUpdate( (new Date()).getTime() );
	}

	const posAdminCallback = () =>{
		// refreshList();
		globals.getBots();
		optionCloseHandle();
	}

	const onClose = () =>{

	}

	const [link,linkUpdate] = useState({});



	const newBotHandle = (parameters) =>{

		// console.log(list);
		// let count = list.length -1;

		// list[count] = 

		adminCurrentUpdate( 'new' );
		adminCurrentParametersUpdate( parameters );
		// setTimeout(()=>{
			optionShowUpdate( (new Date()).getTime() );
		// },500);
	}

	// if( typeOf(list) === 'array' ) return(<></>);
	

	let [storeMode, storeModeUpdate] = useState(null);
	let [openStore, openStoreUpdate] = useState(false);
	const openStoreHandle = key => {
	   // currentKeyUpdate(fields.name);
	   openStoreUpdate(true);
	   // storeModeUpdate();
	};
	const storeCloseHandle = () => {
			openStoreUpdate(false);
	};


	return (
		<>
			
			<Options className="--option-theme" open={optionShow} close={optionClose} onClose={onClose} areaclass="w100 " baseelement="body [id='root']" link={link} >						
				<Admin optionCloseHandle={optionCloseHandle} adminCurrent={adminCurrent} adminCurrentUpdate={adminCurrentUpdate} posAdminCallback={posAdminCallback} linkUpdate={linkUpdate} adminCurrentParameters={adminCurrentParameters} />
			</Options>

			<Dialog onClose={storeCloseHandle} open={openStore}  >
				<BotStore storeCloseHandle={storeCloseHandle} storeMode={storeMode} storeCloseHandle={storeCloseHandle} newBotHandle={newBotHandle} />
			</Dialog>

			{/*<Options className="--option-theme" open={storeShow} close={storeClose} onClose={onClose} areaclass="w100 " baseelement="body [id='root']" link={link} >						
				<BotStore storeCloseHandle={storeCloseHandle} adminCurrent={adminCurrent} adminCurrentUpdate={adminCurrentUpdate} posAdminCallback={posAdminCallback} linkUpdate={linkUpdate} />
			</Options>*/}

			<div className="page bots h100 ">

				<div className="wrapper">
					<div className="header ">
						
						<div className="line mobile-nobreak tablet-nobreak padding">
							<div className="column col0 left padding-right-10 nowrap">
								<button className="button -secondary" onClick={()=>{ newBotHandle() }} >
									{/*<FontAwesomeIcon icon={faPlus} /> */}
									Novo
								</button>									
							</div>	
							<div className="column left">
								<button className="button -secondary" onClick={()=>{ openStoreHandle() }} >
									{/*<FontAwesomeIcon icon={faStore} />*/} Estratégias
								</button>
							</div>
							{/*<div className="column col0 right">
								<button className="button -linktransp" onClick={()=>{listUpdate([]);refreshList();}}>
									
									<FontAwesomeIcon icon={faSyncAlt} />
								</button>
							</div>	*/}
						</div>	



					</div>
					<div className="main sticky overflow-y-scroll">				
						
						



						{  ( typeOf(list) === 'array' ) && (
							<div className="loading center padding link "></div>
						)}

						

						{ ( typeOf(list) === 'object' && Object.keys(list).length < 1 ) && (							
							<div className="center" >
								<p>Sem registro</p>
							</div>
						)}


						<div className="listCards">
							<ul>

								{Object.keys(list).map((key,index) => {


									if( global.exchangeID !== '[full]' && (global.exchangeID !== list[key].exchange))
									return (<li key={index}></li>);
									
							        return (
							        	<li key={index} onClick={()=>{ optionShowHandle(key); }}>
							        		
							        		<LayoutCard 
												key={key} 
												enabledValue={list[key].enabled}
												
												splitMain={
													<>
														<div className="line mobile-nobreak tablet-nobreak ">
															<div className="column col0 middle center padding-right-10 ">
											    				<FontAwesomeIcon icon={faCheck} className="icon" /> 
															</div>
															<div className="column  left ">

																<div className="line mobile-nobreak tablet-nobreak ">
																	<div className="column  middle left ">
												        				<span className="title">
												        					{ list[key].name } 
																		</span>

																		<small className="description">
																			{Object.keys(globals.exchanges).map((key2,index2)=>{


																				if( globals.exchanges[key2]._id !== list[key].fields.exchange ) return (<React.Fragment key={index2}></React.Fragment>);

																				return (
																					<div key={index2} >
																					
																							{globals.exchanges[key2].name}
																						
																					</div>
																				)
																			})}
																		</small>

																										
																	</div>	
																	<div className="column  middle right ">

																		<ul>
																			<li>
																				<div className={"link coin_last_price "} onClick={(e)=>{  }} >						
																					<small>{ isNaN(list[key].fields.coin_last_price)?'':parseFloat(list[key].fields.coin_last_price).toFixed(2)+'' }&nbsp;</small>
																					<FontAwesomeIcon icon={faCoins} />&nbsp;
																					<small className="coin">{list[key].fields.coinfrom}/{list[key].fields.cointo}</small>
																				</div>


																				{ (typeof backtest[list[key].name]?.fields.backtest_countgain !== 'undefined' ) && (
																					<div className="backtestcount">
																						<span className="gain">{/*<FontAwesomeIcon icon={faAngleUp} />*/} {backtest[list[key].name]?.fields.backtest_countgain} ({Number(backtest[list[key].name]?.fields.backtest_porcentgain).toFixed(2)}%)</span>
																						<span className="loss">{/*<FontAwesomeIcon icon={faAngleDown} />*/} {backtest[list[key].name]?.fields.backtest_countloss} ({Number(backtest[list[key].name]?.fields.backtest_porcentloss).toFixed(2)}%)</span>
																					</div>
																				)}

																			</li>	
																		
																		
																		
																			{Object.keys(globals.exchanges).map((key2,index2)=>{
																				return (
																					<li key={key2} >
																						{
																							((globals.exchanges[key2]._id === list[key].fields.exchange)?(globals.exchanges[key2].status):('')) === 'error' && (
																								
																								<button type="button" className={"link "+((globals.exchanges[key2].status==='error')?'-error':'-success') } title="Exchange not connected" onClick={(e)=>{  }} >						
																									<FontAwesomeIcon icon={faBuildingColumns} />
																								</button>
																								
																							)
																						}
																					</li>
																				)
																			})}

																		</ul>

																	</div>										
																</div>	




																<div className="line mobile-nobreak tablet-nobreak progresssbarline padding-top-10   ">

																		

																		{ backtest[list[key].name]?.fields?.lastprice?.progress.map((key2,index2)=>{
																																				
																			let count = Object.keys( backtest[list[key].name]?.fields?.lastprice?.progress[index2]).length;																			
																			let countTotal = Object.keys( backtest[list[key].name]?.fields?.lastprice?.progress).length;

																			// return ( <React.Fragment key={index2}></React.Fragment> );
																			


																			return (
																				<React.Fragment key={index2}>
																					
																					<div className={" column col6"}>

																						<div className="line mobile-nobreak tablet-nobreak  ">
																							{ Object.keys(backtest[list[key].name]?.fields?.lastprice?.progress[index2]).map((key3,index3)=>{

								

																								let order = key3;
																								let value = backtest[list[key].name]?.fields?.lastprice?.progress[index2][key3];

																								return(
																									<React.Fragment key={index2+key3}>
																										<div className={" column "}>																						
																											<ProgressBar  percentage={ (value) } className={order} style={{"height":"3px"}} >																							
																												{/*value.toFixed(2)*/}																						
																											</ProgressBar>																					
																										</div>		


																				    						{ ( (index3+1)!==count) &&(
																					    						<div className={" column col0 padding-h-5"}></div>
																					    					)}
																				    																					
																									</React.Fragment>																					
																								)
																							
																							})}
																						</div>

																						
															    					</div>
															    					
															    					

															    					{ ( (index2+1)!==countTotal) &&(
															    						<div className={" column col0 padding-h-10"}></div>
															    					)}

																				</React.Fragment>
																			)
																		})}	

																								        						
															    </div> 




															</div>										
														</div>										
													</>
												}

											/>
							     		</li>
							     	)
							     })} 						
								 	
							</ul>
						</div>

					</div>
					<div className="footer">

						<div className="headerArea">
							<h1>Bots <span className="badge">{Object.keys(list).length}</span></h1>
							{/*<p>Listagem de bots criados</p>*/}
						</div>

					</div>
				</div>
			
			</div>
		</>
	);
};


export default Component;
