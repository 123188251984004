import { env } from '../.env.js';
import axios from "axios";

export const api = axios.create({
	baseURL: env.AchoordURL
});

// http://admin.achoord.com/api/v1/accounts/wallrio/trade/p/storage/api/storage/name:coins/collections/name:{get.exchange}/
 
export const coinsList = async (exchange) => {	
	return await api.get("/coins/list/?exchange="+exchange);
}

export const coinsGet = async (exchange,botid) => {
	return await api.get("/coins/get/?exchange="+exchange+"&botid="+botid);
}

/* export const botPut = async (username,botid,data) => {	
	return await api.put("/bots/put/?username="+username+"&botid="+botid,data);	
}

export const botPost = async (username,data) => {
	return await api.post("/bots/post/?username="+username,data);	
}

export const botDelete = async (username,botid) => {
	return await api.delete("/bots/delete/?username="+username+"&botid="+botid);
}
 */