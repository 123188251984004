import { env } from '../.env.js';
import axios from "axios";


export const api = axios.create({
	baseURL: env.AchoordURL
});



export const exchangeList = async (username) => {
	
	// collection = '0812887001668738913';
	return await api.get("/exchanges/list/?username="+username);
	// return api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/");
}

export const exchangeGet = async (username,botid) => {
	// let collection = '0812887001668738913';
	return await api.get("/exchanges/get/?username="+username+"&botid="+botid);
	// return api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");
	// return api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/name:"+botid+"/");
}

export const exchangePut = async (username,botid,data) => {
	// let collection = '0812887001668738913';
	return await api.put("/exchanges/put/?username="+username+"&botid="+botid,data);
	// return api.put("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/",data);
}

export const exchangePost = async (username,data) => {
	// let collection = '0812887001668738913';
	return await api.post("/exchanges/post/?username="+username,data);
	// return api.post("/storage/api/storage/Exchanges/collections/"+collection+"/documents/",data);
}

export const exchangeDelete = async (username,botid) => {
	// let collection = '0812887001668738913';
	return await api.delete("/exchanges/delete/?username="+username+"&botid="+botid);
	// return api.delete("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");
}



export const exchangeClone = async (username,botid) => {
	let collection = '0812887001668738913';

	let response = await api.get("/exchanges/get/?username="+username+"&botid="+botid);
	// let response = await api.get("/storage/api/storage/Exchanges/collections/"+collection+"/documents/"+botid+"/");

	let posfix = (new Date()).toLocaleTimeString();//(new Date()).toISOString();

	let data = response.data.body[0];
	data.name = data.name+' Clone '+posfix;
	data.title = data.title+' Clone '+posfix;

	delete data.id;
	return await api.post("/exchanges/post/?username="+username,data);
	// return api.post("/storage/api/storage/Exchanges/collections/"+collection+"/documents/",data);

}

