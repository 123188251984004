import AppRouters from "./AppRouters"
import { SnackbarProvider } from 'notistack';

function App() {

  window.setDeviceType = function(type){      
    document.querySelector('html').setAttribute('devicetype',type);
  }

  

  return (
    <div className=" h100">     
    <SnackbarProvider     
      maxSnack={3} 
      autoHideDuration={2000} 
      
      anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }} >
        <AppRouters/>   
    </SnackbarProvider>   
    </div>
  );
}

export default App;