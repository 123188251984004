import { env } from '../.env.js';
import axios from "axios";

export const api = axios.create({
	baseURL: env.AchoordURL
});


export const checkServer = async () => {

  	// let command = "uptime";

  	

  	return await api.get("/global/checkserver/");

	// return api.get("https://admin.achoord.com:1111/server/sendcommand?username=wallrio&project=trade&service=bottrade&command="+command);
}
