import React,{useEffect,useLayoutEffect,useState,useContext} from "react";

import {GlobalContext} from "../../../contexts/global";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarDay,faCheck,faTimes,faPlug,faSyncAlt,faVial,faBox,faToggleOn,faToggleOff} from '@fortawesome/free-solid-svg-icons'


import {exchangeList} from "../../../services/apiExchange.jsx";

import LayoutCard from "../../../components/card/index.jsx";

import Options from "../../../components/options/index.jsx";
import Admin from "./admin/index.jsx";

import {typeOf} from "../../../libs/misc.js";


const Component = () => {

	const {settings,globals} = useContext(GlobalContext);
	
	const [list, listUpdate] = useState([]);
	// const [list,listUpdate] = useState({});

	const refreshList = () =>{
		
		let list = globals.listExchanges;	


		listUpdate(list);
		/*
		exchangeList(window.username).then((response)=>{
			
			let status = response.data.status;
			let list = response.data.content;

			listUpdate(list);

					
		}).catch((response)=>{
			
		});*/

		
		/*if( document.querySelector('html').getAttribute('route') === '/dashboard/exchanges' ){			
			setTimeout(()=>{
				refreshList();
			},5000);
		}*/
	}

	useLayoutEffect(()=>{

		refreshList();

	},[window.username,globals.exchanges]);



	const [adminCurrent,adminCurrentUpdate] = useState(null);

	const [optionClose,optionCloseUpdate] = useState(false);
	const optionCloseHandle = () =>{			
		optionCloseUpdate( (new Date()).getTime() );
	}

	const [optionShow,optionShowUpdate] = useState(false);
	const optionShowHandle = (key) =>{		
		adminCurrentUpdate( list[key] );
		optionShowUpdate( (new Date()).getTime() );
	}

	const posAdminCallback = () =>{
		// refreshList();
		globals.getExchanges();
		optionCloseHandle();
	}

	const onClose = () =>{

	}

	const [link,linkUpdate] = useState({});


	const newHandle = () =>{

		// console.log(list);
		// let count = list.length -1;

		// list[count] = 

		adminCurrentUpdate( 'new' );
		// setTimeout(()=>{
			optionShowUpdate( (new Date()).getTime() );
		// },500);
	}

	
	// if( typeOf(list) === 'array' ) return(<></>);

	return (
		<>
			
			<Options className="--option-theme" open={optionShow} close={optionClose} onClose={onClose} areaclass="w100 " baseelement="body [id='root']" link={link} >						
				<Admin optionCloseHandle={optionCloseHandle} adminCurrent={adminCurrent} adminCurrentUpdate={adminCurrentUpdate} posAdminCallback={posAdminCallback} linkUpdate={linkUpdate} />
			</Options>

			<div className="page exchanges h100 ">

				<div className="wrapper">
					<div className="header ">
						
						<div className="line mobile-nobreak tablet-nobreak padding">
							<div className="column left">
								<button className="button -secondary" onClick={()=>{ newHandle() }} >Novo</button>								
							</div>	
							{/*<div className="column col0 right">
								<button className="button -linktransp" onClick={()=>{listUpdate([]);refreshList();}}>
									<FontAwesomeIcon icon={faSyncAlt} />
								</button>
							</div>*/}	
						</div>	

					</div>
					<div className="main sticky overflow-y-scroll">				
						
					
						{ ( typeOf(list) === 'array' ) && (
							<div className="loading center padding link "></div>
						)}

						

						{ ( typeOf(list) === 'object' && Object.keys(list).length < 1 ) && (							
							<div className="center" >
								<p>Sem registro</p>
							</div>
						)}

						<div className="listCards">
							<ul>
								{  Object.keys(list).map((key,index) => {
							        return (
							        	<li key={key} onClick={()=>{ optionShowHandle(key); }}>
							        		<LayoutCard 
					        					key={key} 
					        					enabledValue={list[key].enabled}
					        					numColMin='col7'
					        					splitMain={
								        			<>
								        				<span className="title">
								        					{ list[key].name } 
														</span>


																

														{ list[key].fields.exchange && (
															<span className="description">
																{ list[key].fields.exchange }
															</span>										
														)}
								        			</>
								        		}

								        		splitInfo={
								        			<>
														{ (list[key].fields.futures==='true') && (
															<li>
																<button type="button" className={"link -secondary"} title="Sandbox" onClick={(e)=>{  }} >						
																	<FontAwesomeIcon icon={faCalendarDay} />
																</button>
															</li>
														)}	
														
														{ (list[key].fields.sandbox==='true') && (
															<li>
																<button type="button" className={"link -secondary"} title="Sandbox" onClick={(e)=>{  }} >						
																	<FontAwesomeIcon icon={faBox} />
																</button>
															</li>
														)}								

								        				{/*<li>
															<button type="button" className={"link "+((list[key].enabled==='true')?'':'-secondary') } title="Ativo" onClick={(e)=>{  }} >						
																<FontAwesomeIcon icon={((list[key].enabled==='true')?faToggleOn:faToggleOff)} />
															</button>
														</li>*/}

								        				<li>
															<button type="button" className={"link "+((list[key].fields.status==='success')?'-success':((list[key].fields.status==='unknown')?'loading -secondary':'-error')) } title="Ativo" onClick={(e)=>{  }} >						
																
																{(list[key].fields.status==='success')?(
																	<FontAwesomeIcon icon={faCheck} />
																):(
																	<>
																		{(list[key].fields.status!=='unknown') && (
																			<FontAwesomeIcon icon={faTimes} />
																		)}
																	</>
																)}

															</button>
														</li>
								        			</>
								        		}


					        				/>
							     		</li>
							     	)
							     })
							    } 
								
								

								 	
							</ul>
						</div>

					</div>
					<div className="footer">

						<div className="headerArea">
							<h1>Corretoras <span className="badge">{Object.keys(list).length}</span></h1>
							{/*<p>Listagem de bots criados</p>*/}
						</div>

					</div>
				</div>
			
			</div>
		</>
	);
};


export default Component;
