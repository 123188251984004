
const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
export {uuidv4}


const getId = (prefix) => {
    
    let deviceid = window.localStorage.getItem(prefix+'-deviceid');

    if(deviceid === null){
        deviceid = uuidv4();
        window.localStorage.setItem(prefix+'-deviceid',deviceid);
    }
    
    return deviceid;
}
export {getId}