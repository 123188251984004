import React, {useEffect,useLayoutEffect,useRef} from "react";

import "./style.css";

const Component = (props) =>{

	const inputRef = useRef(null);
 	
 	// let idcomp = (new Date()).getTime();

	/*useEffect(() => {

	},[props.content]);
*/

	useLayoutEffect(() => {

		// console.log( inputRef.current.querySelector('.tabs ul li:not(.noactive) a') );
		

		if(typeof props.content !== 'undefined' && Object.keys(props.content).length < 1 ) return;

		if(inputRef.current === null) return;

		// inputRef.current.style.width = inputRef.current.offsetWidth+'px';
		inputRef.current.querySelector('.tabs').style.width = inputRef.current.offsetWidth+'px';

		

		// setTimeout(()=>{
			if(inputRef.current === null) return;

			let list = inputRef.current.querySelectorAll('ul li:not(.noactive)');

			// console.log( inputRef.current,list );

			 setTimeout(()=>{

			
				if(inputRef.current === null) return;
			
				
				let listactive = inputRef.current.querySelectorAll('.tabs ul li.active');
				if(listactive.length < 1){
					// inputRef.current.querySelector('.tabs ul li').classList.add('active');
					if(inputRef.current.querySelector('.tabs ul li:not(.noactive) a')){
						inputRef.current.querySelector('.tabs ul li:not(.noactive) a').click();
					}else{
						
						

						setTimeout(()=>{
							if(inputRef.current.querySelector('.tabs ul li:not(.noactive) a'))
							inputRef.current.querySelector('.tabs ul li:not(.noactive) a').click();
						},500);
					}
				}
			 },0);
			
			for (var i = 0; i < list.length; i++) {
				list[i].onclick = function(){

					let list = this.parentNode.parentNode.querySelectorAll('li:not(.noactive)');
					for (var a = 0; a < list.length; a++) {					
						list[a].classList.remove('active');					
					}

					this.classList.add('active');	


					let tabs = this.parentNode.parentNode;
					let tabs_width = tabs.offsetWidth;
					let parentEl = this;
					// let posX = this.offsetLeft-(this.offsetLeft/tabs_width);

					let posX =  (parentEl.offsetLeft + (parentEl.offsetWidth) - (parentEl.offsetWidth/2)) - ( tabs_width/2 ) ;

					tabs.scrollTo(posX,0);

					
				}
			}

		// },10);



	}, [props.content]);



	return (
		<div /* idcomp={idcomp} */ ref={inputRef} className={'tabswrapper '+(props.className??'')}>	

			<div className="tabs">
				<ul>
					{props.children}
				</ul>
			</div>

		</div>
	)
}

export default Component;
