import React,{useEffect,useState,useRef} from "react";


import {RunBacktest} from "../RunBacktest.js";

const Component = (props) => {

	

	return (
		<>
			<div className="line mobile-nobreak " visible={ props.listDays.length < 1?'false':'true' }>
				<div className="column left col4 middle">
					{  ((props.currentDay !== 0 && props.currentDay !== '0') && props.loadingBacktestGeral.status === 'true') && (
						<>	
							<label className={"padding-h padding-bottom-10 display:block center -primary-text " } >
								{props.loadingBacktestGeral.index} / {props.listDays.length}
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								
								<small className={" loading " } ></small>
									
							</label>
						</>
					)}



				</div>
				<div className="column left   middle">

					{  (props.currentDay !== 0 && props.currentDay !== '0' &&  props.loadingBacktestGeral.status === 'true') && (
						<>	
							<button className="button  -secondary -nowrap w100 padding-h-10 "  onClick={(e)=>{ window.stopRunBackTest = 'true';props.setloadingBacktestGeral('false'); }}>Parar</button>

					
						</>
					)}
				</div>
				<div className="column left col3 padding middle">

					{/*{  props.currentDay !== 0 && props.currentDay !== '0' && (
						<>															*/}
							<button type="button" className={"changebtn button -primary w100 padding-h-10 "+ (props.loadingBacktestGeral.status === 'true'?'disabled':'') } onClick={(e)=>{RunBacktest(props)}}>Testar</button>
						{/*</>
					)}*/}
				</div>
			</div>
		</>
	)
}

export default Component